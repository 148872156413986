import { Flex, Box } from '@chakra-ui/react';
import SkeletonComponent from '@components/skeleton';

const StatisticsSkeleton = () => {
    return (
        <Flex className="statistic__content statistic-skeleton__container">
            <Box flex={1} borderRight="1px solid var(--color-neutral10)">
                <SkeletonComponent width="120px" height="22px" mb="12px" />
                <Flex>
                    <SkeletonComponent width="268px" height="45px" mr="12px" />
                    <Box>
                        <SkeletonComponent width="56px" height="20px" mb="2px" />
                        <SkeletonComponent width="108px" height="22px" />
                    </Box>
                </Flex>
            </Box>

            <Box flex={1} marginLeft="32px">
                <SkeletonComponent width="120px" height="22px" mb="12px" />
                <Flex>
                    <SkeletonComponent width="268px" height="45px" mr="12px" />
                    <Box>
                        <SkeletonComponent width="56px" height="20px" mb="2px" />
                        <SkeletonComponent width="108px" height="22px" />
                    </Box>
                </Flex>
            </Box>
        </Flex>
    );
};
export default StatisticsSkeleton;
