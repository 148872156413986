import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { DeleteIcon, WarningIcon } from './icons';
import { usePopUp } from '@store/popup';
import { ConditionalComponent } from '@components/functionComponent';
import './styles.scss';

const PopUp = () => {
    const { status, title, description, onSubmit, isLoading, onClose, onResetPopUp, submitOptions, cancelOptions } =
        usePopUp();

    const onCloseHandler = () => {
        if (onClose) onClose();
        onResetPopUp();
    };

    return (
        <ConditionalComponent statement={!!status}>
            <Box className="popup-container">
                <Box className="overlay" onClick={onResetPopUp} data-testid="overlay-popup__global" />
                <Box className="box-container">
                    <Box className="head">
                        <Box className="close-icon" onClick={onResetPopUp}>
                            <CloseIcon fontSize="10px" color="#800000" data-testid="close-popup__global" />
                        </Box>
                        <Box className="icon">
                            <ConditionalComponent statement={status === 'delete'} fallback={<WarningIcon />}>
                                <DeleteIcon />
                            </ConditionalComponent>
                        </Box>
                    </Box>
                    <Box className="body">
                        <Text className="title">{title}</Text>
                        <Text className="desc">{description}</Text>
                    </Box>
                    <Box className="footer">
                        <Button className="cancel" onClick={onCloseHandler} data-testid="cancel-popup__global">
                            {cancelOptions?.name || 'Cancel'}
                        </Button>
                        <Button
                            className="submit"
                            onClick={onSubmit}
                            isLoading={isLoading}
                            data-testid="submit-popup__global"
                        >
                            {submitOptions?.name || 'Yakin'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </ConditionalComponent>
    );
};

export default PopUp;
