import React from 'react';
import { Box } from '@chakra-ui/react';
import TableTemplate from '@components/tableTemplate';
import { tableList } from './const';
import { useSelectedFilterStore, useStoreConfigStore } from '@pages/StoreConfig/helpers/mainState';

import './styles.scss';

const Table = () => {
    const { storeConfigs, paginationInfo, isLoadingTable } = useStoreConfigStore();
    const { page, onChangeFilterSelectedStore } = useSelectedFilterStore();
    const handlePageClick = ({ selected }) => onChangeFilterSelectedStore({ page: selected + 1 });
    return (
        <Box className="table-store-config__container">
            <TableTemplate
                tableList={tableList}
                data={storeConfigs}
                dataCount={paginationInfo?.dataCount}
                totalData={paginationInfo?.totalData}
                page={page}
                totalPage={paginationInfo?.totalPage}
                handlePageClick={handlePageClick}
                isPagination={true}
                overflowTable="visible"
                isLoading={isLoadingTable}
            />
        </Box>
    );
};

export default Table;
