//========================== LIBRARY ===========================

import { Box, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';

//========================== GLOBAL ASSETS ===========================

import ModalTemplate from '@components/modalTemplate';
import { usePopUpNotification } from '@store/popupNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { useUserInfo } from '@store/userInfo';

//========================== LOCAL ASSETS ===========================

import { PageContext } from '@pages/UploadSellout';
import { onFormatOption, isActiveButtonHandler } from '@pages/UploadSellout/helpers/utils';
import { usePopUpAssetStore, usePopUpStore } from '@pages/UploadSellout/components/uploadPopUp/state';
import { onCheckIsBlockUpload } from '@pages/UploadSellout/helpers/utils';
import TimeUploadDescription from './components/timeUploadDescription';
import { onUploadFile, fetchAssets } from '../../helpers/crud';
// import MultipleSelect from './components/multipleSelect';
import TimeSelect from './components/datePicker';
import ButtonLoadingBar from '../buttonLoadingBar';

import Tabs from './components/tabs';

import './styles.scss';
import onGetClientScreen from '@store/clientUser';
import BlockedNotification from './components/blockedNotification';
import { TextBold } from '@components/typography';
import { QuestionBlueIcon } from '@assets/icons/index';
import FileSelectedInfo from './components/fileSelectedInfo';

const link =
    'https://paracorpgroup-my.sharepoint.com/:w:/p/muhammad_adobson/EZ0JQ3_gVapMpZNxFzBGjkoB14LK0UA36jn9oVLslqYT5Q?e=qoCQtF';

const UploadPopUp = ({ onClose, selectedFile }) => {
    const popUpStore = usePopUpStore();
    const { onFetchUploadData } = useContext(PageContext);
    const { userInfo } = useUserInfo();
    const clientScreen = onGetClientScreen();
    const modalWidth = clientScreen === 'wideScreen' ? '520px' : clientScreen === 'mediumScreen' ? '460px' : '460px';

    const { onChangePopUpNotification } = usePopUpNotification();

    const {
        selectedTimeUploadType,
        onChangePopUpStore,
        selectedTimeUploadValue,
        // selectedBrand,
        isTermsChecked,
        selectedFormatUploadType,
        onResetPopUpStore,
        isUploading,
        isBlockNotification,
    } = popUpStore;

    const { timeUploadOptions, uploadTypeOptions, isLoadingAsset, onChangePopupAssetStore, blockedUpload } =
        usePopUpAssetStore();

    const isBlockUpload = onCheckIsBlockUpload({ selectedTimeUploadValue, selectedTimeUploadType, blockedUpload });
    const isActiveButton = isActiveButtonHandler({ store: popUpStore, isBlockUpload });

    useEffect(() => {
        fetchAssets({ onChangePopupAssetStore });
        // eslint-disable-next-line
    }, []);

    return (
        <ModalTemplate isOpen={true} width={modalWidth} onClose={onClose}>
            <Box className="modal-upload-and-data-checking__container">
                {/* //==================== HEADER ===================== */}

                <Flex className="section-header">
                    <Flex alignItems="center">
                        <Text className="title">Upload Sellout</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <a target="_blank" rel="noreferrer" href={link}>
                            <QuestionBlueIcon />
                            <TextBold className="guidline-text">Temukan Panduan Upload Disini!</TextBold>
                        </a>

                        <CloseIcon
                            width="13px"
                            height="13px"
                            color="var(--semantic-text)"
                            cursor="pointer"
                            onClick={onClose}
                        />
                    </Flex>
                </Flex>

                {/* //==================== BODY ===================== */}

                <Box className="section-body">
                    <Text className="label">File di Upload</Text>

                    <FileSelectedInfo selectedFile={selectedFile} onClose={onClose} />

                    <Text className="label">Pilih waktu upload</Text>

                    <ConditionalComponent
                        statement={!isEmpty(blockedUpload) && blockedUpload?.is_block && isBlockNotification}
                    >
                        <BlockedNotification
                            blockedUpload={blockedUpload}
                            onClose={() => onChangePopUpStore({ isBlockNotification: false })}
                        />
                    </ConditionalComponent>

                    <Tabs
                        isLoading={isLoadingAsset}
                        options={onFormatOption({ arr: timeUploadOptions, selectedFile })}
                        isTabActive={selectedTimeUploadType}
                        onChangeTab={({ value }) =>
                            onChangePopUpStore({ selectedTimeUploadType: value, selectedTimeUploadValue: new Date() })
                        }
                    />
                    <ConditionalComponent statement={selectedTimeUploadType}>
                        <TimeUploadDescription
                            selectedTimeUploadType={selectedTimeUploadType}
                            timeUploadOptions={timeUploadOptions}
                        />
                    </ConditionalComponent>
                    <TimeSelect
                        isBlockUpload={isBlockUpload}
                        blockedUpload={blockedUpload}
                        isActive={!isEmpty(selectedTimeUploadType)}
                        timeSelected={selectedTimeUploadValue}
                        timeTypeSelected={selectedTimeUploadType}
                        onChange={({ value }) => onChangePopUpStore({ selectedTimeUploadValue: value })}
                    />

                    {/* <MultipleSelect
                        currentSelect={selectedBrand}
                        onChange={({ value }) => onChangePopUpStore({ selectedBrand: value })}
                        options={brandOptions}
                        config={{
                            useAll: true,
                            allName: 'All Brand',
                        }}
                    /> */}
                    <Text className="label">Pilih tipe upload</Text>
                    <Tabs
                        isLoading={isLoadingAsset}
                        className="tab-type-upload__container"
                        options={onFormatOption({ arr: uploadTypeOptions, selectedFile, optionType: 'upload-type' })}
                        isTabActive={selectedFormatUploadType}
                        onChangeTab={({ value }) => onChangePopUpStore({ selectedFormatUploadType: value })}
                    />
                </Box>

                {/* //==================== FOOTER ===================== */}

                <Box className="section-footer">
                    <Flex alignItems="center" justifyContent="center" width="100%">
                        <Checkbox
                            isChecked={isTermsChecked}
                            onChange={() => onChangePopUpStore({ isTermsChecked: !isTermsChecked })}
                        />
                        <Text className="terms-and-condition__text">
                            Saya setuju dengan{' '}
                            <a target="_blank" rel="noreferrer" href={link}>
                                Syarat & Ketentuan
                            </a>{' '}
                            yang berlaku.
                        </Text>
                    </Flex>

                    <Tooltip label={isActiveButton ? '' : 'Semua field harus diisi'} bg="red">
                        <ButtonLoadingBar
                            isLoading={isUploading}
                            className={`upload-button ${isActiveButton && 'active-button'}`}
                            onClick={() =>
                                onUploadFile({
                                    popUpStore: { ...popUpStore, selectedFile },
                                    onChangePopUpStore,
                                    onResetPopUpStore,
                                    onClose,
                                    isCanUpload: isActiveButton,
                                    onChangePopUpNotification,
                                    onRefetchUploadData: onFetchUploadData,
                                    userInfo,
                                })
                            }
                        >
                            Cek & Upload
                        </ButtonLoadingBar>
                    </Tooltip>
                </Box>
            </Box>
        </ModalTemplate>
    );
};

export default UploadPopUp;
