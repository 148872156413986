import { Box, Flex, Spinner, useDisclosure } from '@chakra-ui/react';
import './styles.css';
import { InformationIcon, LeftArrowIcon, SaveWhiteIcon } from '@assets/icons/index';
import { TextBold, TextRegular } from '@components/typography';
import ButtonRegular from '@components/buttons/buttonRegular';
import { useNavigate, useParams } from 'react-router-dom';
import { ConditionalComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';
import { isEmpty } from 'lodash';
import useUpdateConfigurationInfo from '@domain/configuration/useUpdateConfigurationInfo';
import useExportConfigurationHistory from '@domain/configuration/useExportConfigurationHistory';
import { checkIsShowSaveButton } from '@pages/storeConfigurationDetail/helpers/utils';
import ButtonOutline from '@components/buttons/buttonOutline';
import ModalConfirmation from '@components/mocalConfirmation';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';

const Head = ({ configuration, isLoadingConfiguration, configurationFirstLoad }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const updateConfigurationInfo = useUpdateConfigurationInfo({ id, configuration });
    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);
    const downloadConfigurationHistory = useExportConfigurationHistory({ id });
    const isShowSaveButton = checkIsShowSaveButton(configurationFirstLoad, configuration);
    const { isOpen: isOpenModalCancel, onClose: onCloseModalCancel, onOpen: onOpenModalCancel } = useDisclosure();

    const onCancelConfigurationChanges = () => {
        updateConfigurationInfoState?.mutate({ ...configurationFirstLoad });
        onCloseModalCancel();
    };

    return (
        <Box className="store-config-detail__head__wrapper">
            <Flex className="store-config-detail__head__container">
                <Box flex={1}>
                    <TextBold className="title" onClick={() => navigate('/store-config/iseller')}>
                        <LeftArrowIcon />
                        Konfigurasi
                    </TextBold>
                    <ConditionalComponent
                        statement={!isLoadingConfiguration}
                        fallback={
                            <Flex alignItems="center" gap="8px" width="100%" ml="40px">
                                <SkeletonComponent height="16px" width="16px" />
                                <SkeletonComponent height="20px" width="100%" maxWidth="600px" />
                            </Flex>
                        }
                    >
                        <Flex ml="40px" className="information__container">
                            <InformationIcon />
                            <TextRegular className="description">
                                <ConditionalComponent
                                    statement={!isEmpty(configuration?.latestUpdate)}
                                    fallback={'Tidak ada pembaruan terbaru untuk saat ini.'}
                                >
                                    Terakhir diperbarui pada <b> {configuration?.latestUpdate?.created_at} </b> oleh{' '}
                                    <b>{configuration?.latestUpdate?.email}.</b>
                                </ConditionalComponent>

                                <span onClick={downloadConfigurationHistory.mutate}>Download Riwayat Log</span>
                            </TextRegular>
                        </Flex>
                    </ConditionalComponent>
                </Box>
                <ConditionalComponent statement={isShowSaveButton}>
                    <Flex gap="12px" height={'100%'} margin="auto 0">
                        <ButtonOutline
                            color="var(--color-primary)"
                            height="42px"
                            width="95px"
                            onClick={onOpenModalCancel}
                            component={<TextBold className="cancel-button__text">Batalkan</TextBold>}
                        />
                        <ButtonRegular
                            isLoading={updateConfigurationInfo?.isPending}
                            color="var(--color-primary)"
                            height="42px"
                            width="121px"
                            onClick={updateConfigurationInfo.mutate}
                            component={
                                <Flex gap="12px" alignItems="center">
                                    <ConditionalComponent
                                        statement={!updateConfigurationInfo?.isPending}
                                        fallback={<Spinner color="white" size="sm" />}
                                    >
                                        <SaveWhiteIcon />
                                    </ConditionalComponent>
                                    <TextBold className="save-button__text">Simpan</TextBold>
                                </Flex>
                            }
                        />
                    </Flex>
                </ConditionalComponent>

                {/* MODAL */}
                <ModalConfirmation
                    isOpen={isOpenModalCancel}
                    onClose={onCloseModalCancel}
                    title="Cancel Configuration"
                    width="400px"
                    borderRadius={'8px'}
                    description="Baru saja ada perubahan pada konfigurasi toko. Apakah Kamu ingin membatalkan perubahan tersebut?"
                    cancelButtonText="Kembali"
                    submitButtonText="Batalkan"
                    onCancelClick={onCloseModalCancel}
                    onSubmitClick={onCancelConfigurationChanges}
                />
            </Flex>
        </Box>
    );
};

export default Head;
