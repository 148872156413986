import getBrandsRequest from '@domain/model/request/brands/getBrandsRequest';
import getBrandsResponse from '@domain/model/response/brands/getBrandsResponse';
import getBrands from '@hooks/brands/getBrands';

const useGetBrands = ({ enabled, search }) => {
    const queries = getBrandsRequest({ search });
    const responses = getBrands({ enabled, queries });
    const mappingResult = getBrandsResponse(responses?.data);
    return { ...responses, data: mappingResult };
};

export default useGetBrands;
