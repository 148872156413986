import fetchDC from '@data/dc/fetchDC';
import { useQuery } from '@tanstack/react-query';

const getDC = ({ enabled, queries }) => {
    return useQuery({
        queryKey: ['dc', queries],
        queryFn: async () => await fetchDC({ queries }),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getDC;
