import { Image, Skeleton } from '@chakra-ui/react';
import React from 'react';
import upload from './upload.svg';
import uploadWhite from './upload-white.svg';
import calendar from './calendar.svg';
import excel from './excel.svg';
import questionBlue from './question-blue.svg';
import questionBlack from './question-black.svg';
import informationIcon from './information.svg';
import informationBlackIcon from './information-black.svg';
import filterIcon from './filter-icon.svg';
import sellinIcon from './sellin.svg';
import selloutIcon from './sellout.svg';
import mtdPositiveIcon from './mtd-positive.svg';
import mtdNegativeIcon from './mtd-negative.svg';
import mtdNeutralIcon from './mtd-neutral.svg';
import blackArrow from './black-arrow.svg';
import sortIcon from './sort-icon.svg';
import searchIcon from './search.svg';
import leftArrowIcon from './left-arrow.svg';
import saveWhiteIcon from './save-white.svg';
import syncIcon from './sync-blue.svg';
import filterRemoveIcon from './filter-remove.svg';
import exportIcon from './export-button.svg';
import informationBlueIcon from './information-blue24.svg';
import successGreen24Icon from './success-green24.svg';
import warningRed24Icon from './warning-red24.svg';
import closeRedIcon from './close-red.svg';
import copyIcon from './copy.svg';
import redoIcon from './redo.svg';
import redoBlueIcon from './redo-blue.svg';
import failedRed32Icon from './failed-red32.svg';
import todoIconCircle from './todo-icon-circle.svg';

export const UploadIcon = () => <Image src={upload} />;
export const UploadWhiteIcon = () => <Image src={uploadWhite} />;
export const CalendarIcon = () => <Image src={calendar} />;
export const ExcelIcon = () => <Image src={excel} />;
export const QuestionBlueIcon = () => <Image src={questionBlue} />;
export const QuestionBlackIcon = () => <Image src={questionBlack} />;
export const InformationIcon = () => <Image src={informationIcon} />;
export const InformationBlackIcon = () => <Image src={informationBlackIcon} />;
export const FilterIcon = () => <Image src={filterIcon} />;
export const SellinIcon = ({ transform }) => <Image src={sellinIcon} transform={transform} />;
export const SelloutIcon = () => <Image src={selloutIcon} />;
export const MtdPositiveIcon = () => <Image src={mtdPositiveIcon} />;
export const MtdNegativeIcon = () => <Image src={mtdNegativeIcon} />;
export const MtdNeutralIcon = () => <Image src={mtdNeutralIcon} />;
export const BlackArrowIcon = () => <Image src={blackArrow} />;
export const SortIcon = ({ onClick }) => <Image src={sortIcon} cursor="pointer" onClick={onClick} />;
export const SearchIcon = () => <Image src={searchIcon} />;
export const LeftArrowIcon = () => <Image src={leftArrowIcon} />;
export const SaveWhiteIcon = () => <Image src={saveWhiteIcon} />;
export const SyncIcon = () => <Image src={syncIcon} />;
export const FilterRemoveIcon = ({ onClick }) => <Image src={filterRemoveIcon} onClick={onClick} />;
export const ExportIcon = () => <Image src={exportIcon} />;
export const InformationBlue24Icon = () => <Image src={informationBlueIcon} />;
export const SuccessGreen24Icon = ({ width, height, fallback }) => (
    <Image width={width} height={height} src={successGreen24Icon} fallback={fallback} />
);
export const FailedRed32Icon = ({ fallback }) => <Image src={failedRed32Icon} fallback={fallback} />;
export const WarningRed24Icon = () => <Image src={warningRed24Icon} />;
export const CloseRedIcon = () => <Image src={closeRedIcon} />;
export const CopyIcon = ({ onClick }) => (
    <Image src={copyIcon} cursor="pointer" data-testid="copy-icon" onClick={onClick} />
);
export const RedoIcon = () => <Image src={redoIcon} />;
export const RedoBlueIcon = () => <Image src={redoBlueIcon} />;
export const TodoIconCircle = ({ fallback }) => <Image src={todoIconCircle} fallback={fallback} />;
