import { Box, Checkbox, Flex, Input, useDisclosure } from '@chakra-ui/react';
import './styles.css';
import { TextBold, TextRegular } from '@components/typography';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect, useState } from 'react';
import SelectSkeleton from './skeleton';
import { debounce } from '@helpers/utils';
import { find, isEmpty } from 'lodash';
import SkeletonComponent from '@components/skeleton';
import { RedoBlueIcon } from '@assets/icons/index';

const Select = ({
    title,
    placeholder,
    mb,
    mr,
    width,
    isMultiple = true,
    isDisabled,
    mt,
    refetchData,
    data,
    isLoading,
    onChangeFilter,
    selected,
    onSearch,
    onCloseOptions,
    isCanSearch = true,
    isloadingComponent,
}) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState([]);

    const onSelect = ({ name, id }) => {
        let temp;

        if (isMultiple) {
            temp = [...selectedOption];
            const isCheck = [...temp]?.filter((item) => item?.id === id)?.length > 0;
            if (isCheck) temp = temp.filter((item) => item?.id !== id);
            else temp.push({ name, id });
        } else {
            temp = {
                name,
                id,
            };
        }

        setSelectedOption(temp);
        onChangeFilter(temp);

        if (!isCanSearch) onClose();
    };

    const onOpenOptions = () => {
        if (isDisabled) return;
        if (refetchData) refetchData();
        onOpen();
    };

    const onOutsideClick = () => {
        onClose();
        setSearch('');
        onCloseOptions();
    };

    const onSearchHandler = debounce((query) => onSearch(query), 400);

    const onChangeSearch = (query) => {
        if (!isMultiple) return '';
        setSearch(query);
        onSearchHandler(query);
    };

    useEffect(() => {
        setSelectedOption(selected);
    }, [selected]);

    return (
        <Box className="select-container" mb={mb} mr={mr} width={width ?? '100%'} mt={mt}>
            <TextBold
                className="select-title  no-highlight__text"
                color={`${isDisabled ? 'var(--color-neutral40)' : 'var(--semantic-text)'} !important`}
            >
                {title}
            </TextBold>
            <ConditionalComponent
                statement={!isloadingComponent}
                fallback={<SkeletonComponent width={'100%'} height="40px" />}
            >
                <Flex
                    className="select-button"
                    onClick={onOpenOptions}
                    bg={isDisabled ? 'var(--semantic-heron) !important' : 'white'}
                >
                    <Input
                        onChange={(e) => onChangeSearch(e.target.value)}
                        className="search-input  no-highlight__text"
                        placeholder={placeholder}
                        value={
                            isOpen
                                ? search
                                : !isMultiple
                                ? selectedOption?.name
                                : selected?.length > 0
                                ? `${selected?.length} Terpilih`
                                : ''
                        }
                    />
                    <ChevronDownIcon fontSize="18px" color="var(--semantic-text)" className="chevron-down__icon" />
                </Flex>

                <ConditionalComponent statement={isOpen}>
                    <OutsideClickHandler onOutsideClick={onOutsideClick}>
                        <Box className="select-options__container" width={width || '100%'}>
                            <ConditionalComponent statement={!isLoading} fallback={<SelectSkeleton width={width} />}>
                                <ConditionalComponent
                                    statement={data?.length > 0}
                                    fallback={
                                        <Flex className={`option-item`}>
                                            <ConditionalComponent
                                                statement={search}
                                                fallback={
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <TextRegular>Data Kosong</TextRegular>
                                                        <Flex
                                                            gap="6px"
                                                            alignItems="center"
                                                            onClick={refetchData}
                                                            cursor="pointer"
                                                        >
                                                            <RedoBlueIcon />
                                                            <TextBold className="reload-text">Muat Ulang</TextBold>
                                                        </Flex>
                                                    </Flex>
                                                }
                                            >
                                                <TextRegular>
                                                    Data <b>'{search}'</b> tidak ditemukan
                                                </TextRegular>
                                            </ConditionalComponent>
                                        </Flex>
                                    }
                                >
                                    <IterationComponent
                                        arr={data}
                                        render={({ name, id }) => {
                                            const isChecked = find(selectedOption, { id: id });

                                            return (
                                                <Flex className={`option-item ${isChecked && 'selected-option-item'}`}>
                                                    <Box
                                                        className="option-onclick__zone"
                                                        onClick={() => onSelect({ name, id })}
                                                    />
                                                    <ConditionalComponent statement={isMultiple}>
                                                        <Checkbox
                                                            defaultChecked={!isEmpty(isChecked)}
                                                            isChecked={!isEmpty(isChecked)}
                                                            className="option-checkbox"
                                                        />
                                                    </ConditionalComponent>

                                                    <TextRegular className="option-text">{name}</TextRegular>
                                                </Flex>
                                            );
                                        }}
                                    />
                                </ConditionalComponent>
                            </ConditionalComponent>
                        </Box>
                    </OutsideClickHandler>
                </ConditionalComponent>
            </ConditionalComponent>
        </Box>
    );
};

export default Select;
