import React from 'react';
import { ChevronDownIcon } from '../icons';
import { Skeleton, Box, Flex } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import onGetClientScreen from '@store/clientUser';

const NavbarSkeleton = () => {
    const clientScreen = onGetClientScreen();
    const skeletonSize = clientScreen === 'wideScreen' ? '22px' : clientScreen === 'mediumScreen' ? '18px' : '18px';
    const skeletonParentWidth =
        clientScreen === 'wideScreen' ? '160px' : clientScreen === 'mediumScreen' ? '136px' : '140px';
    const skeletonChildWidth =
        clientScreen === 'wideScreen' ? '180px' : clientScreen === 'mediumScreen' ? '156px' : '156px';

    return (
        <Box className="navbar-skeleton__container">
            <Skeleton
                mb="16px"
                height="22px"
                w="80px"
                borderRadius="2px"
                startColor="var(--skeleton-color)"
                endColor="var(--skeleton-active-color)"
                ml="24px"
            />
            <IterationComponent
                arr={[1, 2, 3, 4]}
                render={() => (
                    <Box className="navbar-item__skeleton" mb="8px" padding="0 32px">
                        <Flex className="parent" gap="36px" h="44px" justifyContent="space-between" alignItems="center">
                            <Flex gap="12px">
                                <Skeleton
                                    height="22px"
                                    w="22px"
                                    borderRadius="2px"
                                    startColor="var(--skeleton-color)"
                                    endColor="var(--skeleton-active-color)"
                                />

                                <Skeleton
                                    height="22px"
                                    width="126px"
                                    borderRadius="2px"
                                    startColor="var(--skeleton-color)"
                                    endColor="var(--skeleton-active-color)"
                                />
                            </Flex>

                            <Skeleton
                                height="24px"
                                w="22px"
                                borderRadius="2px"
                                startColor="var(--skeleton-color)"
                                endColor="var(--skeleton-active-color)"
                            />
                        </Flex>
                        <IterationComponent
                            arr={[1, 2, 3]}
                            render={() => (
                                <Flex className="child" pl="24px" mb="8px" h="36px" gap="12px" alignItems="center">
                                    <Skeleton
                                        height="22px"
                                        w="22px"
                                        borderRadius="2px"
                                        startColor="var(--skeleton-color)"
                                        endColor="var(--skeleton-active-color)"
                                    />

                                    <Skeleton
                                        height="22px"
                                        width="126px"
                                        borderRadius="2px"
                                        startColor="var(--skeleton-color)"
                                        endColor="var(--skeleton-active-color)"
                                    />
                                </Flex>
                            )}
                        />
                    </Box>
                )}
            />
        </Box>
    );
};

export default NavbarSkeleton;
