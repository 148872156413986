import fetchTemplateUpload from '@data/templateUpload/fetchTemplateUpload';
import { useQuery } from '@tanstack/react-query';

const getTemplateUpload = ({ enabled }) => {
    return useQuery({
        queryKey: ['template-upload'],
        queryFn: async () => await fetchTemplateUpload(),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getTemplateUpload;
