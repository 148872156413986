import { find, isEmpty, isEqual, lowerCase, template } from 'lodash';

export const checkBackgroundProgress = (arr) => {
    let result = true;

    result = find(arr, { status: 'Inprogress' })?.status;

    return result;
};

export const checkIsShowSaveButton = (prev, current) => {
    if (isEmpty(prev)) return false;
    const object1 = {
        ...prev,
        template_upload: null,
        system_type: { id: prev?.system_type?.id },
        tag: { id: prev?.tag?.value },
        customer_id_konsi: prev?.customer_id_konsi || null,
        transfer_status: lowerCase(prev?.transfer_status?.name),
    };
    const object2 = {
        ...current,
        template_upload: null,
        system_type: { id: current?.system_type?.id },
        tag: { id: current?.tag?.value },
        customer_id_konsi: current?.customer_id_konsi || null,
        transfer_status: lowerCase(current?.transfer_status?.name),
    };

    return !isEqual(object1, object2);
};
