import authAxios from '@services/authAxios';

const fetchSyncToExagonOutbound = async (id) => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/outbound/${id}`,
    });

    return data?.data;
};

export default fetchSyncToExagonOutbound;
