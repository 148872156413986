import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import Cookies from 'universal-cookie';

import { DottedIcon, UserIcon } from '@assets/icons';
import FloatingMenu from '@components/floatingMenu';
import FilterDropdown from '@components/filter/dropdown';
import AddButton from '@components/addButton';
import Search from '@components/search';
import { useDragFile } from '@store/dragFile';
import UploadFullZone from '@components/uploadFullZone';
import { onChangeFile } from '@pages/UserManagement/helpers/event';
import { usePopUp } from '@store/popup';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { ConditionalComponent } from '@components/functionComponent';

import HeadSkeleton from '@pages/UserManagement/components/headSkeleton';
import {
    useFilterDCOptionStore,
    useFilterRoleOptionStore,
    useUserManagementFilterStore,
    useUserManagementStore,
} from '@pages/UserManagement/helpers/mainState';
import { fetchDC, fetchRole } from '@pages/UserManagement/helpers/crud';
import { listHeadMenu } from './const';
import './styles.scss';

const Head = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const userRole = cookies.get('role');

    const { isDragFile, onChangeDragFile } = useDragFile();
    const { onChangePopUp, onResetPopUp } = usePopUp();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const { onChangeFilterDCOptionStore, dcOptions, isLoadingDCOption } = useFilterDCOptionStore();
    const { onChangeFilterRoleOptionStore, roleOptions, isLoadingRoleOption } = useFilterRoleOptionStore();
    const { onChangeUserManagementFilterSelectedStore } = useUserManagementFilterStore();
    const { isLoadingHead } = useUserManagementStore();

    const onFetchDC = () => fetchDC({ onChangeFilterDCOptionStore });
    const onFetchRole = () => fetchRole({ onChangeFilterRoleOptionStore });

    return (
        <Box className="section-head__usermanagement">
            <Text className="title">User Management</Text>
            <ConditionalComponent statement={!isLoadingHead} fallback={<HeadSkeleton />}>
                <Flex alignItems="center" justifyContent="space-between">
                    {/* //==================== LEFT SECTION ==================== */}

                    <Flex alignItems="center">
                        <Search
                            onSearch={({ query }) =>
                                onChangeUserManagementFilterSelectedStore({ searchQuery: query, page: 1 })
                            }
                            placeholder="Cari nama atau email user...."
                        />
                        <FilterDropdown
                            placeholder="Role"
                            options={roleOptions}
                            maxFilter={1}
                            isMultiple={false}
                            onFetchData={onFetchRole}
                            isLoading={isLoadingRoleOption}
                            filterId="filter-role__usermanagement"
                            onSelect={({ selected }) =>
                                onChangeUserManagementFilterSelectedStore({ selectedRole: selected, page: 1 })
                            }
                        />
                        <FilterDropdown
                            placeholder="DC"
                            options={dcOptions}
                            maxFilter={3}
                            isMultiple={false}
                            onFetchData={onFetchDC}
                            isLoading={isLoadingDCOption}
                            filterId="filter-dc__usermanagement"
                            onSelect={({ selected }) =>
                                onChangeUserManagementFilterSelectedStore({ selectedDC: selected, page: 1 })
                            }
                        />
                    </Flex>

                    {/* //==================== RIGHT SECTION ==================== */}

                    <Flex alignItems="center">
                        <ConditionalComponent statement={userRole !== 'User'}>
                            <AddButton
                                icon={<UserIcon />}
                                text={'Tambah User'}
                                onClick={() => navigate('/user-management/create')}
                            />
                        </ConditionalComponent>
                        <ConditionalComponent
                            statement={userRole === 'National Leaders' || userRole === 'Area Leaders'}
                        >
                            <FloatingMenu
                                className="dotted-icon"
                                isClickMenu={true}
                                list={listHeadMenu}
                                buttonElement={<DottedIcon />}
                            />
                        </ConditionalComponent>

                        <ConditionalComponent statement={isDragFile}>
                            <UploadFullZone
                                maxSize={3}
                                closeButton
                                accessAllowed={'.csv'}
                                placeholder="Drop or Click file anywhere"
                                onSelectFile={(selectedFile) =>
                                    onChangeFile({
                                        selectedFile,
                                        onChangePopUp,
                                        onResetPopUp,
                                        onChangeSnackbarNotification,
                                    })
                                }
                                onClose={() => onChangeDragFile({ isDragFile: false })}
                            />
                        </ConditionalComponent>
                    </Flex>
                </Flex>
            </ConditionalComponent>
        </Box>
    );
};

export default Head;
