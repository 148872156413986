import React from 'react';

export const IconLeftTopCorner = () => (
    <svg
        data-testid="icon-left-top-corner__upload-full-zone"
        className="left-corner"
        width="64"
        height="64"
        viewBox="0 0 6 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6 1H5C4.07003 1 3.60504 1 3.22354 1.10222C2.18827 1.37962 1.37962 2.18827 1.10222 3.22354C1 3.60504 1 4.07003 1 5"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconRightTopCorner = () => (
    <svg
        data-testid="icon-right-top-corner__upload-full-zone"
        width="64"
        height="64"
        viewBox="0 0 6 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 1H1C1.92997 1 2.39496 1 2.77646 1.10222C3.81173 1.37962 4.62038 2.18827 4.89778 3.22354C5 3.60504 5 4.07003 5 5"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconRightBottomCorner = () => (
    <svg
        data-testid="icon-right-bottom-corner__upload-full-zone"
        width="64"
        height="64"
        viewBox="0 0 6 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 0V1C5 2.86923 5 3.80385 4.59808 4.5C4.33477 4.95606 3.95606 5.33477 3.5 5.59808C2.80385 6 1.86923 6 0 6"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconLeftBottomCorner = () => (
    <svg
        data-testid="icon-left-bottom-corner__upload-full-zone"
        width="64"
        height="64"
        viewBox="0 0 6 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 0V1C1 2.86923 1 3.80385 1.40192 4.5C1.66523 4.95606 2.04394 5.33477 2.5 5.59808C3.19615 6 4.13077 6 6 6"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);
