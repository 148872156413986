import queryString from 'qs';

const getBrandsRequest = (data) => {
    const payload = {
        searchparam: data?.search ? `name.${data?.search}` : null,
    };

    return queryString.stringify(payload, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getBrandsRequest;
