import { useDownloadNotification } from '@store/downloadNotification';
import { saveAs } from 'file-saver';
import { useMutation } from '@tanstack/react-query';
import fetchExportConfigurationHistory from '@data/configuration/fetchExportConfigurationHistory';

const   getExportConfigurationHistory = ({ fileName, id }) => {
    const { onChangeDownloadNotification } = useDownloadNotification();

    return useMutation({
        mutationFn: () => fetchExportConfigurationHistory({ id }),
        throwOnError: (error) => error,
        onMutate: () => {
            onChangeDownloadNotification({
                status: 'download',
                title: 'Proses Download Data',
                description: `Silahkan menunggu, sedang mengunduh ${fileName}`,
            });
        },
        onSuccess: (value) => {
            onChangeDownloadNotification({
                status: 'success',
                title: 'Success',
                description: `Download ${fileName} berhasil`,
            });
            const blob = new Blob([value], { type: 'text/xlsx' });

            saveAs(blob, `${fileName}`);
        },
        onError: () => {
            onChangeDownloadNotification({
                status: 'error',
                title: 'Failed',
                description: `Download ${fileName} gagal`,
            });
        },
    });
};

export default getExportConfigurationHistory;
