import React from 'react';
import { Box, Text, Flex, Table, Td, Th, Tr, Tbody, Thead } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { isEmpty } from 'lodash';
import EmptyState from '@components/emptyState';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { ChevronRightIcon, ChevronLeftIcon } from '@assets/icons';

import './styles.scss';
import { Fragment } from 'react';
import { TextBold, TextRegular } from '@components/typography';
import SkeletonComponent from '@components/skeleton';
import SortTableIcon from './components/sortIcon';

const TableTemplate2 = ({
    tableList,
    data,
    totalPage,
    page,
    handlePageClick,
    isDynamicTable,
    dataCount,
    totalData,
    isPagination,
    overflowTable = 'hidden',
    withHeader,
    emptyStateBottomComponent,
    isLoading,
    onSort,
    sortType,
}) => {
    return (
        <Box className="table-template2__container" data-testid="table-template2">
            <Box className="table-container" overflow={overflowTable} maxH={isLoading ? '622px' : 'unset'}>
                <Table w={isDynamicTable ? 'max-content' : '100%'}>
                    {/** --------------------------  TABLE HEAD -------------------------- */}
                    <ConditionalComponent statement={data?.length > 0 || isLoading}>
                        <Thead>
                            <Tr className="row-head__container">
                                <IterationComponent
                                    arr={tableList}
                                    render={({
                                        name,
                                        width,
                                        isDivider,
                                        value,
                                        justifyContent = 'flex-start',
                                        withSort,
                                    }) => (
                                        <Fragment>
                                            <Th
                                                className={`table-head__item ${isDivider && 'divider'}`}
                                                width={`${width}px`}
                                                maxWidth={`${width}px`}
                                                key={value}
                                            >
                                                <Flex
                                                    gap="8px"
                                                    justifyContent={justifyContent}
                                                    alignItems="center"
                                                    onClick={() => withSort && onSort(value)}
                                                    cursor={withSort ? 'pointer' : 'default'}
                                                >
                                                    <TextBold className="head-title">{name}</TextBold>
                                                    <SortTableIcon
                                                        sortType={sortType}
                                                        withSort={withSort}
                                                        value={value}
                                                    />
                                                </Flex>
                                            </Th>
                                        </Fragment>
                                    )}
                                />
                            </Tr>
                        </Thead>
                    </ConditionalComponent>

                    {/** --------------------------  TABLE COLUMN-------------------------- */}

                    <ConditionalComponent statement={data?.length > 0 && !isLoading}>
                        <Tbody>
                            <IterationComponent
                                arr={data}
                                render={(values, index) => (
                                    <Tr className="row-column__container" key={index}>
                                        <IterationComponent
                                            arr={tableList}
                                            render={(item) => (
                                                <Td
                                                    key={item?.value}
                                                    className={`table-column__item ${item.isDivider && 'divider'}`}
                                                    maxWidth={item?.width}
                                                >
                                                    {item.component({ value: values[item.value], values, index })}
                                                </Td>
                                            )}
                                        />
                                    </Tr>
                                )}
                            />
                        </Tbody>
                    </ConditionalComponent>

                    <ConditionalComponent statement={isLoading}>
                        <Tbody>
                            <IterationComponent
                                arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                render={(_, index) => (
                                    <Tr className="row-column__container row-skeleton" key={index}>
                                        <IterationComponent
                                            arr={tableList}
                                            render={(item) => (
                                                <Td
                                                    key={item?.value}
                                                    className={`table-column__item ${item.isDivider && 'divider'}`}
                                                    maxWidth={item?.maxWidth}
                                                >
                                                    <Flex width="100%" justifyContent={item?.justifyContent}>
                                                        <ConditionalComponent
                                                            statement={item?.value !== 'devider'}
                                                            fallback={<Box className="devider-component" />}
                                                        >
                                                            <SkeletonComponent
                                                                width="100%"
                                                                maxWidth={item?.skeleton?.width ?? '100px'}
                                                                height={item?.skeleton?.height ?? '20px'}
                                                            />
                                                        </ConditionalComponent>
                                                    </Flex>
                                                </Td>
                                            )}
                                        />
                                    </Tr>
                                )}
                            />
                        </Tbody>
                    </ConditionalComponent>
                </Table>

                {/** --------------------------  EMPTY STATE -------------------------- */}
                <ConditionalComponent statement={isEmpty(data) && !isLoading}>
                    <EmptyState
                        text={'Data Tidak Tersedia'}
                        emptyStateBottomComponent={emptyStateBottomComponent}
                        description={
                            <TextRegular>
                                Saat ini, data sell-in dan sell-out belum tersedia untuk bulan <b>Agustus 2024.</b>
                            </TextRegular>
                        }
                    />
                </ConditionalComponent>
            </Box>

            <ConditionalComponent statement={isPagination}>
                <Box className="section-pagination__container">
                    <Text className="showing-text">
                        Menampilkan {dataCount} dari {totalData}
                    </Text>

                    <Flex className="pagination-container">
                        <ReactPaginate
                            nextLabel={<ChevronRightIcon />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={totalPage}
                            forcePage={page - 1}
                            previousLabel={<ChevronLeftIcon />}
                        />
                    </Flex>
                </Box>
            </ConditionalComponent>
        </Box>
    );
};

export default TableTemplate2;
