import updateConfigurationInfoRequest from '@domain/model/request/config/updateConfigurationInfoRequest';
import updateConfigurationInfo from '@hooks/configuration/updateConfigurationInfo';

const useUpdateConfigurationInfo = ({ id, configuration, refetchConfiguration }) => {
    const payload = updateConfigurationInfoRequest({ configuration });

    return updateConfigurationInfo({ id, payload, refetchConfiguration });
};

export default useUpdateConfigurationInfo;
