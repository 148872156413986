import { Button } from '@chakra-ui/react';
import React from 'react';
import './styles.css';

const ButtonRegular = ({
    component,
    width,
    height,
    color = 'var(--color-primary)',
    onClick,
    isLoading,
    borderRadius,
    isDisabled,
}) => {
    return (
        <Button
            className={`button-regular__container ${isLoading && 'button-regular__container--loading'}`}
            width={width}
            height={height}
            borderColor={isDisabled ? 'var(--color-neutral40)' : color}
            background={isDisabled ? 'var(--color-neutral10)' : color}
            borderRadius={borderRadius ?? '4px !important'}
            onClick={!isLoading && !isDisabled && onClick}
        >
            {component}
        </Button>
    );
};

export default ButtonRegular;
