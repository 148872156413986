import { Box } from '@chakra-ui/react';
import styles from './styles.module.css';
import { BlackArrowIcon } from '@assets/icons/index';

const TooltipComponent = ({ children, positionArrowLeft, maxWidth, content }) => {
    return (
        <Box className={styles['tooltip__container']}>
            <Box className={styles['tooltip-trigger__button']}>{children}</Box>
            <Box className={`${styles['tooltip__content']} tooltip__content`} maxWidth={maxWidth}>
                <Box className={styles['icon__container']} left={positionArrowLeft}>
                    <BlackArrowIcon />
                </Box>
                <Box className={styles['tooltip-labels__container']}>{content}</Box>
            </Box>
        </Box>
    );
};

export default TooltipComponent;
