import React, { useState } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { FileUploader } from 'react-drag-drop-files';
import { ChevronLeftIcon, DownloadIcon } from '@chakra-ui/icons';
import { ConditionalComponent } from '@components/functionComponent';

import { UploadIcon } from '@assets/icons';
import { useSnackbarNotification } from '@store/snackbarNotification';
import './styles.scss';

const UploadZone = ({ title, onClose, onDownloadTemplateFile, isDownloadTemplate, onUploadFile, fileTypeAllowed }) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const [selectedFile, setSelectedFile] = useState(null);

    return (
        <Box className="upload-zone__container">
            <Box className="overlay" onClick={onClose} />
            <Box className="box-content fade-animation">
                <Flex className="head">
                    <Flex alignItems="center">
                        <Box className="icon-back" onClick={onClose}>
                            <ChevronLeftIcon color="var(--main-color-dark)" fontSize="24px" />
                        </Box>
                        <Text className="title" onClick={onClose} pl="16px">
                            {title}
                        </Text>
                    </Flex>
                    <ConditionalComponent statement={isDownloadTemplate}>
                        <Flex className="download-button" onClick={onDownloadTemplateFile}>
                            <Text className="download-text">Download template</Text>
                            <DownloadIcon color="var(--main-color-dark)" />
                        </Flex>
                    </ConditionalComponent>
                </Flex>
                <Flex className="body">
                    <Box className={`upload-zone__container ${selectedFile && 'selected-file__container'}`}>
                        <UploadIcon color={selectedFile ? '#588669' : '#868686'} />
                        <Text className="button-text">
                            {selectedFile ? selectedFile?.name : 'Choose file to upload'}
                        </Text>
                        <FileUploader
                            classes="upload-zone"
                            handleChange={(selectedFile) => setSelectedFile(selectedFile)}
                            onTypeError={(err) => {
                                onChangeSnackbarNotification({
                                    status: 'error',
                                    title: 'Failed',
                                    description: err || 'File Error!',
                                    duration: 3,
                                });
                            }}
                            onSizeError={() => {
                                onChangeSnackbarNotification({
                                    status: 'error',
                                    title: 'Failed',
                                    description: 'File maximal berukuran 3MB!',
                                    duration: 3,
                                });
                            }}
                            types={fileTypeAllowed}
                            maxSize={3}
                            before
                        />
                    </Box>
                    <Button
                        _hover={{ background: 'blue' }}
                        isDisabled={!selectedFile?.name}
                        className="submit"
                        onClick={() => onUploadFile(selectedFile)}
                    >
                        Upload
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

export default UploadZone;
