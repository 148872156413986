import React from 'react';
import { Box } from '@chakra-ui/react';
import './styles.scss';
import { IterationComponent } from '@components/functionComponent';
import OutsideClickHandler from 'react-outside-click-handler';

const FloatingList = ({ arr, renderElement, onClose }) => (
    <OutsideClickHandler onOutsideClick={onClose}>
        <Box className="floating-list__container fade-animation-top" data-testid="floating-list__container">
            <IterationComponent arr={arr} render={(item, idx) => renderElement(item, idx)} />
        </Box>
    </OutsideClickHandler>
);

export default FloatingList;
