import fetchSalesAreas from '@data/salesArea/fetchSalesAreas';
import { useQuery } from '@tanstack/react-query';

const getSalesAreas = ({ enabled, queries }) => {
    return useQuery({
        queryKey: ['sales-areas', queries],
        queryFn: async () => await fetchSalesAreas({ queries }),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getSalesAreas;
