import React, { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import { InformationBlackIcon, MtdDecreaseIcon, MtdGrowIcon, SellinIcon } from '@assets/icons/index';
import './styles.css';
import FilterMonth from '@components/filter/month';
import useGetSisoStatistics from '@domain/siso/useGetSisoStatistics';
import { formatCurrency } from '@helpers/utils';
import { getMtdAttribute, getMtdIcon } from '@pages/BusinessSiso/helpers/utils';
import { ConditionalComponent } from '@components/functionComponent';
import StatisticsSkeleton from '@pages/BusinessSiso/components/statisticsSkeleton';
import { useFilter } from '@pages/BusinessSiso/state/filters';
import { useStatistics } from '@pages/BusinessSiso/state/statistics';
import TooltipComponent from '@components/tooltip';
import { TooltipStatistictontent } from '@pages/BusinessSiso/components/toolipContent';

const Statistics = () => {
    const filters = useFilter();
    const statistics = useStatistics();
    const { data, isLoading } = useGetSisoStatistics(filters, statistics);

    const mtdAttributeSellin = getMtdAttribute(data?.sellin?.status);
    const mtdAttributeSellout = getMtdAttribute(data?.sellout?.status);

    return (
        <Box className="statistic__container">
            <Flex className="statistic__head">
                <TextBold className="statistic__title  no-highlight__text">
                    Statistik Sell-In dan Sell-Out (Bulanan)
                    <TooltipComponent
                        position="center"
                        content={<TooltipStatistictontent />}
                        maxWidth="512px"
                        positionArrowLeft="250px"
                    >
                        <InformationBlackIcon />
                    </TooltipComponent>
                </TextBold>
                <FilterMonth
                    onChange={({ month, year }) =>
                        statistics.setStatistics({ statisticMonth: month, statisticYear: year })
                    }
                />
            </Flex>

            <ConditionalComponent statement={!isLoading} fallback={<StatisticsSkeleton />}>
                <Flex className="statistic__content">
                    <Box className="statistic__sellin">
                        <TextRegular className="statistic__sellin-text">
                            <SellinIcon />
                            Total Sell-In
                        </TextRegular>
                        <Flex>
                            <TextRegular className="statistic__total-sellin-text">
                                {formatCurrency(data?.sellin?.total)}
                            </TextRegular>
                            <Box>
                                <Flex mb="2px" gap="4px">
                                    {mtdAttributeSellin?.icon}
                                    <TextBold
                                        className="statistic__sellin-percentage"
                                        color={mtdAttributeSellin?.color}
                                    >
                                        {mtdAttributeSellin?.symbol}
                                        {data?.sellin?.mtd}%
                                    </TextBold>
                                </Flex>
                                <TextRegular className="statistic__sellin-mtd-text">Berdasarkan MTD</TextRegular>
                            </Box>
                        </Flex>
                    </Box>

                    <Box className="statistic__sellout">
                        <TextRegular className="statistic__sellout-text">
                            <SellinIcon transform={'scaleX(-1)'} />
                            Total Sell-Out
                        </TextRegular>
                        <Flex>
                            <TextRegular className="statistic__total-sellout-text">
                                {formatCurrency(data?.sellout?.total)}
                            </TextRegular>
                            <Box>
                                <Flex mb="2px" gap="4px">
                                    {mtdAttributeSellout.icon}

                                    <TextBold
                                        className="statistic__sellout-percentage"
                                        color={mtdAttributeSellout?.color}
                                    >
                                        {mtdAttributeSellout?.symbol}
                                        {data?.sellout?.mtd}%
                                    </TextBold>
                                </Flex>
                                <TextRegular className="statistic__sellout-mtd-text">Berdasarkan MTD</TextRegular>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </ConditionalComponent>
        </Box>
    );
};

export default Statistics;
