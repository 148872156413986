import fetchSisoStatistics from '@data/siso/fetchSisoStatistics';
import { useQuery } from '@tanstack/react-query';

const getSisoStatistics = (queries) => {
    return useQuery({
        queryKey: ['siso-statistics', queries],
        queryFn: () => fetchSisoStatistics(queries),
        throwOnError: (error) => error,
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getSisoStatistics;
