import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import ButtonOutline from '@components/buttons/buttonOutline';
import ButtonRegular from '@components/buttons/buttonRegular';
import { TextBold, TextRegular } from '@components/typography';
import styles from './styles.module.css';

const ModalConfirmation = ({
    isOpen,
    title,
    onClose,
    description,
    submitButtonText,
    cancelButtonText,
    width,
    borderRadius,
    onCancelClick,
    onSubmitClick,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay onClick={onClose} />
            <ModalContent margin={0} alignItems="center" alignSelf="center" width={width} borderRadius={borderRadius}>
                <Box className={styles['modal-confirmation__container']}>
                    <Flex width="100%" justifyContent="space-between" alignItems="center" mb="24px">
                        <TextBold className={styles['title']}>{title}</TextBold>
                        <CloseIcon fontSize="15px" />
                    </Flex>
                    <TextRegular className={styles['description']}>{description}</TextRegular>
                    <Flex justifyContent="center" width="100%" gap="12px">
                        <ButtonOutline
                            borderRadius="6px !important"
                            color="var(--color-primary)"
                            onClick={onCancelClick}
                            component={<TextBold color="var(--color-primary)">{cancelButtonText}</TextBold>}
                        />
                        <ButtonRegular
                            borderRadius="6px !important"
                            color="var(--color-primary)"
                            onClick={onSubmitClick}
                            component={<TextBold color="white">{submitButtonText}</TextBold>}
                        />
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalConfirmation;
