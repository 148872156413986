import { useMutation, useQueryClient } from '@tanstack/react-query';

const sortSisoTable = (queries) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (response) => response,
        onSuccess: (response) => {
            queryClient.setQueryData(['siso-table', queries], response);
        },
    });
};

export default sortSisoTable;
