import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useDownloadNotification } from '@store/downloadNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { useSelectedFilterStore } from '@pages/StoreConfig/helpers/mainState';
import { onDownloadStoreConfig } from '@pages/StoreConfig/helpers/crud';
import { useDragFile } from '@store/dragFile';

const ImportButton = () => {
    const { onChangeDragFile } = useDragFile();

    return (
        <Box className="list-button__store-config" onClick={() => onChangeDragFile({ isDragFile: true })}>
            <Text>Import</Text>
        </Box>
    );
};

const ExportButton = () => {
    const { onChangeDownloadNotification, status } = useDownloadNotification();
    const store = useSelectedFilterStore();

    return (
        <Box
            className={`list-button__store-config ${status === 'download' && 'ondownload-process'}`}
            onClick={() => onDownloadStoreConfig({ onChangeDownloadNotification, store })}
        >
            <Text>Export</Text>
            <ConditionalComponent statement={status === 'download'}>
                <Spinner color="var(--main-color)" size="sm" data-testId="spinner-export-store-config" />
            </ConditionalComponent>
        </Box>
    );
};
export const listHeadMenu = [<ImportButton />, <ExportButton />];
