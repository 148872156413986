import { Box, Flex } from '@chakra-ui/react';
import './styles.css';
import { TextBold, TextRegular } from '@components/typography';
import Select from '@components/filter/select';
import useGetSystemType from '@domain/systemType/useGetSystemType';
import { useParams } from 'react-router-dom';
import useGetTemplateUpload from '@domain/templateUpload/useTemplateUpload';
import { find } from 'lodash';
import { ConditionalComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';

const StoreInfo = ({ configuration, isLoadingConfiguration }) => {
    const { id } = useParams();

    const {
        data: systemType,
        refetch: refetchSystemType,
        isLoading: isLoadingSystemType,
    } = useGetSystemType({ enabled: false });

    const {
        data: uploadTemplate,
        refetch: refetchUploadTemplate,
        isLoading: isLoadingUploadTemplate,
    } = useGetTemplateUpload({ enabled: false });

    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);

    const onChangeTemplateUpload = (value) => {
        const predefinedSelectedConfig = find(uploadTemplate, { id: value?.id })?.predefined_selected_config ?? {};
        updateConfigurationInfoState?.mutate({ ...predefinedSelectedConfig, template_upload: value });
    };

    return (
        <Box className="store-config-detail__store-info__container">
            <Box className="store-name__container">
                <ConditionalComponent
                    statement={!isLoadingConfiguration}
                    fallback={<SkeletonComponent width="480px" height="24px" />}
                >
                    <TextBold className="store-name">{configuration?.nama_outlet}</TextBold>
                </ConditionalComponent>
            </Box>

            <Flex mb="24px">
                <Box className="store-info__item">
                    <TextBold className="store-info__label">Customer ID</TextBold>
                    <ConditionalComponent
                        statement={!isLoadingConfiguration}
                        fallback={<SkeletonComponent width="120px" height="20px" />}
                    >
                        <TextRegular className="store-info__value">{configuration?.customer_id}</TextRegular>
                    </ConditionalComponent>
                </Box>

                <Box className="store-info__item">
                    <TextBold className="store-info__label">Store Group</TextBold>
                    <ConditionalComponent
                        statement={!isLoadingConfiguration}
                        fallback={<SkeletonComponent width="120px" height="20px" />}
                    >
                        <TextRegular className="store-info__value">{configuration?.group}</TextRegular>
                    </ConditionalComponent>
                </Box>

                <Box className="store-info__item">
                    <TextBold className="store-info__label">Kode Tipe Outlet</TextBold>
                    <ConditionalComponent
                        statement={!isLoadingConfiguration}
                        fallback={<SkeletonComponent width="120px" height="20px" />}
                    >
                        <TextRegular className="store-info__value">{configuration?.kode_tipe_outlet}</TextRegular>
                    </ConditionalComponent>
                </Box>

                <Box className="store-info__item">
                    <TextBold className="store-info__label">Store DC</TextBold>
                    <ConditionalComponent
                        statement={!isLoadingConfiguration}
                        fallback={<SkeletonComponent width="120px" height="20px" />}
                    >
                        <TextRegular className="store-info__value">{configuration?.nama_dc}</TextRegular>
                    </ConditionalComponent>
                </Box>

                <Box className="store-info__item">
                    <TextBold className="store-info__label">Sales Area</TextBold>
                    <ConditionalComponent
                        statement={!isLoadingConfiguration}
                        fallback={<SkeletonComponent width="120px" height="20px" />}
                    >
                        <TextRegular className="store-info__value">{configuration?.sales_area}</TextRegular>
                    </ConditionalComponent>
                </Box>
            </Flex>
            <Flex gap="24px">
                <Box className="store-info__item">
                    <Select
                        isloadingComponent={isLoadingConfiguration}
                        selected={configuration?.system_type}
                        title="Tipe Integrasi"
                        isMultiple={false}
                        placeholder="Pilih Tipe Integrasi"
                        isGetAll={true}
                        refetchData={refetchSystemType}
                        data={systemType}
                        isLoading={isLoadingSystemType}
                        isCanSearch={false}
                        onChangeFilter={(value) => updateConfigurationInfoState?.mutate({ system_type: value })}
                    />
                </Box>

                <Box className="store-info__item">
                    <Select
                        isloadingComponent={isLoadingConfiguration}
                        selected={configuration?.template_upload}
                        title="Template"
                        isMultiple={false}
                        placeholder="Pilih Template"
                        isGetAll={true}
                        refetchData={refetchUploadTemplate}
                        data={uploadTemplate}
                        isLoading={isLoadingUploadTemplate}
                        isCanSearch={false}
                        onChangeFilter={(value) => onChangeTemplateUpload(value)}
                    />
                </Box>
            </Flex>
        </Box>
    );
};

export default StoreInfo;
