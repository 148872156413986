import { Box, Text, Flex } from '@chakra-ui/react';
import { isNumber, isObject } from 'lodash';
import { currencyFormatted } from './function';
import React from 'react';

export const tableList = [
    {
        name: '2023',
        value: 'month',
        width: 264,
        component: ({ value }) => (
            <Flex>
                <Text className="column-text__child">{value}</Text>
            </Flex>
        ),
    },
    {
        name: 'Penjualan Wardah',
        width: 500,
        value: 'selloutWardah',
        children: ['Penjualan Sellout (Nett)'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value &&
                    ['revenue'].map((child) => (
                        <Text className="column-text__child">
                            Rp {currencyFormatted({ value: value[child] }) || '-'}
                        </Text>
                    ))}
            </Flex>
        ),
    },
    {
        name: 'Penjualan Emina',
        width: 500,
        value: 'selloutEmina',
        children: ['Penjualan Sellout (Nett)'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value &&
                    ['revenue'].map((child) => (
                        <Text className="column-text__child">
                            Rp {currencyFormatted({ value: value[child] }) || '-'}
                        </Text>
                    ))}
            </Flex>
        ),
    },
    {
        name: 'Penjualan Make Over',
        width: 500,
        value: 'selloutMakeOver',
        children: ['Penjualan Sellout (Nett)'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value &&
                    ['revenue'].map((child) => (
                        <Text className="column-text__child">
                            Rp {currencyFormatted({ value: value[child] }) || '-'}
                        </Text>
                    ))}
            </Flex>
        ),
    },
];

export const tableActual = [
    {
        name: '-',
        value: 'name',
        width: 264,
        component: ({ value }) => (
            <Flex>
                <Text className="column-text__child">{value}</Text>
            </Flex>
        ),
    },
    {
        name: 'Wardah',
        width: 600,
        value: 'wardah',
        children: ['Semester 1', 'Semester 2', 'Tahunan'],
        component: ({ value, type }) => (
            <Flex className="column__item-child">
                {value && isObject(value) ? (
                    ['Semester1', 'Semester2', 'Tahunan'].map((child) => (
                        <Text className="column-text__child">
                            {type === '%'
                                ? value[child]
                                    ? `${value[child]}%`
                                    : '-'
                                : value[child] !== 'empty'
                                ? `Rp ${currencyFormatted({ value: value[child] }) || '-'}`
                                : ''}
                        </Text>
                    ))
                ) : (
                    <>
                        {value === 'empty' ? (
                            <Box className="empty-state__table"></Box>
                        ) : value ? (
                            <Text className="column-text__child">
                                {isNumber(value) ? `Rp ${currencyFormatted({ value }) || '-'}` : value}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Flex>
        ),
    },
    {
        name: 'Emina',
        width: 600,
        value: 'emina',
        children: ['Semester 1', 'Semester 2', 'Tahunan'],
        component: ({ value, type }) => (
            <Flex className="column__item-child">
                {value && isObject(value) ? (
                    ['Semester1', 'Semester2', 'Tahunan'].map((child) => (
                        <Text className="column-text__child">
                            {type === '%'
                                ? value[child]
                                    ? `${value[child]}%`
                                    : '-'
                                : `Rp ${currencyFormatted({ value: value[child] }) || '-'}`}
                        </Text>
                    ))
                ) : (
                    <>
                        {value === 'empty' ? (
                            <Box className="empty-state__table"></Box>
                        ) : value ? (
                            <Text className="column-text__child">Rp {currencyFormatted({ value }) || '-'}</Text>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Flex>
        ),
    },
    {
        name: 'Make Over',
        width: 600,
        value: 'makeover',
        children: ['Semester 1', 'Semester 2', 'Tahunan'],
        component: ({ value, type }) => (
            <Flex className="column__item-child">
                {value && isObject(value) ? (
                    ['Semester1', 'Semester2', 'Tahunan'].map((child) => (
                        <Text className="column-text__child">
                            {type === '%'
                                ? value[child]
                                    ? `${value[child]}%`
                                    : '-'
                                : `Rp ${currencyFormatted({ value: value[child] }) || '-'}`}
                        </Text>
                    ))
                ) : (
                    <>
                        {value === 'empty' ? (
                            <Box className="empty-state__table"></Box>
                        ) : value ? (
                            <Text className="column-text__child">Rp {currencyFormatted({ value }) || '-'}</Text>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Flex>
        ),
    },
];
