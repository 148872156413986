import authAxios from '@services/authAxios';

const fetchSyncToExagonInventory = async (id) => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/replenishment/${id}`,
    });

    return data?.data;
};

export default fetchSyncToExagonInventory;
