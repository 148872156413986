import axios from 'axios';
import Cookies from 'universal-cookie';
const Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
Axios.interceptors.request.use(
    (config) => {
        const cookies = new Cookies();
        const token = cookies.get('userToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default Axios;
