import authAxios from '@services/authAxios';

const fetchExportSisoTable = async (queries) => {
    const data = await authAxios({
        method: 'GET',
        url: `/api/v1/siso/brands/export?${queries}`,
        responseType: 'blob',
    });

    return data?.data;
};

export default fetchExportSisoTable;
