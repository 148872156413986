import { find, forEach, isEmpty } from 'lodash';
import Cookies from 'universal-cookie';

export const isCancelAccessHandler = () => {
    const cookies = new Cookies();
    const listAccess = cookies.get('menuAccess');
    const isCancel = find(listAccess, { link: 'cancel_sellout' });
    return !!isCancel;
};

export const brandQuantityFormatted = ({ qty, setBrandQuantity }) => {
    const arr = [];
    forEach(qty, (item, key) => {
        if (key !== 'total') {
            arr.push({ ...item, brand: key?.replace('brand:', '') });
        }
    });

    setBrandQuantity(arr);
};

export const onFormattingOptionFilter = ({ arr, filterName, filterId }) => {
    return arr?.map((item) => ({
        name: item[filterName],
        id: item[filterId],
    }));
};

export const templateFormatting = ({ data }) => {
    return data?.map((item) => {
        return {
            ...item,
            label: item?.name,
            value: item?.name,
        };
    });
};

export const assetFormatting = ({ data }) => {
    data = data?.map((item) => {
        return {
            ...item,
            label: item?.name,
            value: item?.name,
        };
    });

    return data;
};

const isellerUrlConfig = (url) => {
    if (isEmpty(url)) return null;

    //remove empty empty string
    url = url
        ?.split('')
        ?.filter((item) => item !== ' ')
        ?.join('');

    //add "/" if doesnt have

    return url[url.length - 1] === '/' ? url : url + '/';
};

export const onDetailStorePayloadFormatting = ({ storeInfo }) => {
    return {
        template_upload: storeInfo?.template_upload?.name || 'custom',
        transfer_status: storeInfo.transfer_status?.value,
        iseller_url: isellerUrlConfig(storeInfo.iseller_url),
        tag: storeInfo?.tag?.value || null,
        is_sync_create_product: storeInfo.is_sync_create_product,
        is_sync_transfer_in: storeInfo.is_sync_transfer_in,
        is_sync_bivi_dashboard: Boolean(storeInfo.is_sync_bivi_dashboard),
        is_sync_update_product: storeInfo.is_sync_update_product,
        customer_id_konsi: storeInfo?.is_sync_consignment ? storeInfo?.customer_id_konsi : null,
        is_sync_consignment: storeInfo?.is_sync_consignment,
        is_update_product_with_price: storeInfo.is_update_product_with_price,
        is_trusted_iseller: storeInfo.is_trusted_iseller,
        is_active: storeInfo.is_active,
        is_create_product_with_price: storeInfo.is_create_product_with_price,
        is_create_product_with_description: storeInfo.is_create_product_with_description,
        is_create_product_continue_selling_when_soldout: storeInfo.is_create_product_continue_selling_when_soldout,
        is_create_product_with_attribute: storeInfo.is_create_product_with_attribute,
        is_update_product_with_name: storeInfo.is_update_product_with_name,
        is_update_product_with_description: storeInfo.is_update_product_with_description,
        is_update_product_continue_selling_when_soldout: storeInfo.is_update_product_continue_selling_when_soldout,
        is_update_product_with_attribute: storeInfo.is_update_product_with_attribute,
        system_type_id: storeInfo?.system_type?.id,
        exagon_id: storeInfo?.exagon_id,
        is_replenishment: storeInfo?.is_replenishment,
        is_replenishment_pending: false,
        is_get_order: storeInfo?.is_get_order,
        is_outbound: storeInfo?.is_outbound,
    };
};

export const getStatusColor = (name) => {
    const obj = {
        Success: 'green !important',
        Inprogress: 'var(--main-color) !important',
        Failed: 'red !important',
    };

    return obj[name] || '#2f2f2f';
};

export const onCheckBackgroundProgress = (arr) => {
    let result = true;

    result = find(arr, { status: 'Inprogress' })?.status;

    return result;
};
