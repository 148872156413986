import React from 'react';
import { DottedIcon } from '@assets/icons';
import { Text, Box, Flex } from '@chakra-ui/react';
import FilterDropdown from '@components/filter/dropdown';
import FilterSearch from '@components/filter/search';
import { ConditionalComponent } from '@components/functionComponent';
import FloatingMenu from '@components/floatingMenu';
import UploadFullZone from '@components/uploadFullZone';
import { onChangeFile } from '@pages/StoreConfig/helpers/event';
import { useDragFile } from '@store/dragFile';
import { usePopUp } from '@store/popup';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { fetchDC, fetchSalesArea, fetchCustomer, fetchSystem } from '@pages/StoreConfig/helpers/crud';
import {
    useFilterCustomerStore,
    useFilterDCStore,
    useFilterSalesAreaStore,
    useFilterSystemStore,
    useSelectedFilterStore,
} from '@pages/StoreConfig/helpers/mainState';
import { listHeadMenu } from './const';

import './styles.scss';

const Head = ({ onFetchStoreConfig }) => {
    const { isDragFile, onChangeDragFile } = useDragFile();
    const { onChangePopUp, onResetPopUp } = usePopUp();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const { onChangeFilterDCOptionStore, dcOptions, isLoadingDCOption } = useFilterDCStore();
    const { systemOptions, isLoadingSystemOption, onChangeFilterSystemOptionStore, onResetFilterSystemOptionStore } =
        useFilterSystemStore();
    const {
        isLoadingSalesAreaOption,
        salesAreaOptions,
        searchSalesAreaQuery,
        onChangeFilterSalesAreaOptionStore,
        onResetFilterSalesAreaOptionStore,
    } = useFilterSalesAreaStore();

    const {
        isLoadingCustomerOption,
        customerOptions,
        onChangeFilterCustomerOptionStore,
        searchCustomerQuery,
        onResetFilterCustomerOptionStore,
    } = useFilterCustomerStore();

    const { onChangeFilterSelectedStore, storeConfigSystem } = useSelectedFilterStore();

    const onFetchDC = () => fetchDC({ onChangeFilterDCOptionStore });
    const onFetchSystem = () => fetchSystem({ onChangeFilterSystemOptionStore });
    const onFetchSalesArea = ({ searchQuery }) => fetchSalesArea({ onChangeFilterSalesAreaOptionStore, searchQuery });
    const onFetchCustomer = ({ searchQuery }) => fetchCustomer({ onChangeFilterCustomerOptionStore, searchQuery });

    return (
        <Box className="section-head__store-config">
            <Text className="title">Store Config</Text>
            <Flex justifyContent="space-between">
                <Flex>
                    <FilterDropdown
                        placeholder="DC"
                        options={dcOptions}
                        maxFilter={3}
                        onFetchData={onFetchDC}
                        isLoading={isLoadingDCOption}
                        filterId="filter-status__store-config"
                        onSelect={({ selected }) => onChangeFilterSelectedStore({ selectedDC: selected, page: 1 })}
                    />
                    <FilterSearch
                        placeholder="Area"
                        options={salesAreaOptions}
                        maxFilter={3}
                        searchQuery={searchSalesAreaQuery}
                        onClose={onResetFilterSalesAreaOptionStore}
                        onSearchQuery={(value) => onChangeFilterSalesAreaOptionStore({ searchSalesAreaQuery: value })}
                        onFetchData={({ searchQuery }) => onFetchSalesArea({ searchQuery })}
                        isLoading={isLoadingSalesAreaOption}
                        filterId="filter-salesarea__store-config"
                        onSelect={({ selected }) =>
                            onChangeFilterSelectedStore({ selectedSalesArea: selected, page: 1 })
                        }
                    />
                    <FilterSearch
                        placeholder="Store"
                        options={customerOptions}
                        maxFilter={3}
                        searchQuery={searchCustomerQuery}
                        onClose={onResetFilterCustomerOptionStore}
                        onSearchQuery={(value) => onChangeFilterCustomerOptionStore({ searchCustomerQuery: value })}
                        onFetchData={({ searchQuery }) => onFetchCustomer({ searchQuery })}
                        isLoading={isLoadingCustomerOption}
                        filterId="filter-customer__store-config"
                        onSelect={({ selected }) =>
                            onChangeFilterSelectedStore({ selectedCustomer: selected, page: 1 })
                        }
                    />

                    <FilterDropdown
                        isMultiple={false}
                        placeholder="Consigment"
                        options={[
                            { name: 'True', id: true },
                            { name: 'False', id: false },
                        ]}
                        filterId="filter-system__store-config"
                        onSelect={({ selected }) =>
                            onChangeFilterSelectedStore({ storeConfigConsignment: selected, page: 1 })
                        }
                    />
                    <FilterDropdown
                        isMultiple={false}
                        placeholder="Status"
                        options={[
                            { name: 'Active', id: true },
                            { name: 'Nonactive', id: false },
                        ]}
                        filterId="filter-status__store-config"
                        onSelect={({ selected }) =>
                            onChangeFilterSelectedStore({ storeConfigStatus: selected, page: 1 })
                        }
                    />

                    <FilterDropdown
                        isMultiple={false}
                        placeholder="System"
                        isLoading={isLoadingSystemOption}
                        options={systemOptions}
                        filterId="filter-system__store-config"
                        onFetchData={onFetchSystem}
                        currentValue={storeConfigSystem}
                        onSelect={({ selected }) =>
                            onChangeFilterSelectedStore({ storeConfigSystem: selected, page: 1 })
                        }
                    />
                </Flex>
                <Flex>
                    <FloatingMenu
                        className="dotted-icon"
                        isClickMenu={true}
                        list={listHeadMenu}
                        buttonElement={<DottedIcon />}
                    />

                    <ConditionalComponent statement={isDragFile}>
                        <UploadFullZone
                            maxSize={3}
                            closeButton
                            accessAllowed={'.csv,.xls,.xlsx'}
                            placeholder="Drop or Click file anywhere"
                            onSelectFile={(selectedFile) =>
                                onChangeFile({
                                    selectedFile,
                                    onChangePopUp,
                                    onResetPopUp,
                                    onChangeSnackbarNotification,
                                    onFetchStoreConfig,
                                })
                            }
                            onClose={() => onChangeDragFile({ isDragFile: false })}
                        />
                    </ConditionalComponent>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Head;
