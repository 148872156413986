import { Checkbox, Table, Td, Th, Tr } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import styles from './styles.module.css';
import './styles.css';
import { useParams } from 'react-router-dom';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';

const TableComponent = ({ configuration, isLoadingConfiguration }) => {
    const { id } = useParams();
    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);
    return (
        <Table className={`${styles['table-component__container']} table-component__container`}>
            <Tr className={styles['head-row']}>
                <Th className={styles['table-head']}>Akses</Th>
                <Th className={styles['table-head']} width="120px" textAlign="center">
                    Create
                </Th>
                <Th className={styles['table-head']} width="120px" textAlign="center">
                    Update
                </Th>
            </Tr>
            <Tr className={styles['body-row']}>
                <Td className={styles['table-body']}>
                    <TextBold className={styles['title-text']}>Status</TextBold>
                    <TextRegular className={styles['description-text']}>
                        Aktivasi pipeline Create dan Update{' '}
                    </TextRegular>
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_sync_create_product}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_sync_create_product: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_sync_update_product}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_sync_update_product: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
            </Tr>

            <Tr className={styles['body-row']}>
                <Td className={styles['table-body']}>
                    <TextBold className={styles['title-text']}>Harga Produk</TextBold>
                    <TextRegular className={styles['description-text']}>
                        Sinkronisasi Create dan Update dengan harga terbaru{' '}
                    </TextRegular>
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_create_product_with_price}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_create_product_with_price: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_update_product_with_price}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_update_product_with_price: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
            </Tr>

            <Tr className={styles['body-row']}>
                <Td className={styles['table-body']}>
                    <TextBold className={styles['title-text']}>Nama Produk</TextBold>
                    <TextRegular className={styles['description-text']}>
                        Menambahkan keterangan nama produk di iSeller
                    </TextRegular>
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        className="disabled-checked"
                        isChecked={true}
                        disabled
                    />
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_update_product_with_name}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_update_product_with_name: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
            </Tr>

            <Tr className={styles['body-row']}>
                <Td className={styles['table-body']}>
                    <TextBold className={styles['title-text']}>Tetap Jual dengan Stok Habis</TextBold>
                    <TextRegular className={styles['description-text']}>
                        {'Produk tidak bisa dijual apabila stock < 0 di iSeller'}
                    </TextRegular>
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_create_product_continue_selling_when_soldout}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_create_product_continue_selling_when_soldout: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
                <Td className={styles['table-body']} width="120px" textAlign="center">
                    <Checkbox
                        isLoading={isLoadingConfiguration}
                        isChecked={configuration?.is_update_product_continue_selling_when_soldout}
                        onChange={(e) =>
                            updateConfigurationInfoState?.mutate({
                                is_update_product_continue_selling_when_soldout: e.target.checked,
                                template_upload: { id: 'Custom', name: 'Custom' },
                            })
                        }
                    />
                </Td>
            </Tr>
        </Table>
    );
};

export default TableComponent;
