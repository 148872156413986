import { Image } from '@chakra-ui/react';
import React from 'react';

import emptyStateImage from './empty-state.svg';
import errorStateImage from './error-state.svg';
import errorState401Image from './error-state-401.svg';
import errorState404Image from './error-state-404.svg';
import errorState500Image from './error-state-500.svg';
import errorStateNetworkImage from './error-state-network.svg';
import dashboardImage from './dashboard.svg';

export const EmptyStateImage = () => <Image src={emptyStateImage} />;
export const ErrorStateImage = () => <Image src={errorStateImage} />;
export const ErrorState401Image = () => <Image src={errorState401Image} />;
export const ErrorState404Image = () => <Image src={errorState404Image} />;
export const ErrorState500Image = () => <Image src={errorState500Image} />;
export const DashboardImage = () => <Image src={dashboardImage} />;
export const ErrorStateNetworkImage = () => <Image src={errorStateNetworkImage} />;
