import { Box, Flex, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold, TextRegular } from '@components/typography';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { getprogressData } from './const';
import SyncSkeleton from '../syncSkeleton';
import { CloseRedIcon, CopyIcon } from '@assets/icons/index';
import { upperFirst } from 'lodash';
import copy from 'copy-to-clipboard';
import { useSnackbarNotification } from '@store/snackbarNotification';
import ButtonOutline from '@components/buttons/buttonOutline';
import ButtonRegular from '@components/buttons/buttonRegular';

const SyncModal = ({ isOpen, data, onClose, isLoading, onRefetchSync }) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const {
        isOpen: isOpenErrorMessage,
        onClose: onCloseErrorMessage,
        onOpen: onOpenErrorMessage,
    } = useDisclosure({ defaultIsOpen: true });

    const copyToClipboard = () => {
        copy(data?.status?.message);

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Berhasil',
            description: `Text berhasil di copy ke clipboard`,
            duration: 3,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay onClick={onClose} />
            <ModalContent maxWidth="unset" width={'967px'} margin={0} alignItems="center" alignSelf="center">
                <ConditionalComponent statement={!isLoading} fallback={<SyncSkeleton onClose={onClose} />}>
                    <Box className={styles['sync-modal__container']}>
                        <Flex mb="8px" alignItems="center" justify="space-between">
                            <TextBold className={styles['title']}>Sync Exagon Inventory</TextBold>
                            <CloseIcon
                                cursor="pointer"
                                color="var(--semantic-text)"
                                fontSize="14px"
                                onClick={onClose}
                            />
                        </Flex>
                        <TextRegular margin="0 0 40px 0">Force sync saat ini untuk menyamakan data stok</TextRegular>

                        <Flex className={styles['progress-list__container']}>
                            <IterationComponent
                                arr={data?.progress_stage}
                                render={(item, idx) => {
                                    const progressData = getprogressData(item?.status);
                                    const nextProgressData = getprogressData(data?.progress_stage[idx + 1]?.status);
                                    return (
                                        <Box
                                            className={styles['progress-item__container']}
                                            flex={idx !== 4 ? 1 : 0}
                                            minWidth="100px"
                                        >
                                            <Box>
                                                <Flex alignItems="center" gap="16px" mb="32px">
                                                    {progressData?.icon}
                                                    <ConditionalComponent statement={idx !== 4}>
                                                        <ConditionalComponent
                                                            statement={
                                                                data?.progress_stage[idx + 1]?.status === 'Inprogress'
                                                            }
                                                            fallback={
                                                                <Box
                                                                    className={styles['progress-bar__container']}
                                                                    bg={`${nextProgressData?.progressColor} !important`}
                                                                />
                                                            }
                                                        >
                                                            <Box className={styles['progress-bar__container']}>
                                                                <Box
                                                                    className={styles['progress-bar']}
                                                                    bg="var(--color-primary)"
                                                                />
                                                            </Box>
                                                        </ConditionalComponent>
                                                    </ConditionalComponent>
                                                </Flex>
                                                <TextRegular margin="0 0 2px 0" color="var(--color-neutral50)">
                                                    STEP {idx + 1}
                                                </TextRegular>
                                                <TextBold color={progressData?.nameColor}>{item?.name}</TextBold>
                                            </Box>
                                            <Flex justifySelf="flex-end" gap="4px" alignItems="center" mt="16px">
                                                <Box
                                                    className={styles['circle__status']}
                                                    background={progressData?.dotColor}
                                                />
                                                <TextRegular color={progressData?.statusColor}>
                                                    {item?.status}
                                                </TextRegular>
                                            </Flex>
                                        </Box>
                                    );
                                }}
                            />
                        </Flex>

                        <ConditionalComponent statement={data?.status?.name === 'Failed'}>
                            <Box className={styles['box-info__container']}>
                                <Flex
                                    className={styles['toggle__button']}
                                    onClick={isOpenErrorMessage ? onCloseErrorMessage : onOpenErrorMessage}
                                >
                                    <TextBold>Lihat Detail Kesalahan</TextBold>
                                    <Box transform={`rotate(${isOpenErrorMessage ? '180deg' : '0deg'})`}>
                                        <ChevronDownIcon color="var(--color-primary)" />
                                    </Box>
                                </Flex>
                                <ConditionalComponent statement={isOpenErrorMessage}>
                                    <Flex className={styles['info__container']}>
                                        <Flex gap="8px" flex={1}>
                                            <CloseRedIcon />
                                            <TextRegular>{upperFirst(data?.status?.message)}</TextRegular>
                                        </Flex>
                                        <CopyIcon onClick={copyToClipboard} />
                                    </Flex>
                                </ConditionalComponent>
                            </Box>
                            <Flex justifyContent="center" width="100%" gap="12px">
                                <ButtonOutline
                                    borderRadius="6px !important"
                                    width="116px"
                                    color="var(--color-primary)"
                                    onClick={onClose}
                                    component={<TextBold color="var(--color-primary)">Kembali </TextBold>}
                                />
                                <ButtonRegular
                                    width="116px"
                                    borderRadius="6px !important"
                                    color="var(--color-primary)"
                                    onClick={onRefetchSync}
                                    component={<TextBold color="white">Sync Ulang</TextBold>}
                                />
                            </Flex>
                        </ConditionalComponent>
                    </Box>
                </ConditionalComponent>
            </ModalContent>
        </Modal>
    );
};

export default SyncModal;
