import { isEmpty, lowerCase } from 'lodash';

const isellerUrlConfig = (url) => {
    if (isEmpty(url)) return null;

    //remove empty empty string
    url = url
        ?.split('')
        ?.filter((item) => item !== ' ')
        ?.join('');

    //add "/" if doesnt have

    return url[url.length - 1] === '/' ? url : url + '/';
};

const updateConfigurationInfoRequest = ({ configuration }) => {
    console.log(configuration);
    return {
        template_upload: configuration?.template_upload?.name || 'custom',
        transfer_status: lowerCase(configuration?.transfer_status?.name),
        iseller_url: isellerUrlConfig(configuration?.iseller_url),
        tag: configuration?.tag?.value || null,
        is_sync_create_product: configuration?.is_sync_create_product,
        is_sync_transfer_in: configuration?.is_sync_transfer_in,
        is_sync_bivi_dashboard: Boolean(configuration?.is_sync_bivi_dashboard),
        is_sync_update_product: configuration?.is_sync_update_product,
        customer_id_konsi: configuration?.is_sync_consignment ? configuration?.customer_id_konsi : null,
        is_sync_consignment: configuration?.is_sync_consignment,
        is_update_product_with_price: configuration?.is_update_product_with_price,
        is_trusted_iseller: configuration?.is_trusted_iseller,
        is_active: configuration?.system_type?.name === 'iSeller' ? configuration?.is_active : false,
        is_create_product_with_price: configuration?.is_create_product_with_price,
        is_create_product_with_description: configuration?.is_create_product_with_description,
        is_create_product_continue_selling_when_soldout: configuration?.is_create_product_continue_selling_when_soldout,
        is_create_product_with_attribute: configuration?.is_create_product_with_attribute,
        is_update_product_with_name: configuration?.is_update_product_with_name,
        is_update_product_with_description: configuration?.is_update_product_with_description,
        is_update_product_continue_selling_when_soldout: configuration?.is_update_product_continue_selling_when_soldout,
        is_update_product_with_attribute: configuration?.is_update_product_with_attribute,
        system_type_id: configuration?.system_type?.id,
        exagon_id: configuration?.exagon_id,
        is_replenishment: configuration?.is_replenishment,
        is_replenishment_pending: false,
        is_get_order: configuration?.is_get_order,
        is_outbound: configuration?.is_outbound,
    };
};

export default updateConfigurationInfoRequest;
