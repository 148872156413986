import React from 'react';
import { IconCamera } from '../../assets/icons';
import { Flex, Text } from '@chakra-ui/react';

const TutorialButton = () => (
    <a href="https://s.id/info-sagita" target="_blank" rel="noreferrer">
        <Flex className="tutorial-button__container" data-testid="tutorial-button__container-upload-sellout">
            <IconCamera />
            <Text className="text">Tutorial Upload</Text>
        </Flex>
    </a>
);

export default TutorialButton;
