import React from 'react';
export const ArrowLeftIcon = ({ onClick }) => (
    <svg
        className="arrow-left"
        data-testid="arrow-left-icon__navbar"
        onClick={onClick}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 13.5L4.5 9L9 4.5"
            stroke="#005B99"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.5 13.5L9 9L13.5 4.5"
            stroke="#005B99"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ArrowRightIcon = ({ onClick }) => (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 4.5L9 9L4.5 13.5"
            stroke="var(--main-color)"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 4.5L13.5 9L9 13.5"
            stroke="var(--main-color)"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ChevronDownIcon = ({ color, isActive }) => (
    <svg
        className={`chevron-down__icon ${isActive ? 'active-chevron' : ''}`}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 7.5L10 12.5L5 7.5"
            stroke={`var(${color})`}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const DotIcon = ({ color }) => (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 6C4.65687 6 6 4.65687 6 3C6 1.34313 4.65687 0 3 0C1.34313 0 0 1.34313 0 3C0 4.65687 1.34313 6 3 6Z"
            fill={color}
        />
    </svg>
);
