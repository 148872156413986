import getTemplateUploadResponse from '@domain/model/response/templateUpload/getTemplateUploadResponse';
import getTemplateUpload from '@hooks/templateUpload/getTemplateUpload';

const useGetTemplateUpload = ({ enabled }) => {
    const response = getTemplateUpload({ enabled });
    const mappingResult = getTemplateUploadResponse(response?.data);
    return { ...response, data: mappingResult };
};

export default useGetTemplateUpload;
