import fetchExportSisoTable from '@data/siso/fetchExportSisoTable';
import { useDownloadNotification } from '@store/downloadNotification';
import { saveAs } from 'file-saver';
import { useMutation } from '@tanstack/react-query';

const getExportSisoTable = ({ fileName, queries }) => {
    const { onChangeDownloadNotification } = useDownloadNotification();

    return useMutation({
        mutationFn: () => fetchExportSisoTable(queries),
        throwOnError: (error) => error,
        onMutate: () => {
            onChangeDownloadNotification({
                status: 'download',
                title: 'Proses Download Data',
                description: `Silahkan menunggu, sedang mengunduh ${fileName}`,
            });
        },
        onSuccess: (value) => {
            onChangeDownloadNotification({
                status: 'success',
                title: 'Sukses Download Data',
                description: `${fileName} berhasil diunduh`,
            });
            const blob = new Blob([value], { type: 'text/xlsx' });

            saveAs(blob, `${fileName}`);
        },
        onError: () => {
            onChangeDownloadNotification({
                status: 'error',
                title: 'Gagal Download Data',
                description: `${fileName} gagal diunduh`,
            });
        },
    });
};

export default getExportSisoTable;
