import { Box, Flex } from '@chakra-ui/react';
import Head from './sections/head';
import StoreInfo from './sections/storeInfo';
import Integration from './sections/integration';
import BiviSync from './sections/biviSync';
import ExagonSync from './sections/exagonSync';
import MasterData from './sections/masterData';
import TransferIn from './sections/transferIn';
import Product from './sections/product';
import { useParams } from 'react-router-dom';
import useGetConfigurationInfo from '@domain/configuration/useGetConfigurationInfo';
import { ConditionalComponent } from '@components/functionComponent';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

const StoreConfigurationDetail = () => {
    const { id } = useParams();
    const [configurationFirstLoad, setConfigurationFirstLoad] = useState();

    const { data: configuration, isLoading: isLoadingConfiguration } = useGetConfigurationInfo(id);

    useEffect(() => {
        if (!isEmpty(configuration) && isEmpty(configurationFirstLoad)) {
            setConfigurationFirstLoad(configuration);
        }
    }, [configuration]);
    return (
        <Box className="store-configuration-detail__container">
            <Head
                configuration={configuration}
                isLoadingConfiguration={isLoadingConfiguration}
                configurationFirstLoad={configurationFirstLoad}
            />
            <StoreInfo configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
            <Flex gap="24px">
                <ConditionalComponent
                    statement={configuration?.system_type?.name === 'iSeller' || isLoadingConfiguration}
                >
                    <Integration configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
                </ConditionalComponent>
                <BiviSync configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
            </Flex>
            <ConditionalComponent statement={configuration?.system_type?.name === 'iSeller' || isLoadingConfiguration}>
                <ExagonSync configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
            </ConditionalComponent>
            <ConditionalComponent statement={configuration?.system_type?.name === 'iSeller' || isLoadingConfiguration}>
                <Flex gap="24px">
                    <MasterData configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
                    <TransferIn configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
                </Flex>
                <Product configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
            </ConditionalComponent>
        </Box>
    );
};

export default StoreConfigurationDetail;
