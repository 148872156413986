import React from 'react';
import { Text } from '@chakra-ui/react';
import './styles.scss';

export const TextRegular = ({ className, children, color, textAlign, margin }) => (
    <Text className={`${className} sagittarius-text__reguler`} color={color} textAlign={textAlign} margin={margin}>
        {children}
    </Text>
);
export const TextMedium = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__medium`}>{children}</Text>
);
export const TextSemiBold = ({ className, children, color }) => (
    <Text className={`${className} sagittarius-text__semibold`} color={color}>
        {children}
    </Text>
);
export const TextBold = ({ className, children, color, onClick }) => (
    <Text color={color} className={`${className} sagittarius-text__bold`} onClick={onClick  }>
        {children}
    </Text>
);
export const TextExtraBold = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__extrabold`}>{children}</Text>
);
