import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useDownloadNotification } from '@store/downloadNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { useAuditTrailFilterStore } from '@pages/AuditTrail/helpers/mainState';
import { onDownloadAuditTrail } from '@pages/AuditTrail/helpers/crud';

const ExportButton = () => {
    const { onChangeDownloadNotification, status } = useDownloadNotification();
    const store = useAuditTrailFilterStore();

    return (
        <Box
            className={`list-button__audit-trail ${status === 'download' && 'ondownload-process'}`}
            onClick={() => onDownloadAuditTrail({ onChangeDownloadNotification, store })}
        >
            <Text>Export</Text>
            <ConditionalComponent statement={status === 'download'}>
                <Spinner color="var(--main-color)" size="sm" />
            </ConditionalComponent>
        </Box>
    );
};
export const listHeadMenu = [<ExportButton />];
