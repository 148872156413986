import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Flex } from '@chakra-ui/react';
import { LogoutIcon, ProfileIcon } from './icons';

import { useSnackbarNotification } from '@store/snackbarNotification';
import { onLogoutHandler } from './helpers/events';
import { useLoader } from '@store/loader';

const GoToProfileComponent = () => {
    const navigate = useNavigate();
    return (
        <Flex className="floating-button__wrapper" onClick={() => navigate('/profile')} data-testid="profile">
            <ProfileIcon />
            <Text>Profile</Text>
        </Flex>
    );
};

const LogoutComponent = () => {
    const navigate = useNavigate();
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const { onChangeLoader } = useLoader();

    return (
        <Flex
            className="floating-button__wrapper"
            data-testid="logout"
            onClick={() => onLogoutHandler({ navigate, onChangeLoader, onChangeSnackbarNotification })}
        >
            <LogoutIcon />
            <Text>Logout</Text>
        </Flex>
    );
};

export const clickProfileList = [<GoToProfileComponent />, <LogoutComponent />];
