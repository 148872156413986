import { isEmpty } from 'lodash';

const getDCResponse = (data) => {
    if (isEmpty(data)) return [];

    const { listDc } = data;

    const mappingData = listDc?.map((item) => {
        return {
            ...item,
            name: item?.dc_name,
        };
    });

    return mappingData;
};

export default getDCResponse;
