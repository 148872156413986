import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import CheckboxComponent from '@pages/storeConfigurationDetail/components/checkbox';
import SyncComponent from '@pages/storeConfigurationDetail/components/sync';
import TextComponent from '@pages/storeConfigurationDetail/components/text';
import useGetSyncToExagonInventory from '@domain/configuration/useGetSyncToExagonInventory';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { checkBackgroundProgress } from '@pages/storeConfigurationDetail/helpers/utils';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';
import { getStatusDotBackground } from './const';
import SyncModal from '@pages/storeConfigurationDetail/components/syncModal';
import usePostSyncToExagonInventory from '@domain/configuration/usePostSyncToExagonInventory';
import usePostSyncToExagonOutbound from '@domain/configuration/usePostSyncToExagonOutbound';
import useGetSyncToExagonOutbound from '@domain/configuration/useGetSyncToExagonOutbound';

const ExagonSync = ({ isLoadingConfiguration, configuration }) => {
    const { id } = useParams();
    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);
    const postSyncToExagonInventory = usePostSyncToExagonInventory(id);
    const postSyncToExagonOutbound = usePostSyncToExagonOutbound(id);
    const { data: syncDataInventory, refetch: refetchSyncToExagonInventory } = useGetSyncToExagonInventory(id);
    const { data: syncDataOutbound, refetch: refetchSyncToExagonOutbound } = useGetSyncToExagonOutbound(id);

    const inventoryDisclosure = useDisclosure();
    const outboundDisclosure = useDisclosure();

    const statusDotBackgroundInventory = getStatusDotBackground(syncDataInventory?.status?.name);
    const statusDotBackgroundOutbound = getStatusDotBackground(syncDataOutbound?.status?.name);

    //inventory
    useEffect(() => {
        let intervalRef = null;
        const isBackgroundProgress = checkBackgroundProgress(syncDataInventory?.progress_stage);

        if (isBackgroundProgress) intervalRef = setInterval(() => refetchSyncToExagonInventory(), 2000);
        else clearInterval(intervalRef);

        return () => clearInterval(intervalRef);
    }, [syncDataInventory?.progress_stage]);

    //outbound

    useEffect(() => {
        let intervalRef = null;
        const isBackgroundProgress = checkBackgroundProgress(syncDataOutbound?.progress_stage);

        if (isBackgroundProgress) intervalRef = setInterval(() => refetchSyncToExagonOutbound(), 2000);
        else clearInterval(intervalRef);

        return () => clearInterval(intervalRef);
    }, [syncDataOutbound?.progress_stage]);

    return (
        <Box className={styles['exagon-sync__container']}>
            <TextBold className={styles['title']}>Exagon Sync</TextBold>
            <Flex gap="24px" width="100%" justifyContent="space-between">
                <Box flex={1}>
                    <TextBold className={styles['section-title__text']}>Inventory</TextBold>
                    <CheckboxComponent
                        isLoading={isLoadingConfiguration}
                        value={configuration?.is_replenishment}
                        label="Status"
                        description="Aktivasi sinkronisasi data ke exagon"
                        id="is_replenishment"
                        onChange={(val) =>
                            updateConfigurationInfoState?.mutate({
                                is_replenishment: val?.value,
                                exagon_id: id,
                            })
                        }
                    />
                    <SyncComponent
                        onOpen={() => {
                            inventoryDisclosure?.onOpen();
                            postSyncToExagonInventory.mutate();
                        }}
                        isLoading={isLoadingConfiguration}
                        label="Sync Exagon Inventory"
                        description="Force sync saat ini untuk menyamakan data stok"
                    />
                    <TextComponent
                        label="Terakhir Dijalankan"
                        value={syncDataInventory?.status?.updated_at ?? '-'}
                        isLoading={isLoadingConfiguration}
                        skeletonWidth="120px"
                    />
                    <TextComponent
                        label="Status"
                        value={syncDataInventory?.status?.name ?? '-'}
                        isLoading={isLoadingConfiguration}
                        skeletonWidth="80px"
                        withDot={true}
                        backgroundDot={statusDotBackgroundInventory}
                        statusErrorMessage={syncDataInventory?.status?.message}
                    />
                    <TextComponent
                        label="ID"
                        value={syncDataInventory?.task_id ?? '-'}
                        isLoading={isLoadingConfiguration}
                        skeletonWidth="120px"
                    />
                </Box>

                <Box flex={1}>
                    <TextBold className={styles['section-title__text']}>Outbound</TextBold>
                    <CheckboxComponent
                        isLoading={isLoadingConfiguration}
                        label="Status"
                        id="is_outbound"
                        description="Aktivasi sinkronisasi data ke exagon"
                        value={configuration?.is_outbound}
                        onChange={(val) =>
                            updateConfigurationInfoState?.mutate({
                                is_outbound: val?.value,
                                exagon_id: id,
                            })
                        }
                    />
                    <SyncComponent
                        isLoading={isLoadingConfiguration}
                        label="Sync Exagon Inventory"
                        description="Sinkronisasi jumlah Sell-Out ke outbound Exagon"
                        onOpen={() => {
                            outboundDisclosure?.onOpen();
                            postSyncToExagonOutbound.mutate();
                        }}
                    />
                    <TextComponent
                        isLoading={isLoadingConfiguration}
                        label="Terakhir Dijalankan"
                        value={syncDataOutbound?.updated_at ?? '-'}
                        skeletonWidth="120px"
                    />
                    <TextComponent
                        isLoading={isLoadingConfiguration}
                        label="Status"
                        value={syncDataOutbound?.status?.name ?? '-'}
                        skeletonWidth="80px"
                        withDot={true}
                        backgroundDot={statusDotBackgroundOutbound}
                        statusErrorMessage={syncDataOutbound?.status?.message}
                    />
                    <TextComponent
                        isLoading={isLoadingConfiguration}
                        label="ID"
                        value={syncDataOutbound?.task_id ?? '-'}
                        skeletonWidth="120px"
                    />
                </Box>
            </Flex>

            {/* MODAL */}

            <SyncModal
                isOpen={inventoryDisclosure?.isOpen}
                data={syncDataInventory}
                isLoading={postSyncToExagonInventory?.isPending}
                onClose={inventoryDisclosure?.onClose}
                onRefetchSync={postSyncToExagonInventory.mutate}
            />

            <SyncModal
                isOpen={outboundDisclosure?.isOpen}
                data={syncDataOutbound}
                isLoading={postSyncToExagonOutbound.isPending}
                onClose={outboundDisclosure?.onClose}
                onRefetchSync={postSyncToExagonOutbound.mutate}
            />
        </Box>
    );
};

export default ExagonSync;
