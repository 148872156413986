import React from 'react';
import {
    ErrorState401Image,
    ErrorState404Image,
    ErrorState500Image,
    ErrorStateImage,
    ErrorStateNetworkImage,
} from '@assets/images';

export const useGetError = (error) => {
    const statusCode = error?.error?.response?.status;
    const errorName = error?.error?.name;

    let errorState = {};

    if (errorName === 'AxiosError') {
        switch (statusCode) {
            case 400:
                errorState = {
                    type: 'invalid',
                    image: <ErrorState500Image />,
                    title: 'Maaf, telah terjadi kesalahan di Sagitarius ...',
                    description:
                        'Kami menyadari masalah ini dan sedang dalam proses perbaikan. Silahkan tunggu beberapa saat dan coba muat ulang halaman ini.',
                };
                break;
            case 401:
                errorState = {
                    type: 'forbidden',
                    image: <ErrorState401Image />,
                    title: 'Akses dibutuhkan',
                    description:
                        'Halaman yang Kamu coba akses dibatasi. Saat ini, kamu tidak memiliki izin untuk mengakses halaman ini. Silahkan hubungi tim kami jika Kamu memerlukan izin akses.',
                };
                break;

            case 404:
                errorState = {
                    type: 'not-found',
                    image: <ErrorState404Image />,
                    title: 'Maaf, halaman yang Kamu cari tidak ditemukan ...',
                    description: 'Kamu mungkin salah mengetik tautan atau halaman tersebut mungkin telah dipindahkan.',
                };
                break;
            case 500:
                errorState = {
                    type: 'server',
                    image: <ErrorState500Image />,
                    title: 'Maaf, telah terjadi kesalahan di Sagitarius ...',
                    description:
                        'Kami menyadari masalah ini dan sedang dalam proses perbaikan. Silahkan tunggu beberapa saat dan coba muat ulang halaman ini.',
                };
                break;
            default:
                errorState = {
                    image: <ErrorStateNetworkImage />,
                    title: 'Whoa! Koneksi hilang.',
                    description:
                        'Sepertinya koneksi internet Kamu terputus atau dimatikan. Silahkan periksa kembali koneksi internet Kamu dan muat ulang halaman ini',
                };
                break;
        }
    } else {
        errorState = {
            type: 'javascript',
            image: <ErrorStateImage />,
            title: 'Maaf, Terjadi Kesalahan di Sagitarius ...',
            description:
                'Saat ini, kami tidak dapat memastikan masalah yang terjadi. Coba muat ulang halaman, atau logout untuk kembali ke halaman login.',
        };
    }

    return errorState;
};
