import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import './styles.css';
import { useState } from 'react';

const FilterYear = ({ onChange }) => {
    const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));

    const onChangeYear = (type) => {
        let year = 0;

        switch (type) {
            case 'prev':
                year = Number(currentYear) - 1;
                setCurrentYear(year);
                onChange(year);
                break;
            case 'next':
                year = Number(currentYear) + 1;
                setCurrentYear(year);
                onChange(year);
                break;
        }
    };

    return (
        <Flex className="filter-year__container">
            <TextBold className="filter-year__label no-highlight__text">Tahun:</TextBold>
            <Flex className="filter-year__switch">
                <ChevronLeftIcon
                    fontSize="18px"
                    color="var(--semantic-text)"
                    cursor="pointer"
                    onClick={() => onChangeYear('prev')}
                />
                <TextRegular className="filter-year__text  no-highlight__text">{currentYear}</TextRegular>
                <ChevronRightIcon
                    fontSize="18px"
                    color="var(--semantic-text)"
                    cursor="pointer"
                    onClick={() => onChangeYear('next')}
                />
            </Flex>
        </Flex>
    );
};

export default FilterYear;
