import { Skeleton, SkeletonCircle } from '@chakra-ui/react';

const SkeletonComponent = ({ mr, ml, mb, mt, height, width, maxWidth, float, type }) => {
    if (type === 'circle') {
        return (
            <SkeletonCircle
                mr={mr}
                ml={ml}
                mb={mb}
                mt={mt}
                height={height}
                width={width}
                float={float}
                maxWidth={maxWidth}
                startColor="var(--skeleton-color)"
                endColor="var(--skeleton-active-color)"
            />
        );
    }
    return (
        <Skeleton
            mr={mr}
            ml={ml}
            mb={mb}
            mt={mt}
            height={height}
            width={width}
            float={float}
            maxWidth={maxWidth}
            startColor="var(--skeleton-color)"
            borderRadius="2px"
            endColor="var(--skeleton-active-color)"
        />
    );
};

export default SkeletonComponent;
