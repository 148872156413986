import fethcGroupStores from '@data/groupStore/fetchGroupStores';
import { useQuery } from '@tanstack/react-query';

const getGroupStores = ({ enabled, queries }) => {
    return useQuery({
        queryKey: ['group-stores', queries],
        queryFn: async () => await fethcGroupStores({ queries }),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getGroupStores;
