import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Countdown from 'react-countdown';

import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { useSnackbarNotification } from '@store/snackbarNotification';

import { CloseIcon } from './icons';
import { Icon, getColor } from './const';

import './styles.scss';
import { isArray } from 'lodash';
import { TextBold, TextRegular } from '@components/typography';

const SnackbarNotification = () => {
    const { status, title, description, duration, onResetSnackbarNotification, detailMessage, alwaysShow } =
        useSnackbarNotification();

    const { borderColor, backgroundColor, icon } = getColor({ status });
    useEffect(() => {
        setTimeout(() => {
            if (status && !alwaysShow) {
                onResetSnackbarNotification();
            }
        }, [duration * 1000]);
        // eslint-disable-next-line
    }, [status, detailMessage, title, description]);

    return (
        <ConditionalComponent statement={!!status}>
            <Box className="snackbar-notification__container">
                <Flex className="box" bg={backgroundColor} borderLeft={`4px solid ${borderColor}`}>
                    {icon}
                    <Box className="info-container">
                        <TextBold className="title">{title}</TextBold>
                        <TextRegular className="description">{description}</TextRegular>

                        <ConditionalComponent statement={isArray(detailMessage) && detailMessage?.length}>
                            <Box className="detail-message__container">
                                <IterationComponent
                                    arr={detailMessage}
                                    render={(item) => <Text className="detail-message">{item}</Text>}
                                />
                            </Box>
                        </ConditionalComponent>
                    </Box>
                    <CloseIcon color={'var(--semantic-text)'} onClick={onResetSnackbarNotification} />
                </Flex>
            </Box>
        </ConditionalComponent>
    );
};

export default SnackbarNotification;
