import queryString from 'qs';

const getStoreGroupRequest = (data) => {
    const payload = {
        searchparam: `group.${data?.search}`,
    };

    return queryString.stringify(payload, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getStoreGroupRequest;
