import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import './styles.css';
import { ConditionalComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';

const CheckboxComponent = ({ label, description, value, onChange, id, isLoading }) => {
    return (
        <Flex className="store-config-detail__checkbox__container">
            <ConditionalComponent statement={!isLoading} fallback={<SkeletonComponent width="20px" height="20px" />}>
                <Checkbox isChecked={value} onChange={(e) => onChange({ value: e.target.checked, id })} />
            </ConditionalComponent>
            <Box>
                <TextBold className="text-label">{label}</TextBold>
                <TextRegular className="text-description">{description}</TextRegular>
            </Box>
        </Flex>
    );
};

export default CheckboxComponent;
