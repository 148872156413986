import postSyncToExagonInventory from '@data/configuration/postSyncToExagonInventory';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const sendSyncToExagonInventory = (id) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => postSyncToExagonInventory({ id }),
        onSuccess: async () => {
            onChangeSnackbarNotification({
                status: 'success',
                title: 'Sedang melakukan sync data.',
                description: '',
                duration: 3,
            });

            await queryClient.invalidateQueries({ queryKey: ['sync-to-exagon-inventory', id] });
        },
        onError: (error) => {
            const errorMessage = error?.response?.data?.message ?? 'Terdapat sebuah kesalahan sistem';
            onChangeSnackbarNotification({
                status: 'error',
                title: 'Gagal melakukan sync.',
                description: errorMessage,
                duration: 3,
            });
        },
    });
};

export default sendSyncToExagonInventory;
