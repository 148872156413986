import queryString from 'qs';

const getSisoTrendsRequest = (filters) => {
    const payload = {
        year: filters?.selectedYear,
        nama_dc: filters?.dcName && `[${filters?.dcName}]`,
        customer: filters?.customer && `[${filters?.customer}]`,
        sales_area: filters?.salesArea && `[${filters?.salesArea}]`,
        brand: filters?.brands && `[${filters?.brands}]`,
        store_group: filters?.storeGroup && `[${filters?.storeGroup}]`,
    };

    return queryString.stringify(payload, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getSisoTrendsRequest;
