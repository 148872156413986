import authAxios from '@services/authAxios';

const patchConfigurationInfo = async ({ id, payload }) => {
    const { data } = await authAxios({
        method: 'PUT',
        url: `/api/v1/configuration/${id}`,
        data: payload,
    });

    return data?.data;
};

export default patchConfigurationInfo;
