import { SuccessGreen24Icon, FailedRed32Icon, TodoIconCircle } from '@assets/icons/index';
import styles from './styles.module.css';
import { Box } from '@chakra-ui/react';
import SkeletonComponent from '@components/skeleton';

export const getprogressData = (status) => {
    switch (status) {
        case 'Todo':
            return {
                nameColor: 'var(--color-neutral50)',
                dotColor: 'var(--color-neutral50)',
                statusColor: 'var(--color-neutral50)',
                progressColor: 'var(--color-neutral10)',
                icon: <TodoIconCircle fallback={<SkeletonComponent type="circle" width="32px" height="32px" />} />,
            };
        case 'Failed':
            return {
                nameColor: 'var(--semantic-text)',
                dotColor: 'var(--color-danger50)',
                statusColor: 'var(--semantic-text)',
                progressColor: 'var(--color-danger50)',
                icon: <FailedRed32Icon fallback={<SkeletonComponent type="circle" width="32px" height="32px" />} />,
            };
        case 'Inprogress':
            return {
                nameColor: 'var(--semantic-text)',
                dotColor: 'var(--color-primary)',
                statusColor: 'var(--semantic-text)',
                icon: (
                    <Box className={styles['process-container']}>
                        <Box className={styles['blue-progress--circle1']} />
                        <Box className={styles['blue-progress--circle2']} />
                        <Box className={styles['blue-progress--circle3']} />
                    </Box>
                ),
            };
        case 'Success':
            return {
                nameColor: 'var(--color-success60)',
                dotColor: 'var(--color-success50)',
                statusColor: 'var(--semantic-text)',
                progressColor: 'var(--color-success60)',
                icon: (
                    <SuccessGreen24Icon
                        width={'30px'}
                        height={'30px'}
                        fallback={<SkeletonComponent type="circle" width="32px" height="32px" />}
                    />
                ),
            };

        default:
            return {
                nameColor: 'var(--color-neutral50)',
                dotColor: 'var(--color-neutral50)',
                statusColor: 'var(--color-neutral50)',
                progressColor: 'var(--color-neutral10)',
                icon: <TodoIconCircle fallback={<SkeletonComponent type="circle" width="32px" height="32px" />} />,
            };
    }
};
