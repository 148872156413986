import { MtdNegativeIcon, MtdNeutralIcon, MtdPositiveIcon } from '@assets/icons/index';
import { isEmpty } from 'lodash';

export const getMtdAttribute = (status) => {
    switch (status) {
        case 'Positive':
            return { icon: <MtdPositiveIcon />, symbol: '+', color: 'var(--color-success60)' };

        case 'Negative':
            return { icon: <MtdNegativeIcon />, symbol: '-', color: 'var(--color-danger50)' };

        default:
            return { icon: <MtdNeutralIcon />, symbol: '', color: 'var(--color-neutral60)' };
    }
};

export const checkFilterInformation = (data) => {
    const { dcName, customer, salesArea, brands, storeGroup } = data;

    return !isEmpty(dcName) || !isEmpty(customer) || !isEmpty(salesArea) || !isEmpty(brands) || !isEmpty(storeGroup);
};
