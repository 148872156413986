import { Modal, ModalOverlay, ModalContent, ModalHeader, Button, Box, Text, VStack, Flex } from '@chakra-ui/react';
import React from 'react';
import copy from 'copy-to-clipboard';

import { getDetailLineErrorMessage } from './helpers';

import { ImageSuccess, ImageErrorDetail, ImageError } from './images';
import './styles.scss';
import { Fragment } from 'react';
import { usePopUpNotification } from '@store/popupNotification';
import { IconCopy } from '@pages/UploadSellout/assets/icons';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { isArray } from 'lodash';
import { useSpotLight } from '@store/spotlight';

export const SuccessUploadPopUp = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="modal-content__popup-success">
                <VStack className="modal-stack">
                    <ModalHeader>File berhasil di upload</ModalHeader>
                    <ImageSuccess />
                    <Text className="modal-text">File sell-out anda berhasil di upload</Text>
                    <Button
                        onClick={onClose}
                        color="var(--main-color)"
                        variant="outline"
                        border="1px solid var(--main-color)"
                        mt="16px"
                    >
                        Tutup
                    </Button>
                </VStack>
            </ModalContent>
        </Modal>
    );
};

export const FailedUploadPopUp = ({ isOpen, onClose, errorMessages, additionalInfo }) => {
    const lineError = getDetailLineErrorMessage(errorMessages);
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const { onSpotlight } = useSpotLight();

    const onClickSeeError = () => {
        onClose();
        onSpotlight({ selectedElement: 'error__upload-sellout' });
    };

    const copyToClipboard = () => {
        copy(
            `nama toko: ${additionalInfo?.storeName},\r\nemail: ${additionalInfo?.email},\r\ntype upload: ${
                additionalInfo?.typeUpload
            },\r\ndate upload: ${additionalInfo?.dateUpload},\r\nerror status: ${
                additionalInfo?.errorStatus
            },\r\nerror message: ${
                isArray(errorMessages)
                    ? `${errorMessages[0]?.line?.substring(0, 50)} - ${errorMessages[0]?.description}`
                    : errorMessages
            }`
        );
        onChangeSnackbarNotification({
            status: 'success',
            title: 'Berhasil',
            description: `Text berhasil di copy ke clipboard`,
            duration: 3,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <Box className="modal-content__poup-failed-detail">
                    <ImageErrorDetail />
                    <Text className="title">
                        <span>{additionalInfo?.errorStatus}</span> - File gagal upload
                    </Text>

                    <ConditionalComponent
                        statement={isArray(errorMessages)}
                        fallback={
                            <Box className="error-message__container">
                                <Text className="error-message">{errorMessages}</Text>
                            </Box>
                        }
                    >
                        <Text className="error-message__multiple">
                            <span className="error-text">{`Terdapat file error pada baris ${lineError}`}</span>
                            <span className="read-more" onClick={onClickSeeError}>
                                Baca Selengkapnya
                            </span>
                        </Text>
                    </ConditionalComponent>

                    <Text className="error-desc">
                        File yang anda upload memiliki data yang error. Periksa kembali file yang anda submit
                    </Text>

                    <Box className="action-info__container">
                        <Text className="information-text">Informasi Customer</Text>
                        <Flex mb="28px">
                            <Box mr="36px">
                                <Flex mb="4px">
                                    <Text className="information-label">Nama Toko</Text>
                                    <Text className="information-value">{additionalInfo?.storeName} </Text>
                                </Flex>
                                <Flex>
                                    <Text className="information-label">Email </Text>
                                    <Text className="information-value">{additionalInfo?.email} </Text>
                                </Flex>
                            </Box>

                            <Box>
                                <Flex mb="4px">
                                    <Text className="information-label">Date Upload </Text>
                                    <Text className="information-value">{additionalInfo?.dateUpload} </Text>
                                </Flex>
                                <Flex>
                                    <Text className="information-label">Type Upload </Text>
                                    <Text className="information-value">{additionalInfo?.typeUpload} </Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Button className="icon-copy" onClick={copyToClipboard}>
                        <IconCopy />
                        Salin
                    </Button>
                    <Button className="icon-close" onClick={onClose}>
                        Tutup
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
};

const PopUpNotification = () => {
    const { onResetPopUpNotification, status, errorMessages, additionalInfo } = usePopUpNotification();
    return (
        <Fragment>
            <SuccessUploadPopUp isOpen={status === 'success'} onClose={onResetPopUpNotification} />
            <FailedUploadPopUp
                isOpen={status === 'failed'}
                errorMessages={errorMessages}
                onClose={onResetPopUpNotification}
                additionalInfo={additionalInfo}
            />
        </Fragment>
    );
};

export default PopUpNotification;
