import queryString from 'qs';

const getSalesAreasRequest = (data) => {
    const payload = {
        searchparam: `name.${data?.search}`,
    };

    return queryString.stringify(payload, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getSalesAreasRequest;
