import fetchSystemType from '@data/systemType/fetchSystemType';
import { useQuery } from '@tanstack/react-query';

const getSystemType = ({ enabled }) => {
    return useQuery({
        queryKey: ['system-type'],
        queryFn: async () => await fetchSystemType(),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getSystemType;
