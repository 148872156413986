import { Box, Flex, Table, Td, Tr } from '@chakra-ui/react';
import styles from './styles.module.css';
import SkeletonComponent from '@components/skeleton';
import { IterationComponent } from '@components/functionComponent';

const TrendsSkeleton = () => {
    return (
        <Box className={styles['trends-skeleton__container']}>
            <Flex justifyContent="center" gap="24px" mb="24px">
                <SkeletonComponent width="63px" height="20px" />
                <SkeletonComponent width="63px" height="20px" />
            </Flex>
            <Flex alignItems="center" mb="8px">
                <SkeletonComponent width="17px" height="110px" mr="8px" />
                <Flex flexDirection="column" justifyContent="space-between" height="200px">
                    <SkeletonComponent width="28px" height="15px" />
                    <SkeletonComponent width="28px" height="15px" />
                    <SkeletonComponent width="28px" height="15px" />
                    <SkeletonComponent width="28px" height="15px" />
                    <SkeletonComponent width="28px" height="15px" />
                    <SkeletonComponent width="28px" height="15px" />
                    <SkeletonComponent width="28px" height="15px" />
                </Flex>
                <Box ml="18px" flex={1} padding="6px 0">
                    <IterationComponent
                        arr={[1, 2, 3, 4, 5, 6]}
                        render={() => (
                            <Table>
                                <Tr>
                                    <IterationComponent
                                        arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                                        render={() => <Td border="1px solid var(--color-neutral20)"></Td>}
                                    />
                                </Tr>
                            </Table>
                        )}
                    />
                </Box>
            </Flex>
            <Flex pl="72px" width="100%">
                <Flex padding="0 24px" justifyContent="space-between" flex={1}>
                    <IterationComponent
                        arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                        render={() => <SkeletonComponent width="50px" height="15px" />}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};

export default TrendsSkeleton;
