import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import './styles.css';
import { useState } from 'react';
import { find } from 'lodash';
import { months } from './const';

const FilterMonth = ({ onChange }) => {
    const [currentMonth, setCurrentMonth] = useState({
        id: Number(moment().format('M')),
        label: moment().format('MMM'),
        year: moment().format('YYYY'),
    });

    const onChangeMonth = (type) => {
        let selectedMonthId = 0;
        let selectedYear = Number(currentMonth?.year);

        if (type === 'prev') selectedMonthId = currentMonth?.id - 1;
        else selectedMonthId = currentMonth?.id + 1;

        if (selectedMonthId < 1) {
            selectedMonthId = 12;
            selectedYear = selectedYear - 1;
        }

        if (selectedMonthId > 12) {
            selectedMonthId = 1;
            selectedYear = selectedYear + 1;
        }

        const getMonth = find(months, { id: selectedMonthId });

        getMonth.year = selectedYear;

        onChange({ month: getMonth?.id, year: getMonth?.year });

        setCurrentMonth(getMonth);
    };

    return (
        <Flex className="filter-month__container">
            <TextBold className="filter-month__label no-highlight__text">Bulan:</TextBold>
            <Flex className="filter-month__switch">
                <ChevronLeftIcon
                    fontSize="18px"
                    color="var(--semantic-text)"
                    cursor="pointer"
                    onClick={() => onChangeMonth('prev')}
                />
                <TextRegular className="filter-month__text  no-highlight__text">
                    {currentMonth?.label} {currentMonth?.year}
                </TextRegular>

                <ChevronRightIcon
                    fontSize="18px"
                    color="var(--semantic-text)"
                    cursor="pointer"
                    onClick={() => onChangeMonth('next')}
                />
            </Flex>
        </Flex>
    );
};

export default FilterMonth;
