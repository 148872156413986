import queryString from 'qs';

const getStoresRequest = (data) => {
    const payload = {
        searchparam: data?.search ? `name.${data?.search}` : '',
        dc: `[]`,
    };

    return queryString.stringify(payload, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getStoresRequest;
