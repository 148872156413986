import { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { isEmpty } from 'lodash';

import { ConditionalComponent } from '@components/functionComponent';
import { CalendarIcon } from '@assets/icons/index';
import { ResetIcon } from './icons';

import 'react-datepicker/src/stylesheets/datepicker.scss';

import './styles.scss';

const DatePickerComponent = ({ timeSelected, timeTypeSelected, onChange, isActive, blockedUpload, isBlockUpload }) => {
    useEffect(() => {
        onChange({ value: '' });
        // eslint-disable-next-line
    }, [timeTypeSelected]);

    return (
        <Box className="time-select__wrapper">
            <Box className={`time-select__container ${isActive && 'time-select__actived'}`}>
                {/* //========================== BUTTON FOR SELECT DATE =================== */}

                <CalendarIcon />
                {/* //========================== DATE PICKER =================== */}

                <ConditionalComponent statement={timeTypeSelected === 'monthly'}>
                    <Text className="time-selected__text">
                        {timeSelected ? getMonth({ timeSelected }) : 'Pilih bulan'}
                    </Text>
                    <MonthPicker timeSelected={timeSelected} onChange={onChange} />
                </ConditionalComponent>

                <ConditionalComponent statement={timeTypeSelected === 'daily'}>
                    <Text className="time-selected__text">
                        {timeSelected
                            ? getAllDatesInRange({ firstDate: timeSelected[0], lastDate: timeSelected[1] })
                            : 'Pilih hari'}
                    </Text>
                    <DateRangePicker timeSelected={timeSelected} onChange={onChange} />
                </ConditionalComponent>

                <ConditionalComponent statement={timeTypeSelected === 'multipledays'}>
                    <Text className="time-selected__text">
                        {timeSelected
                            ? getDateRange({ firstDate: timeSelected[0], lastDate: timeSelected[1] })
                            : 'Pilih gabungan hari'}
                    </Text>
                    <DateRangePicker timeSelected={timeSelected} onChange={onChange} />
                </ConditionalComponent>

                <ConditionalComponent statement={timeTypeSelected === 'weekly'}>
                    <Text className="time-selected__text">
                        {timeSelected
                            ? getDateRange({ firstDate: timeSelected[0], lastDate: timeSelected[1] })
                            : 'Pilih minggu'}
                    </Text>
                    <WeekPicker timeSelected={timeSelected} onChange={onChange} />
                </ConditionalComponent>
                <ConditionalComponent statement={!isEmpty(timeSelected?.toString())}>
                    <ResetIcon onClick={() => onChange({ value: '' })} />
                </ConditionalComponent>
            </Box>

            <ConditionalComponent statement={blockedUpload?.is_block && isBlockUpload}>
                <Text className="block-sellout__text">
                    Bulan sell-out yang dipilih termasuk dalam periode {blockedUpload?.block_text} yang telah ditutup
                    untuk penghitungan pencapaian.
                    <span>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://paracorpgroup-my.sharepoint.com/:w:/p/muhammad_adobson/EbgzPxNJrHlAhqODaRRBSQgBxmIL3jA9lQIYJmssFEjevg?e=8V6X7s"
                        >
                            Pelajari Selengkapnya Disini
                        </a>
                    </span>
                </Text>
            </ConditionalComponent>
        </Box>
    );
};

export default DatePickerComponent;

//======================================= DATEPICKER COMPONENT ===================

const MonthPicker = ({ timeSelected, onChange }) => (
    <DatePicker
        selected={timeSelected}
        onChange={(value) => onChange({ value })}
        placeholderText="Pilih bulan"
        wrapperClassName="filter-month"
        showMonthYearPicker
    />
);

const WeekPicker = ({ timeSelected, onChange }) => (
    <Box className="week-picker">
        <DatePicker
            onChange={(value) => onChange({ value: dateToWeek(value) })}
            placeholderText="Pilih week"
            wrapperClassName="filter-month"
            showWeekNumbers
            weekLabel
            onWeekSelect
        />
    </Box>
);

const DateRangePicker = ({ timeSelected, onChange }) => (
    <DatePicker
        onChange={(value) => onChange({ value })}
        wrapperClassName="filter-month"
        placeholderText="Pilih Hari"
        startDate={timeSelected[0]}
        endDate={timeSelected[1]}
        maxDate={new Date(moment(timeSelected[0]).endOf('month'))}
        minDate={new Date(moment(new Date(timeSelected[0])).startOf('month'))}
        selectsRange
    />
);

const getAllDatesInRange = ({ firstDate, lastDate }) => {
    if (firstDate && lastDate) {
        if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'))
            return moment(firstDate).format('DD MMMM YYYY');
        let date = firstDate;
        const dates = [date];
        do {
            date = moment(date).add(1, 'day');
            dates.push(date.format('YYYY-MM-DD'));
        } while (moment(date).isBefore(lastDate));

        let result = '';
        dates?.forEach((item, idx) => {
            result += `${moment(item).format('DD')} ${idx !== dates?.length - 1 ? ', ' : ''}`;
        });

        return `${result} ${moment(dates[0]).format('MMMM YYYY')}`;
    }

    if (firstDate) return moment(firstDate).format('DD MMMM YYYY');

    return '';
};

const getDateRange = ({ firstDate, lastDate }) => {
    if (firstDate && lastDate) {
        return `${moment(firstDate)?.format('DD MMMM YYYY')} - ${moment(lastDate)?.format('DD MMMM YYYY')}`;
    }

    if (firstDate) return moment(firstDate).format('DD MMMM YYYY');

    return '';
};

const getMonth = ({ timeSelected }) => {
    if (timeSelected) {
        return moment(timeSelected)?.format('MMMM YYYY');
    }

    return '';
};

const dateToWeek = (value) => {
    const firstDate = new Date(moment(value).startOf('week'));
    const lastDate = new Date(moment(value).endOf('week'));
    return [firstDate, lastDate];
};
