import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, SkeletonCircle } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';

const SyncSkeleton = ({ onClose }) => (
    <Box width="967px" padding="24px 40px">
        <Flex alignItems="center" justifyContent="space-between" mb="8px">
            <SkeletonComponent width="240px" height="36px" />
            <CloseIcon cursor="pointer" color="var(--semantic-text)" fontSize="14px" onClick={onClose} />
        </Flex>
        <SkeletonComponent width="320px" height="20px" mb="40px" />
        <Flex gap="24px">
            <IterationComponent
                arr={[1, 2, 3, 4, 5]}
                render={(item) => (
                    <Box flex={1}>
                        <Flex gap="16px" mb="32px" alignItems="center">
                            <SkeletonComponent type="circle" width="32px" height="32px" />
                            <ConditionalComponent statement={item !== 5}>
                                <Box flex={1}>
                                    <SkeletonComponent width="100%" height="6px" />
                                </Box>
                            </ConditionalComponent>
                        </Flex>
                        <SkeletonComponent width="40px" height="16px" mb="2px" />
                        <SkeletonComponent width={item === 5 ? '96ox' : '172px'} height="20px" mb="2px" />
                        <SkeletonComponent width={item === 5 ? '60px' : '100px'} height="20px" mb="16px" />
                        <Flex gap="4px">
                            <SkeletonComponent width="16px" height="16px" />
                            <SkeletonComponent width="60px" height="16px" />
                        </Flex>
                    </Box>
                )}
            />
        </Flex>
    </Box>
);

export default SyncSkeleton;
