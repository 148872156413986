import authAxios from '@services/authAxios';

const postSyncToExagonInventory = async ({ id }) => {
    await authAxios({
        method: 'POST',
        url: `/api/v1/replenishment/replenishment-pipeline/${id}`,
    });
};

export default postSyncToExagonInventory;
