import { Box, Spinner } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextRegular } from '@components/typography';

const FilterLoader = () => {
    return (
        <Box className={styles['filter-loader']}>
            <Spinner size="xl" color="var(--color-info40)" mb="20px" />
            <TextRegular className={styles['loader__text']}>Loading</TextRegular>
            <TextRegular className={styles['description__text']}>
                Sedang menyiapkan filter untuk pengalaman terbaik ...
            </TextRegular>
        </Box>
    );
};

export default FilterLoader;
