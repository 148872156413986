import { useSnackbarNotification } from '@store/snackbarNotification';
import React from 'react';
import { Input } from '@chakra-ui/react';

import { onCheckInputFile } from './events';

import './styles.scss';

const FileInput = ({ onChange, maxSize, accessAllowed }) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const onChangeFile = (event) => {
        const file = event.target.files[0];

        const checkInputFileStatus = onCheckInputFile({
            file,
            maxSize,
            accessAllowed,
            onChangeSnackbarNotification,
        });

        onChange({ checkedStatus: checkInputFileStatus, selectedFile: file });
    };
    return (
        <Input
            className="file-input__global-container"
            type="file"
            onChange={onChangeFile}
            data-testid="file-input__global"
        />
    );
};

export default FileInput;
