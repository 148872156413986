import { TextRegular } from '@components/typography';
import { formatCurrency } from '@helpers/utils';
import { BadgeComponent, DeviderComponent } from '@pages/BusinessSiso/components/columns';

export const tableList = [
    {
        name: 'Brand',
        width: 125,
        value: 'brand_name',
        withSort: true,
        skeleton: { width: '68px', height: '20px' },
        justifyContent: 'flex-start',
        component: ({ value, values }) => (
            <TextRegular className={`brand-text ${values.brand_name === 'Grand Total' && 'text-bold'}`}>
                {value}
            </TextRegular>
        ),
    },

    {
        name: 'Value Si',
        value: 'sellin_value',
        skeleton: { width: '144px', height: '20px' },
        withSort: true,
        textAlign: 'right',
        justifyContent: 'flex-end',
        component: ({ value, values }) => (
            <TextRegular className={`price-number ${values.brand_name === 'Grand Total' && 'text-bold'}`}>
                {formatCurrency(value)}
            </TextRegular>
        ),
    },

    {
        name: 'MTD Si',
        skeleton: { width: '64px', height: '20px' },
        value: 'sellin_mtd',
        withSort: true,
        width: 96,
        justifyContent: 'center',
        component: ({ value, values }) => <BadgeComponent type={values?.sellin_status} value={value} />,
    },

    {
        name: 'Value YTD Si',
        withSort: true,
        skeleton: { width: '144px', height: '20px' },
        value: 'sellin_value_ytd',
        justifyContent: 'flex-end',
        component: ({ value, values }) => (
            <TextRegular className={`price-number ${values.brand_name === 'Grand Total' && 'text-bold'}`}>
                {formatCurrency(value)}
            </TextRegular>
        ),
    },
    {
        name: '',
        value: 'devider',
        justifyContent: 'center',
        width: 32,
        withSort: false,
        component: () => <DeviderComponent />,
    },
    {
        name: 'Value So',
        value: 'sellout_value',
        skeleton: { width: '144px', height: '20px' },
        withSort: true,
        justifyContent: 'flex-end',
        component: ({ value, values }) => (
            <TextRegular className={`price-number ${values.brand_name === 'Grand Total' && 'text-bold'}`}>
                {formatCurrency(value)}
            </TextRegular>
        ),
    },
    {
        name: 'MTD So',
        width: 102,
        withSort: true,
        skeleton: { width: '64px', height: '20px' },
        value: 'sellout_mtd',
        justifyContent: 'center',
        component: ({ value, values }) => <BadgeComponent type={values?.sellout_status} value={value} />,
    },
    {
        name: 'Value YTD So',
        value: 'sellout_value_ytd',
        skeleton: { width: '144px', height: '20px' },
        withSort: true,
        justifyContent: 'flex-end',
        component: ({ value, values }) => (
            <TextRegular className={`price-number ${values.brand_name === 'Grand Total' && 'text-bold'}`}>
                {formatCurrency(value)}
            </TextRegular>
        ),
    },
];
