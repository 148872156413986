import { Box } from '@chakra-ui/react';
import React, { useEffect, createContext } from 'react';

import Table from '@pages/StockTrail/sections/table';
import { fetchStockTrail } from '@pages/StockTrail/helpers/crud';
import { useStockTrailFilterStore, useStockTrailStore } from '@pages/StockTrail/helpers/mainState';
import Head from '@pages/StockTrail/sections/head';

export const PageContext = createContext();

const StockTrail = () => {
    const { onChangeStockTrailStore, onResetStockTrailStore, trails, isLoadingTable } = useStockTrailStore();
    const {
        searchQuery,
        selectedStore,
        selectedDC,
        page,
        onResetStockTrailFilterSelectedStore,
        selectedStoreGroup,
        selectedStatus,
        selectedUploadType,
        selectedSorting,
        dateUploadSelected,
    } = useStockTrailFilterStore();

    const refetchStockTrail = () => {
        fetchStockTrail({
            onChangeStockTrailStore,
            page,
            searchQuery,
            selectedStore,
            selectedDC,
            selectedStoreGroup,
            selectedStatus,
            selectedUploadType,
            selectedSorting,
            dateUploadSelected,
        });
    };

    useEffect(() => {
        refetchStockTrail();
        // eslint-disable-next-line
    }, [
        page,
        searchQuery,
        selectedStore,
        selectedDC,
        selectedStoreGroup,
        selectedStatus,
        selectedUploadType,
        selectedSorting,
        dateUploadSelected,
    ]);

    useEffect(() => {
        return () => {
            onResetStockTrailStore();
            onResetStockTrailFilterSelectedStore();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <PageContext.Provider value={{ refetchStockTrail }}>
            <Box className="stock-trail-container">
                <Head />
                <Table trails={trails} isLoadingTable={isLoadingTable} />
            </Box>
        </PageContext.Provider>
    );
};

export default StockTrail;
