import { Box, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { useUserInfo } from '@store/userInfo';
import './styles.scss';

const Profile = () => {
    const { userInfo } = useUserInfo();
    return (
        <Box className="profile-container">
            <Text className="label">Informasi Toko</Text>

            <Flex className="text-item">
                <Text className="text-label">Nama outlet</Text>
                <Text className="text-value">{userInfo?.nama_outlet || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Customer ID</Text>
                <Text className="text-value">{userInfo?.customer_id || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Store group</Text>
                <Text className="text-value">{userInfo?.group || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Nama Owner</Text>
                <Text className="text-value">{userInfo?.nama_owner || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Kode tipe outlet</Text>
                <Text className="text-value">{userInfo?.kode_tipe_outlet || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Nama Sales</Text>
                <Text className="text-value">{userInfo?.nama_salesman || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Contact</Text>
                <Text className="text-value">{userInfo?.hp || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Sales Area</Text>
                <Text className="text-value">{userInfo?.sales_area || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">DC</Text>
                <Text className="text-value">{userInfo?.nama_dc || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Outlet Type</Text>
                <Text className="text-value">{userInfo?.tipe_outlet || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Alamat</Text>
                <Text className="text-value">{userInfo?.alamat_outlet || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Kode Area</Text>
                <Text className="text-value">{userInfo?.kode_area || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Kecamatan</Text>
                <Text className="text-value">{userInfo?.kecamatan || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Kelurahan</Text>
                <Text className="text-value">{userInfo?.kelurahan || '-'}</Text>
            </Flex>

            <Flex className="text-item">
                <Text className="text-label">Kode Pos</Text>
                <Text className="text-value">{userInfo?.kode_pos || '-'}</Text>
            </Flex>
        </Box>
    );
};

export default Profile;
