const hasRole = (selectedRole, rolesToCheck) => {
    const filteringSelectedRole = selectedRole?.filter((item) => rolesToCheck.includes(item?.name));
    return filteringSelectedRole?.length > 0;
};

export const onShowCustomerField = ({ selectedRole }) => {
    return hasRole(selectedRole, ['Store Owner']);
};

export const onShowDCField = ({ selectedRole }) => {
    return hasRole(selectedRole, ['Area Admin', 'Area Leaders']);
};

export const onShowUserField = ({ selectedRole }) => {
    return hasRole(selectedRole, ['user']);
};

