import React from 'react';

export const IconDesc = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7H13" stroke="var(--main-color)" strokeWidth="2" strokeLinecap="round" />
        <path d="M5 12H11" stroke="var(--main-color)" strokeWidth="2" strokeLinecap="round" />
        <path d="M5 17H9" stroke="var(--main-color)" strokeWidth="2" strokeLinecap="round" />
        <path d="M19 18L22 15M19 18L16 15M19 18L19 6" stroke="var(--main-color)" strokeWidth="2" />
    </svg>
);
