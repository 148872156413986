import React from 'react';
import { Box, Flex, Button, Input, Text, Tooltip, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSnackbarNotification } from '@store/snackbarNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { CloseEyeIcon, OpenEyeIcon } from '@assets/icons';

import { onLoginHandler } from './helpers/crud';
import { onValidationPassword } from './helpers/events';
import { useLoginPassword } from './helpers/mainState';

import './styles.scss';
import { Circle1Image, OutlookIcon, Circle2Image, ErrorIcon } from './assets/icons';
import moment from 'moment';

import imageDashboard from './assets/dashboard.svg';
import sagitaWhite from '@assets/sagitarius-white.svg';
import { useLoader } from '@store/loader';
import { useEffect, useState } from 'react';
import { isEmpty, upperFirst } from 'lodash';

const Login = () => {
    const navigate = useNavigate();
    const [didMount, setDidMount] = useState(false);
    const { search } = useLocation();
    const { onChangeLoader } = useLoader();

    const {
        username,
        password,
        onChangeLoginPasswordStore,
        isLoading,
        isOpenPassword,
        errorMessage,
        onResetLoginPasswordStore,
    } = useLoginPassword();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const isValid = onValidationPassword({ username, password });

    useEffect(() => {
        if (search?.includes('errorMessage')) {
            const errorMessageParam = search?.replace('?errorMessage=', '');
            const errorMessageString = decodeURIComponent(errorMessageParam);

            onChangeLoginPasswordStore({
                errorMessage: errorMessageString,
            });

            navigate('/');
        }

        setDidMount(true);

        if (didMount) {
            return onResetLoginPasswordStore();
        }
    }, [search]);

    return (
        <Box className="login-password__container">
            <Box className="left-section">
                <Circle1Image />
                <Circle2Image />

                <Box className="box-login__left">
                    <Box>
                        <Image src={sagitaWhite} className="image-logo" />
                        <Text className="title">
                            Kelola Penjualan dan Pertumbuhan Toko lebih Mudah dengan Sagitarius
                        </Text>
                        <Text className="desc">Sagitarius memudahkan pengelolaan penjualan toko dengan efisien.</Text>
                    </Box>
                    <Box className="image-dashboard__container">
                        <Image src={imageDashboard} className="image-dashboard" />
                    </Box>
                </Box>
            </Box>

            <Box className="right-section">
                <Box className="box-login__right">
                    <Text className="title">Selamat datang di Sagitarius!</Text>
                    <Text className="desc">Silahkan login menggunakan akun Sagitarius kamu.</Text>

                    <ConditionalComponent statement={!isEmpty(errorMessage)}>
                        <Flex className="error-container">
                            <ErrorIcon />
                            <Text className="error-text">{upperFirst(errorMessage)}</Text>
                        </Flex>
                    </ConditionalComponent>

                    <Box className="input-container">
                        <Text className="label">
                            Email <span>*</span>
                        </Text>
                        <Box position="relative">
                            <Input
                                data-testid="input-username__login"
                                placeholder="Masukan email disini"
                                className="input"
                                onChange={(e) => onChangeLoginPasswordStore({ username: e.target.value })}
                                onKeyUp={(event) =>
                                    event.key === 'Enter' &&
                                    onLoginHandler({
                                        username,
                                        password,
                                        onChangeSnackbarNotification,
                                        navigate,
                                        onChangeLoginPasswordStore,
                                        isValid,
                                    })
                                }
                            />
                        </Box>
                    </Box>

                    <Box className="input-container" mb="12px !important">
                        <Text className="label">
                            Password <span>*</span>
                        </Text>
                        <Box position="relative">
                            <Input
                                data-testid="input-password__login"
                                placeholder="Masukan password disini"
                                className="input"
                                name="password"
                                type={isOpenPassword ? 'text' : 'password'}
                                onChange={(e) => onChangeLoginPasswordStore({ password: e.target.value })}
                                onKeyUp={(event) =>
                                    event.key === 'Enter' &&
                                    onLoginHandler({
                                        username,
                                        password,
                                        onChangeSnackbarNotification,
                                        navigate,
                                        onChangeLoginPasswordStore,
                                        isValid,
                                    })
                                }
                            />
                            <ConditionalComponent statement={isOpenPassword}>
                                <OpenEyeIcon onClick={() => onChangeLoginPasswordStore({ isOpenPassword: false })} />
                            </ConditionalComponent>
                            <ConditionalComponent statement={!isOpenPassword}>
                                <CloseEyeIcon onClick={() => onChangeLoginPasswordStore({ isOpenPassword: true })} />
                            </ConditionalComponent>
                        </Box>
                    </Box>

                    <Button
                        isLoading={isLoading}
                        className={`button-submit`}
                        data-testid="button-submit__login"
                        onClick={() =>
                            onLoginHandler({
                                username,
                                password,
                                onChangeSnackbarNotification,
                                navigate,
                                isValid,
                                onChangeLoginPasswordStore,
                            })
                        }
                    >
                        Login
                    </Button>

                    <Tooltip label="Hanya dapat menggunakan email @paracorpgroup.com" textAlign="center" width="100%">
                        <Box className="oauth-container">
                            <Text className="or-text">atau</Text>
                            <a
                                href={`${process.env.REACT_APP_API_URL}/login/azure`}
                                onClick={() => onChangeLoader({ isLoadingPage: true })}
                            >
                                <Box className="oauth-button">
                                    <OutlookIcon />
                                    <Text className="outlook-text">Login with Outlook</Text>
                                </Box>
                            </a>
                        </Box>
                    </Tooltip>

                    <Text className="copyright">© {moment().format('YYYY')} Sagitarius All Rights Reserved</Text>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
