import { Box } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold, TextRegular } from '@components/typography';
import TableComponent from '@pages/storeConfigurationDetail/components/table';

const Product = ({ configuration, isLoadingConfiguration }) => {
    return (
        <Box className={styles['product__container']}>
            <Box className={styles['head-title__container']}>
                <TextBold className={styles['title']}>Kelola Produk</TextBold>
            </Box>
            <TableComponent configuration={configuration} isLoadingConfiguration={isLoadingConfiguration} />
        </Box>
    );
};

export default Product;
