import { Box } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import CheckboxComponent from '@pages/storeConfigurationDetail/components/checkbox';
import Select from '@components/filter/select';
import { useParams } from 'react-router-dom';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';
import { ConditionalComponent } from '@components/functionComponent';
import { isEmpty } from 'lodash';

const BiviSync = ({ configuration, isLoadingConfiguration }) => {
    const { id } = useParams();

    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);

    return (
        <Box className={styles['bivi-sync__container']}>
            <TextBold className={styles['title']}>Bivi Sync</TextBold>
            <CheckboxComponent
                value={configuration?.is_sync_bivi_dashboard}
                isLoading={isLoadingConfiguration}
                label="Status"
                description="Aktivasi sinkronisasi data ke BiVi"
                onChange={(val) =>
                    updateConfigurationInfoState?.mutate({
                        [val?.id]: val?.value,
                        template_upload: { id: 'Custom', name: 'Custom' },
                    })
                }
                id="is_sync_bivi_dashboard"
            />
            {console.log(configuration?.system_type)}
            <CheckboxComponent
                value={!isEmpty(configuration?.tag?.value)}
                isLoading={isLoadingConfiguration}
                label="Tutup Bivi Dashboard"
                description="Tutup akses input data Sell-Out dari Aplikasi BiVi"
                onChange={(val) =>
                    updateConfigurationInfoState?.mutate({
                        tag: val?.value ? { value: configuration?.system_type?.name } : null,
                    })
                }
                id="is_tag_active"
            />
            <Select
                isloadingComponent={isLoadingConfiguration}
                title="Sources"
                isMultiple={false}
                isDisabled
                mt="12px"
                placeholder={configuration?.tag?.value ? configuration?.system_type?.name : 'Nonaktif'}
            />
        </Box>
    );
};

export default BiviSync;
