import authAxios from '@services/authAxios';

const fetchSisoLatestSync = async () => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/siso/latest-sync`,
    });

    return data?.data;
};

export default fetchSisoLatestSync;
