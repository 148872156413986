import Cookies from 'universal-cookie';
import React from 'react';
import { useEffect } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

import { PeopleIcon } from '@assets/icons';
import FloatingMenu from '@components/floatingMenu';
import { clickProfileList } from './const';
import SkeletonContainer from '@components/skeletonContainer';
import { useSkeletonPage } from '@store/skeletonPage';

import './styles.scss';
import { ChevronDownIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { TextMedium, TextRegular, TextSemiBold } from '@components/typography';
import { getShortUsername } from './helpers/utils';
import { upperFirst } from 'lodash';

const Header = () => {
    const cookies = new Cookies();
    const { onChangeSkeletonPage, isLoadingSkeleton } = useSkeletonPage();
    const username = cookies.get('username') || '-';
    const shortName = getShortUsername(username);
    const role = cookies.get('role') || '-';

    useEffect(() => {
        onChangeSkeletonPage();
        // eslint-disable-next-line
    }, []);

    return (
        <Flex className="header-global-container">
            <Flex>
                <Box mr="12px">
                    <TextSemiBold className="date-text">{moment(new Date()).format('ddd, D MMMM YYYY')}</TextSemiBold>
                    <TextRegular className="time-text">{moment(new Date()).format('HH:mm:ss')}</TextRegular>
                </Box>
                <TextSemiBold className="badge-week">Week {moment().week()}</TextSemiBold>
            </Flex>

            <FloatingMenu
                list={clickProfileList}
                isClickMenu
                buttonElement={
                    <Flex alignItems="center" className="button-element__container">
                        <Box className="circle-shortname">
                            {shortName}
                            <Box className="active-indicator" />
                        </Box>
                        <Box m="0 12px  ">
                            <TextSemiBold className="username-text">{upperFirst(username)}</TextSemiBold>
                            <TextRegular className="role-text">{role}</TextRegular>
                        </Box>
                        <ChevronDownIcon fontSize="22px" color="var(--semantic-text)" />
                    </Flex>
                }
            />
        </Flex>
    );
};

export default Header;
