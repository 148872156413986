import { Box } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import CheckboxComponent from '@pages/storeConfigurationDetail/components/checkbox';
import Select from '@components/filter/select';
import { useParams } from 'react-router-dom';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';
import { statusTransferOptions } from './const';
import InputComponent from '@pages/storeConfigurationDetail/components/input';
import { ConditionalComponent } from '@components/functionComponent';

const TransferIn = ({ configuration, isLoadingConfiguration }) => {
    const { id } = useParams();
    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);
    return (
        <Box className={styles['transfer-in__container']}>
            <TextBold className={styles['title']}>Transfer In</TextBold>
            <CheckboxComponent
                value={configuration?.is_sync_transfer_in}
                isLoading={isLoadingConfiguration}
                id="is_sync_transfer_in"
                label="Status"
                onChange={(val) =>
                    updateConfigurationInfoState?.mutate({
                        [val?.id]: val?.value,
                        template_upload: { id: 'Custom', name: 'Custom' },
                    })
                }
                description="Aktivasi status pipeline data DO ke Transfer-In iSeller"
            />
            <CheckboxComponent
                value={configuration?.is_sync_consignment}
                isLoading={isLoadingConfiguration}
                id="is_sync_consignment"
                label="Toko Consignment"
                onChange={(val) =>
                    updateConfigurationInfoState?.mutate({
                        [val?.id]: val?.value,
                        customer_id_konsi: val?.value ? configuration?.customer_id_konsi : '',
                        template_upload: { id: 'Custom', name: 'Custom' },
                    })
                }
                description="Mendapatkan data DO customer id konsinyasi"
            />
            <ConditionalComponent statement={configuration?.is_sync_consignment}>
                <InputComponent
                    isLoading={isLoadingConfiguration}
                    value={configuration?.customer_id_konsi}
                    label="Customer ID Consignment"
                    placeholder="Masukan Customer ID Consignment"
                    onChange={(value) =>
                        updateConfigurationInfoState?.mutate({
                            customer_id_konsi: value,
                        })
                    }
                />
            </ConditionalComponent>
            <Select
                data={statusTransferOptions}
                isloadingComponent={isLoadingConfiguration}
                selected={configuration?.transfer_status}
                title="Status Transfer"
                isMultiple={false}
                isCanSearch={false}
                mt="12px"
                placeholder="Pilih status transfer"
                onChangeFilter={(value) =>
                    updateConfigurationInfoState?.mutate({
                        transfer_status: value,
                        template_upload: { id: 'Custom', name: 'Custom' },
                    })
                }
            />
        </Box>
    );
};

export default TransferIn;
