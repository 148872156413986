import { Box, Flex } from '@chakra-ui/react';
import { TextBold } from '@components/typography';
import { InformationBlackIcon } from '@assets/icons/index';
import FilterMonth from '@components/filter/month';

import './styles.css';
import TableTemplate2 from '@components/tableTemplate2';
import { tableList } from './const';
import EmptyStateBottomComponent from '@pages/BusinessSiso/components/emptyStateBottomComponent';
import { useFilter } from '@pages/BusinessSiso/state/filters';
import { useTable } from '@pages/BusinessSiso/state/table';
import useGetSisoTable from '@domain/siso/useGetSisoTable';
import TooltipComponent from '@components/tooltip';
import { TooltipTabletontent } from '@pages/BusinessSiso/components/toolipContent';
import ButtonExport from '@components/buttons/buttonExport';
import onExportSisoTable from '@domain/siso/onExportSisoTable';
import moment from 'moment';
import { reverse, sortBy } from 'lodash';
import useSortSisoTable from '@domain/siso/useSortSisoTable';
import { useState } from 'react';

const TableComponent = () => {
    const filters = useFilter();
    const table = useTable();
    const [sortType, setSortType] = useState({ name: '', type: 'default' });
    const exportSisoTable = onExportSisoTable({ fileName: `data-siso-${moment().format('YYY')}.xlsx`, filters, table });

    const { data, isLoading } = useGetSisoTable(filters, table);
    const sortMutation = useSortSisoTable(filters, table);

    const onSortingData = (sortName) => {
        let temp = [...data];
        let totalData = {};

        const cleanData = temp?.filter((item) => {
            if (item?.brand_name === 'Grand Total') {
                totalData = item;
                return;
            }

            return item;
        });

        let sortingResult = [];

        if (sortName === 'sellin_mtd') {
            sortingResult = sortBy(cleanData, ['mtd_sellin_number']);
        } else if (sortName === 'sellout_mtd') {
            sortingResult = sortBy(cleanData, ['mtd_sellout_number']);
        } else sortingResult = sortBy(cleanData, (item) => item[sortName]);

        if (sortType?.type === 'desc') reverse(sortingResult);

        sortMutation.mutate({ lines: sortingResult, summary: totalData });

        const sortValue = sortType?.type === 'desc' ? 'asc' : 'desc';

        setSortType({ name: sortName, type: sortValue });
    };

    return (
        <Box className="table-siso__container">
            <Flex className="statistic__head">
                <TextBold className="statistic__title  no-highlight__text">
                    Sell-In (Si) dan Sell-Out (So) Berdasarkan Brand
                    <TooltipComponent
                        content={<TooltipTabletontent />}
                        maxWidth="557px"
                        textAlign="center"
                        positionArrowLeft="270px"
                    >
                        <InformationBlackIcon />
                    </TooltipComponent>
                </TextBold>
                <Flex gap="32px" alignItems="center">
                    <FilterMonth
                        onChange={({ month, year }) => table.setTable({ tableMonth: month, tableYear: year })}
                    />
                    <ButtonExport mutation={exportSisoTable} fileName="Siso export data" />
                </Flex>
            </Flex>

            <TableTemplate2
                data={data}
                isPagination={false}
                tableList={tableList}
                isLoading={isLoading}
                sortType={sortType}
                onSort={(type) => onSortingData(type)}
                emptyStateBottomComponent={<EmptyStateBottomComponent />}
            />
        </Box>
    );
};

export default TableComponent;
