export const months = [
    {
        id: 1,
        label: 'Jan',
    },
    {
        id: 2,
        label: 'Feb',
    },
    {
        id: 3,
        label: 'Mar',
    },
    {
        id: 4,
        label: 'Apr',
    },
    {
        id: 5,
        label: 'Mei',
    },
    {
        id: 6,
        label: 'Jun',
    },
    {
        id: 7,
        label: 'Jul',
    },
    {
        id: 8,
        label: 'Agu',
    },
    {
        id: 9,
        label: 'Sep',
    },
    {
        id: 10,
        label: 'Okt',
    },
    {
        id: 11,
        label: 'Nov',
    },
    {
        id: 12,
        label: 'Dec',
    },
];
