export const onCheckInputFile = ({ file, maxSize, accessAllowed, onChangeSnackbarNotification }) => {
    // eslint-disable-next-line

    const size = file?.size;
    const fileSplitName = file?.name.split('.');
    const fileType = fileSplitName[fileSplitName?.length - 1];
    maxSize = maxSize * 1000000;

    if (size && size > maxSize) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed!',
            description: `Maximal file ${maxSize / 1000000}MB!`,
            duration: 3,
        });

        return 'not-valid';
    }

    if (accessAllowed && !accessAllowed?.includes(fileType)) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed!',
            description: `File ${fileType} not support!`,
            duration: 3,
        });
        return 'not-valid';
    }
};
