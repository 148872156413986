import { Box, Flex } from '@chakra-ui/react';
import SkeletonComponent from '@components/skeleton';
import '../styles.scss';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';

const StoreConfigurationDetailSkeleton = () => {
    return (
        <Box className="store-configuration__skeleton">
            {/* HEAD */}
            <Flex className="box-section" gap="16px" padding="16px 24px !important">
                <SkeletonComponent height="24px" width="24px" mt="2px" mb="2px" />
                <Box flex={1}>
                    <SkeletonComponent height="28px" width="120px" mb="4px" />
                    <Flex alignItems="center" gap="8px">
                        <SkeletonComponent height="16px" width="16px" />
                        <SkeletonComponent height="20px" maxWidth="680px" width="100%" />
                    </Flex>
                </Box>
            </Flex>

            {/* STORE INFO */}

            <Box className="box-section" gap="16px">
                <Box pb="24px" mb="24px" borderBottom="1px solid var(--color-neutral10)">
                    <SkeletonComponent height="24px" width="480px" />
                </Box>
                <Flex justifyContent="space-between" mb="24px">
                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="80px" mb="4px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Box>

                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="80px" mb="4px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Box>

                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="80px" mb="4px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Box>

                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="80px" mb="4px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Box>

                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="80px" mb="4px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Box>
                </Flex>

                <Flex justifyContent="space-between" gap="24px">
                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="120px" mb="8px" />
                        <SkeletonComponent height="40px" width="100%" />
                    </Box>

                    <Box flex={1}>
                        <SkeletonComponent height="20px" width="120px" mb="8px" />
                        <SkeletonComponent height="40px" width="100%" />
                    </Box>
                </Flex>
            </Box>

            <Flex gap="24px">
                {/* INTEGRATION */}

                <Box className="box-section" gap="16px" padding="12px 24px  !important" flex={1}>
                    <Box pb="16px" borderBottom="1px solid var(--color-neutral10)">
                        <SkeletonComponent height="32px" width="120px" />
                    </Box>
                    <IterationComponent
                        arr={[1, 2, 3]}
                        render={() => (
                            <Flex
                                gap="12px"
                                alignItems="center"
                                height="64px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="20px" />
                                <Box>
                                    <SkeletonComponent height="20px" width="80px" mb="4px" />
                                    <SkeletonComponent height="16px" width="240px" />
                                </Box>
                            </Flex>
                        )}
                    />
                    <Box height="92px" padding="12px 0">
                        <SkeletonComponent height="20px" width="120px" mb="8px" />
                        <SkeletonComponent height="40px" width="100%" />
                    </Box>
                </Box>

                {/* BIVI SYNC */}

                <Box className="box-section" gap="16px" padding="12px 24px  !important" flex={1}>
                    <Box pb="16px" borderBottom="1px solid var(--color-neutral10)">
                        <SkeletonComponent height="32px" width="120px" />
                    </Box>
                    <IterationComponent
                        arr={[1, 2]}
                        render={() => (
                            <Flex
                                gap="12px"
                                alignItems="center"
                                height="64px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="20px" />
                                <Box>
                                    <SkeletonComponent height="20px" width="80px" mb="4px" />
                                    <SkeletonComponent height="16px" width="240px" />
                                </Box>
                            </Flex>
                        )}
                    />
                    <Box height="92px" padding="12px 0">
                        <SkeletonComponent height="20px" width="120px" mb="8px" />
                        <SkeletonComponent height="40px" width="100%" />
                    </Box>
                </Box>
            </Flex>

            {/* SYNC */}
            <Box className="box-section" gap="16px" padding="12px 24px  !important" flex={1}>
                <SkeletonComponent height="32px" width="120px" mb="20px" />
                <Flex gap="24px">
                    <IterationComponent
                        arr={[1, 2]}
                        render={() => (
                            <Box flex={1}>
                                <Flex height="56px" alignItems="center" borderBottom="1px solid var(--color-neutral10)">
                                    <SkeletonComponent height="32px" width="120px" />
                                </Flex>

                                <Flex
                                    gap="12px"
                                    alignItems="center"
                                    height="64px"
                                    width="100%"
                                    borderBottom="1px solid var(--color-neutral10)"
                                >
                                    <SkeletonComponent height="20px" width="20px" />
                                    <Box>
                                        <SkeletonComponent height="20px" width="80px" mb="4px" />
                                        <SkeletonComponent height="16px" width="240px" />
                                    </Box>
                                </Flex>
                                <Flex
                                    borderBottom="1px solid var(--color-neutral10)"
                                    justifyContent="space-between"
                                    height="64px"
                                    alignItems="center"
                                >
                                    <Box>
                                        <SkeletonComponent height="20px" maxWidth="200px" width="100%" mb="4px" />
                                        <SkeletonComponent height="16px" maxWidth="256px" width="100%" />
                                    </Box>
                                    <SkeletonComponent height="36px" width="80px" />
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    height="44px"
                                    width="100%"
                                    borderBottom="1px solid var(--color-neutral10)"
                                >
                                    <SkeletonComponent height="20px" width="120px" />
                                    <SkeletonComponent height="20px" width="120px" />
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    height="44px"
                                    width="100%"
                                    borderBottom="1px solid var(--color-neutral10)"
                                >
                                    <SkeletonComponent height="20px" width="120px" />
                                    <Flex>
                                        <SkeletonComponent height="16px" width="16px" mr="4px" />
                                        <SkeletonComponent height="20px" width="80px" />
                                    </Flex>
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    height="44px"
                                    width="100%"
                                    borderBottom="1px solid var(--color-neutral10)"
                                >
                                    <SkeletonComponent height="20px" width="120px" />
                                    <SkeletonComponent height="20px" width="120px" />
                                </Flex>
                            </Box>
                        )}
                    />
                </Flex>
            </Box>

            <Flex gap="24px">
                {/* MASTER DATA */}

                <Box className="box-section" gap="16px" padding="12px 24px !important" flex={1} height="384px">
                    <Flex alignItems="center" borderBottom="1px solid var(--color-neutral10)" mb="4px">
                        <SkeletonComponent height="32px" width="120px" mb="16px" />
                    </Flex>

                    <Flex
                        height="44px"
                        alignItems="center"
                        borderBottom="1px solid var(--color-neutral10)"
                        justifyContent="space-between"
                    >
                        <SkeletonComponent height="20px" width="120px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Flex>

                    <Flex
                        height="44px"
                        alignItems="center"
                        borderBottom="1px solid var(--color-neutral10)"
                        justifyContent="space-between"
                    >
                        <SkeletonComponent height="20px" width="120px" />
                        <SkeletonComponent height="20px" width="120px" />
                    </Flex>
                </Box>

                {/* TRANSFER IN */}

                <Box className="box-section" gap="16px" padding="12px 24px  !important" flex={1} height="384px">
                    <Flex alignItems="center" borderBottom="1px solid var(--color-neutral10)" mb="4px">
                        <SkeletonComponent height="32px" width="120px" mb="16px" />
                    </Flex>
                    <IterationComponent
                        arr={[1, 2]}
                        render={() => (
                            <Flex
                                gap="12px"
                                alignItems="center"
                                height="64px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="20px" />
                                <Box>
                                    <SkeletonComponent height="20px" width="80px" mb="4px" />
                                    <SkeletonComponent height="16px" width="240px" />
                                </Box>
                            </Flex>
                        )}
                    />

                    <IterationComponent
                        arr={[1, 2]}
                        render={() => (
                            <Flex height="92px" justifyContent="center" flexDirection="column">
                                <SkeletonComponent height="20px" width="120px" mb="8px" />
                                <SkeletonComponent height="40px" width="100%" />
                            </Flex>
                        )}
                    />
                </Box>
            </Flex>

            {/* PRODUCT */}

            <Box className="box-section" gap="16px" padding="12px 24px  !important" flex={1}>
                <Flex alignItems="center" borderBottom="1px solid var(--color-neutral10)" mb="4px">
                    <SkeletonComponent height="32px" width="120px" mb="16px" />
                </Flex>
                <Flex
                    height="49px"
                    alignItems="center"
                    borderBottom="1px solid var(--color-neutral10)"
                    justifyContent="space-between"
                    padding="0 12px"
                >
                    <SkeletonComponent height="16px" width="68px" />

                    <Flex pr="14px" gap="52px">
                        <SkeletonComponent height="16px" width="68px" />
                        <SkeletonComponent height="16px" width="68px" />
                    </Flex>
                </Flex>

                <IterationComponent
                    arr={[1, 2, 3]}
                    render={() => (
                        <Flex
                            height="64px"
                            alignItems="center"
                            borderBottom="1px solid var(--color-neutral10)"
                            justifyContent="space-between"
                            padding="0 12px"
                        >
                            <Box>
                                <SkeletonComponent height="16px" width="68px" mb="2px" />
                                <SkeletonComponent height="16px" width="200px" />
                            </Box>

                            <Flex pr="39px" gap="102px">
                                <SkeletonComponent height="18px" width="18px" />
                                <SkeletonComponent height="18px" width="18px" />
                            </Flex>
                        </Flex>
                    )}
                />
            </Box>

            {/* INVOICING */}

            {/* <ConditionalComponent statement={false}>
                <Box className="box-section" padding="12px 24px" flex={1}>
                    <Box pb="16px" borderBottom="1px solid var(--color-neutral10)" mb="12px">
                        <SkeletonComponent height="32px" width="120px" />
                    </Box>

                    <Flex gap="24px">
                        <Box width="100%">
                            <Flex
                                gap="12px"
                                alignItems="center"
                                height="64px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="20px" />
                                <Box>
                                    <SkeletonComponent height="20px" width="80px" mb="4px" />
                                    <SkeletonComponent height="16px" width="240px" />
                                </Box>
                            </Flex>
                            <Flex
                                height="80px"
                                justifyContent="center"
                                flexDirection="column"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="80px" mb="12px" />
                                <Flex alignItems="center">
                                    <SkeletonComponent height="16px" width="16px" mr="8px" />
                                    <SkeletonComponent height="20px" width="60px" mr="16px" />
                                    <SkeletonComponent height="16px" width="16px" mr="8px" />
                                    <SkeletonComponent height="20px" width="60px" />
                                </Flex>
                            </Flex>

                            <Flex
                                height="80px"
                                justifyContent="center"
                                flexDirection="column"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="80px" mb="12px" />
                                <Flex alignItems="center">
                                    <SkeletonComponent height="16px" width="16px" mr="8px" />
                                    <SkeletonComponent height="20px" width="60px" mr="16px" />
                                    <SkeletonComponent height="16px" width="16px" mr="8px" />
                                    <SkeletonComponent height="20px" width="60px" />
                                </Flex>
                            </Flex>

                            <Flex height="92px" justifyContent="center" flexDirection="column">
                                <SkeletonComponent height="20px" width="120px" mb="8px" />
                                <SkeletonComponent height="40px" width="100%" />
                            </Flex>

                            <Flex height="92px" justifyContent="center" flexDirection="column">
                                <SkeletonComponent height="20px" width="120px" mb="8px" />
                                <SkeletonComponent height="40px" width="100%" />
                            </Flex>
                        </Box>

                        <Box width="100%">
                            <SkeletonComponent height="20px" width="200px" mb="4px" />
                            <SkeletonComponent height="16px" width="256px" mb="12px" />
                            <Flex gap="12px" pb="12px" borderBottom="1px solid var(--color-neutral10)">
                                <SkeletonComponent height="36px" width="100%" />
                                <SkeletonComponent height="36px" width="80px" />
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                height="44px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="120px" />
                                <SkeletonComponent height="20px" width="120px" />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                height="44px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="120px" />
                                <Flex>
                                    <SkeletonComponent height="16px" width="16px" mr="4px" />
                                    <SkeletonComponent height="20px" width="80px" />
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                height="44px"
                                width="100%"
                                borderBottom="1px solid var(--color-neutral10)"
                            >
                                <SkeletonComponent height="20px" width="120px" />
                                <SkeletonComponent height="20px" width="120px" />
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </ConditionalComponent> */}
        </Box>
    );
};

export default StoreConfigurationDetailSkeleton;
