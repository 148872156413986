import { Box } from '@chakra-ui/react';
import Filters from './sections/filters';
import StatisticsOverview from './sections/statistics';
import Trend from './sections/trend';
import Table from './sections/table';
import { useTrends } from './state/trends';
import { useStatistics } from './state/statistics';
import { useTable } from './state/table';
import { useFilter } from './state/filters';
import { useEffect } from 'react';
import HetModal from './components/hetModal';
import Alert from './sections/alert';

const BusinessSiso = () => {
    const filters = useFilter();
    const trends = useTrends();
    const statistics = useStatistics();
    const table = useTable();

    useEffect(() => {
        return () => {
            filters?.resetFilter();
            trends?.resetTrends();
            statistics?.resetStatistics();
            table?.resetTable();
        };
    }, []);

    return (
        <Box className="business-siso__container">
            <Filters />
            <Alert />
            <StatisticsOverview />
            <Trend />
            <Table />
            <HetModal />
        </Box>
    );
};

export default BusinessSiso;
