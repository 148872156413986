import getSisoStatisticsRequest from '@domain/model/request/siso/getSisoStatisticsRequest';
import getSisoStatistics from '@hooks/siso/getSisoStatistics';

const useGetSisoStatistics = (filters, statistics) => {
    const queries = getSisoStatisticsRequest({ ...filters, ...statistics });

    return getSisoStatistics(queries);
};

export default useGetSisoStatistics;
