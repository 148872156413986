const errorResponseHandler = (error, onShowErrorStatus) => {
    const statusCode = error?.response?.status;
    console.log(error);

    let errorState = {};

    switch (statusCode) {
        case 400:
            errorState = {
                status: 'invalid',
                title: 'Maaf, telah terjadi kesalahan di Sagitarius ...',
                description:
                    'Kami menyadari masalah ini dan sedang dalam proses perbaikan. Silahkan tunggu beberapa saat dan coba muat ulang halaman ini.',
            };
            break;
        case 401:
            errorState = {
                status: 'forbidden',
                title: 'Akses dibutuhkan',
                description:
                    'Halaman yang Kamu coba akses dibatasi. Saat ini, kamu tidak memiliki izin untuk mengakses halaman ini. Silahkan hubungi tim kami jika Kamu memerlukan izin akses.',
            };
            break;
        case 403:
            errorState = {
                status: 'forbidden',
                title: 'Akses dibutuhkan',
                description:
                    'Halaman yang Kamu coba akses dibatasi. Saat ini, kamu tidak memiliki izin untuk mengakses halaman ini. Silahkan hubungi tim kami jika Kamu memerlukan izin akses.',
            };
            break;
        case 404:
            errorState = {
                status: 'not-found',
                title: 'Maaf, halaman yang Kamu cari tidak ditemukan ...',
                description: 'Kamu mungkin salah mengetik tautan atau halaman tersebut mungkin telah dipindahkan.',
            };
            break;
        case 500:
            errorState = {
                status: 'invalid',
                title: 'Maaf, telah terjadi kesalahan di Sagitarius ...',
                description:
                    'Kami menyadari masalah ini dan sedang dalam proses perbaikan. Silahkan tunggu beberapa saat dan coba muat ulang halaman ini.',
            };
            break;
        default:
            errorState = {
                status: 'network',
                title: 'Whoa! Koneksi hilang.',
                description:
                    'Sepertinya koneksi internet Kamu terputus atau dimatikan. Silahkan periksa kembali koneksi internet Kamu dan muat ulang halaman ini',
            };
            break;
    }

    if (onShowErrorStatus) onShowErrorStatus(errorState);

    return 'An unknown error occurred.';
};

export default errorResponseHandler;
