import { Flex } from '@chakra-ui/react';
import { IterationComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';

const SelectSkeleton = ({ width }) => (
    <IterationComponent
        arr={[1, 2, 3, 4, 5, 6, 7, 8]}
        render={() => (
            <Flex
                gap="12px"
                alignItems="center"
                borderBottom="1px solid var(--color-neutral20)"
                height="44px"
                background="white"
                paddingLeft="12px"
            >
                <SkeletonComponent height="16px" width="16px" />
                <SkeletonComponent height="20px" width={`calc(${width} - 56px)`} />
            </Flex>
        )}
    />
);

export default SelectSkeleton;
