import { Box } from '@chakra-ui/react';
import React from 'react';
import { useSpotLight } from '@store/spotlight';
import { ConditionalComponent } from '@components/functionComponent';

import './styles.scss';

const SpotLight = () => {
    const { spotlightActive, onResetSpotlight } = useSpotLight();

    return (
        <ConditionalComponent statement={spotlightActive}>
            <Box className="spotlight-container" onClick={onResetSpotlight} />
        </ConditionalComponent>
    );
};

export default SpotLight;
