import { create } from 'zustand';

const initialState = {
    username: '',
    password: '',
    isLoading: false,
    isOpenPassword: false,
    isLoadingOauth: false,
    errorMessage: '',
};

export const useLoginPassword = create((set) => ({
    ...initialState,
    onChangeLoginPasswordStore: (newState) => set((state) => ({ ...state, ...newState })),
    onResetLoginPasswordStore: () => {
        set(() => ({
            ...initialState,
        }));
    },
}));
