import { SuccessGreen24Icon, WarningRed24Icon } from '@assets/icons/index';
import React from 'react';

export const getColor = ({ status }) => {
    const defaultColor = {
        borderColor: 'var(--color-danger60)',
        backgroundColor: 'var(--color-danger10)',
        icon: <WarningRed24Icon />,
    };

    const obj = {
        error: {
            borderColor: 'var(--color-danger60)',
            backgroundColor: 'var(--color-danger10)',
            icon: <WarningRed24Icon />,
        },
        failed: {
            borderColor: 'var(--color-danger60)',
            backgroundColor: 'var(--color-danger10)',
            icon: <WarningRed24Icon />,
        },
        success: {
            borderColor: 'var(--color-success60)',
            backgroundColor: 'var(--color-success10)',
            icon: <SuccessGreen24Icon />,
        },
    };

    return obj[status] || defaultColor;
};
