import { useMutation, useQueryClient } from '@tanstack/react-query';

const updateConfigurationInfoState = (id) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (obj) => {
            return {
                ...obj,
            };
        },
        onSuccess: (response) => {
            queryClient.setQueryData(['configuration-info', id], (prevData) => {
                return {
                    ...prevData,
                    ...response,
                };
            });
        },
    });
};

export default updateConfigurationInfoState;
