import { Box, Flex } from '@chakra-ui/react';

const { TextSemiBold } = require('@components/typography');

export const BadgeComponent = ({ type, value }) => {
    let data = {};

    switch (type) {
        case 'Positive':
            data = {
                background: 'var(--color-success10)',
                textColor: 'var(--color-success60)',
                status: '+',
            };
            break;

        case 'Negative':
            data = {
                background: 'var(--color-red2)',
                textColor: 'var(--color-red3)',
                status: '-',
            };
            break;

        case 'Neutral':
            data = {
                background: 'var(--skeleton-color)',
                textColor: 'var(--color-grey1)',
                status: '+',
            };
            break;

        default:
            break;
    }

    return (
        <Flex justifyContent="center" alignItems="center" height="40px">
            <Box className="badge-component" background={data?.background}>
                <TextSemiBold color={data?.textColor} className="badge-value__text">
                    {data?.status}
                    {value}%
                </TextSemiBold>
            </Box>
        </Flex>
    );
};

export const DeviderComponent = () => <Box className="devider-component" />;
