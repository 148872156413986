import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Text, Button } from '@chakra-ui/react';
import { ConditionalComponent } from '@components/functionComponent';
import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import Badge from '@components/badge';
import FloatingMenu from '@components/floatingMenu';

import './styles.scss';

export const ActionsComponent = ({ value }) => {
    const navigate = useNavigate();

    return (
        <Box className="action-icon__container__store-config">
            <FloatingMenu
                className="dotted-icon"
                isClickMenu={true}
                list={[
                    <Box className="action-menu" onClick={() => navigate(`/store-config/iseller/${value}`)}>
                        <Text> Config</Text>
                    </Box>,
                ]}
                buttonElement={<Button className="action-button">Actions</Button>}
            />
        </Box>
    );
};

export const IconChecked = ({ value }) => {
    return (
        <Text className="column-text">
            <ConditionalComponent
                statement={value}
                fallback={<NotAllowedIcon color="red.500" boxSize={4} data-testid="not-allowed-icon__store-config" />}
            >
                <CheckCircleIcon color="green.500" boxSize={4} data-testid="check-circle-icon__store-config" />
            </ConditionalComponent>
        </Text>
    );
};

export const Status = ({ value }) => {
    const statusMap = value
        ? { title: 'Active', color: '#4B4B4B', backgroundColor: '#EAF6E9' }
        : { title: 'Inactive', color: '#F24822', backgroundColor: '#FFC7C2' };

    const { color, backgroundColor, title } = statusMap;

    return <Badge color={color} backgroundColor={backgroundColor} title={title} />;
};

export const TextColumn = ({ value }) => <Text className="column-text">{value || '-'}</Text>;
