import { Checkbox, Flex, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import ButtonOutline from '@components/buttons/buttonOutline';
import ButtonRegular from '@components/buttons/buttonRegular';
import { TextBold, TextRegular } from '@components/typography';
import styles from './styles.module.css';
import { DashboardImage } from '@assets/images';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'universal-cookie';

const HetModal = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();

    const { isOpen, onClose } = useDisclosure({
        defaultIsOpen: cookies.get('hetModalStatus') !== 'hide',
    });

    const [isCheck, setIsCheck] = useState();

    return (
        <Modal isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent margin={0} alignItems="center" alignSelf="center" minWidth={'520px'} borderRadius="12px">
                <Flex className={`${styles['het-modal__container']} het-modal__container`}>
                    <DashboardImage />
                    <TextBold className={styles['title']}>Kebijakan Fitur Dashboard SiSo</TextBold>
                    <TextRegular className={styles['description']}>
                        Seluruh angka yang ditampilkan di halaman ini menggunakan{' '}
                        <span>Harga Eceran Tertinggi (HET).</span> Harap dicatat, Sell-In dan Sell-Out di halaman ini
                        belum terhubung dengan pencapaian dalam yang menggunakan<span>nilai NETT.</span>
                    </TextRegular>
                    <Flex mb="24px">
                        <Checkbox onChange={(e) => setIsCheck(e.target.checked)} color="red">
                            <TextRegular>Saya mengerti dan lanjutkan ke dashboard</TextRegular>
                        </Checkbox>
                    </Flex>
                    <Flex justifyContent="center" width="100%" gap="12px">
                        <ButtonOutline
                            width="176px"
                            borderRadius="6px !important"
                            color="var(--color-primary)"
                            onClick={() => navigate('/dahboard')}
                            component={<TextBold color="var(--color-primary)">Kembali ke Beranda</TextBold>}
                        />
                        <ButtonRegular
                            isDisabled={!isCheck}
                            width="176px"
                            borderRadius="6px !important"
                            color="var(--color-primary)"
                            onClick={() => {
                                cookies.set('hetModalStatus', 'hide', {
                                    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // expired 1 tahun dari sekarang
                                });
                                onClose();
                            }}
                            component={
                                <TextBold color={isCheck ? 'white' : 'var(--color-neutral50 )'}>Lanjutkan </TextBold>
                            }
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default HetModal;
