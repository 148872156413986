import moment from 'moment';
import { create } from 'zustand';

const state = {
    statisticMonth: moment().format('MM'),
    statisticYear: moment().format('YYYY'),
};

export const useStatistics = create((set) => ({
    ...state,
    setStatistics: (newState) => set((state) => ({ ...state, ...newState })),
    resetStatistics: () => set(() => ({ ...state })),
}));
