import patchConfigurationInfo from '@data/configuration/patchConfigurationInfo';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { useMutation } from '@tanstack/react-query';

const updateConfigurationInfo = ({ id, payload }) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    return useMutation({
        mutationFn: () => patchConfigurationInfo({ id, payload }),
        onSuccess: () => {
            onChangeSnackbarNotification({
                status: 'success',
                title: 'Konfigurasi Toko Berhasil Disimpan.',
                description: '',
                duration: 3,
            });
        },
        onError: (error) => {
            const errorMessage = error?.response?.data?.message ?? 'Terdapat sebuah kesalahan sistem';
            onChangeSnackbarNotification({
                status: 'error',
                title: 'Konfigurasi Toko Gagal Disimpan.',
                description: errorMessage,
                duration: 3,
            });
        },
    });
};

export default updateConfigurationInfo;
