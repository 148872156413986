import authAxios from '@services/authAxios';

const fetchExportConfigurationHistory = async ({ id }) => {
    const data = await authAxios({
        method: 'GET',
        url: `/api/v1/configuration/download/${id}`,
        responseType: 'blob',
    });

    return data?.data;
};

export default fetchExportConfigurationHistory;
