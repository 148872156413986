import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { onRemoveAllCookies, redirectToPage } from '@helpers/events';
import { routeList } from '@routes';
import { checkPagePermission } from './utils';

export const onFetchProfile = async ({ navigate, pathname, token, setIsLoadingUserProfile, onChangeUserInfo }) => {
    try {
        const cookies = new Cookies();
        setIsLoadingUserProfile(true);

        const { data } = await Axios({
            url: '/api/v1/store/info',
            method: 'GET',
            headers: { Authorization: 'Bearer ' + token },
        });

        const { isDefaultPassword, menuAccess, customer } = data;

        onChangeUserInfo({ userInfo: customer });

        cookies.set('menuAccess', JSON.stringify(menuAccess), { path: '/' });

        //============================ NAVIGATE ========================

        checkPagePermission([...routeList], menuAccess, pathname, navigate);

        if (isDefaultPassword) navigate('/reset-password');
        else if (pathname === '/reset-password') {
            redirectToPage({ menuAccess, navigate });
        }
    } catch (error) {
        console.log(error);
        const errorStatus = error?.response?.status;

        if (errorStatus === 401) {
            onRemoveAllCookies();

            navigate('/login');

            return;
        } else {
        }
    } finally {
        setIsLoadingUserProfile(false);
    }
};
