import { Flex, Box } from '@chakra-ui/react';

import './styles.scss';
import { TextBold, TextSemiBold } from '@components/typography';
import { UploadIcon, ExcelIcon } from '@assets/icons/index';
import { upperFirst } from 'lodash';

const FileSelectedInfo = ({ selectedFile, onClose }) => (
    <Flex className="file-selected-info__container">
        <Flex alignItems="center">
            <ExcelIcon />
            <Box className="file-info__container">
                <TextBold className="file-name__text">{upperFirst(selectedFile?.name)}</TextBold>
                <TextSemiBold className="file-size__text">
                    {(selectedFile?.size / 1000000).toFixed(2).replace('.00', '')} MB
                </TextSemiBold>
            </Box>
        </Flex>
        <Flex className="change-file__container" onClick={onClose}>
            <TextBold className="change-file__text">Ganti File</TextBold>
            <UploadIcon />
        </Flex>
    </Flex>
);

export default FileSelectedInfo;
