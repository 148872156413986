import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { onChangeSetting } from '@pages/StoreConfig/helpers/event';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

const Dropdown = ({ store, options, selectedItem, fieldName, onForceChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClickItem = (item) => {
        if (onForceChange) onForceChange(item);
        else onChangeSetting({ newValue: { [fieldName]: item }, store });

        setIsOpen(false);
    };

    return (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <Box className="dropdown-container__store-config-detail">
                <Text className="dropdown-value" onClick={() => setIsOpen(true)}>
                    {selectedItem || 'Select item '} <ChevronDownIcon />
                </Text>

                <ConditionalComponent statement={isOpen}>
                    <Flex className="dropdown-list fade-animation">
                        <IterationComponent
                            arr={options}
                            render={(item) => (
                                <Text className="dropdown-text" onClick={() => onClickItem(item)}>
                                    {item?.name}
                                </Text>
                            )}
                        />
                    </Flex>
                </ConditionalComponent>
            </Box>
        </OutsideClickHandler>
    );
};

export default Dropdown;
