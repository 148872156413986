import fetchSyncToExagonInventory from '@data/configuration/fetchSyncToExagonInventory';
import { useQuery } from '@tanstack/react-query';

const getSyncToExagonInventory = (id) => {
    return useQuery({
        queryKey: ['sync-to-exagon-inventory', id],
        queryFn: async () => await fetchSyncToExagonInventory(id),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getSyncToExagonInventory;
