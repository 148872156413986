import { redirectToPage } from '@helpers/events';
import { find } from 'lodash';

export const onCheckIsDashboardPage = ({ pathname }) => {
    const pageListWithoutDashboard = ['/login', '/reset-password', '/documentation', '/refresh-page', '/oauth'];

    return pageListWithoutDashboard?.filter((item) => pathname?.includes(item))?.length === 0;
};

export const checkPagePermission = (routes, menuAccess, pathname, navigate) => {
    let result = [];

    routes?.forEach((route) => {
        const childrenAvailable = route?.children?.filter((item) => find(menuAccess, { link: item?.id }));
        const isParentAvailable = find(menuAccess, { link: route?.id })?.link;

        if (isParentAvailable || route?.type === '*') {
            result.push(route);
        }

        if (route?.children?.length > 0 && childrenAvailable?.length > 0) {
            result = [...result, ...childrenAvailable];
        }
    });

    const isGranted = result?.filter(({ path }) => pathname.includes(path?.replace('/:id', '')))?.length > 0;

    if (!isGranted) {
        redirectToPage({ menuAccess, navigate });
    }
};
