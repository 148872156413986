import fetchSyncToExagonOutbound from '@data/configuration/fetchSyncToExagonOutbound';
import { useQuery } from '@tanstack/react-query';

const getSyncToExagonOutbound = (id) => {
    return useQuery({
        queryKey: ['sync-to-exagon-outbound', id],
        queryFn: async () => await fetchSyncToExagonOutbound(id),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getSyncToExagonOutbound;
