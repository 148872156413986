import { isEmpty } from 'lodash';

const getSalesAreasResponse = (data) => {
    if (isEmpty(data)) return [];

    const { page_data } = data;

    return page_data;
};

export default getSalesAreasResponse;
