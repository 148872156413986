import { Tooltip } from '@chakra-ui/react';
import React from 'react';

const FloatingInfo = ({ label, position, children }) => (
    <Tooltip
        hasArrow
        label={label}
        bg="white"
        borderRadius="6px"
        padding="12px"
        fontSize="12px"
        color="#4b4b4b"
        fontWeight={600}
        placement={position}
    >
        {children}
    </Tooltip>
);

export default FloatingInfo;
