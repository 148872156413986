import { Box, Input, Text } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import { ConditionalComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';

const InputComponent = ({ label, placeholder, isLoading, onChange, value }) => {
    return (
        <Box className={styles['input-component__container']}>
            <TextBold className={styles['text-label']}>{label}</TextBold>
            <ConditionalComponent statement={!isLoading} fallback={<SkeletonComponent height="40px" width={'100%'} />}>
                <Input
                    onChange={(e) => onChange(e.target.value)}
                    className={styles['input']}
                    value={value}
                    placeholder={placeholder}
                />
            </ConditionalComponent>
        </Box>
    );
};

export default InputComponent;
