import React from 'react';

export const InfoIcon = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_613_7152)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75C9.41414 21.75 6.93419 20.7228 5.10571 18.8943C3.27723 17.0658 2.25 14.5859 2.25 12C2.25 9.41414 3.27723 6.93419 5.10571 5.10571C6.93419 3.27723 9.41414 2.25 12 2.25C14.5859 2.25 17.0658 3.27723 18.8943 5.10571C20.7228 6.93419 21.75 9.41414 21.75 12ZM24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM9.375 15.75C9.07663 15.75 8.79048 15.8685 8.5795 16.0795C8.36853 16.2905 8.25 16.5766 8.25 16.875C8.25 17.1734 8.36853 17.4595 8.5795 17.6705C8.79048 17.8815 9.07663 18 9.375 18H14.625C14.9234 18 15.2095 17.8815 15.4205 17.6705C15.6315 17.4595 15.75 17.1734 15.75 16.875C15.75 16.5766 15.6315 16.2905 15.4205 16.0795C15.2095 15.8685 14.9234 15.75 14.625 15.75H13.125V10.5H10.5C10.2016 10.5 9.91548 10.6185 9.7045 10.8295C9.49353 11.0405 9.375 11.3266 9.375 11.625C9.375 11.9234 9.49353 12.2095 9.7045 12.4205C9.91548 12.6315 10.2016 12.75 10.5 12.75H10.875V15.75H9.375ZM12 9C12.3978 9 12.7794 8.84196 13.0607 8.56066C13.342 8.27936 13.5 7.89782 13.5 7.5C13.5 7.10218 13.342 6.72064 13.0607 6.43934C12.7794 6.15804 12.3978 6 12 6C11.6022 6 11.2206 6.15804 10.9393 6.43934C10.658 6.72064 10.5 7.10218 10.5 7.5C10.5 7.89782 10.658 8.27936 10.9393 8.56066C11.2206 8.84196 11.6022 9 12 9Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_613_7152">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const CloseIcon = ({ color, onClick }) => (
    <svg
        style={{ cursor: 'pointer' }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        data-testid="close-icon"
    >
        <path
            d="M6.758 17.243L12.001 12L17.244 17.243M17.244 6.75696L12 12L6.758 6.75696"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
