import React from 'react';

export const ProfileIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 7.5C10.4497 7.5 11.625 6.32475 11.625 4.875C11.625 3.42525 10.4497 2.25 9 2.25C7.55025 2.25 6.375 3.42525 6.375 4.875C6.375 6.32475 7.55025 7.5 9 7.5Z"
            stroke="#2A3D4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M2.25 15.3V15.75H15.75V15.3C15.75 13.6198 15.75 12.7798 15.423 12.138C15.1354 11.5735 14.6765 11.1146 14.112 10.827C13.4702 10.5 12.6302 10.5 10.95 10.5H7.05C5.36985 10.5 4.52978 10.5 3.88804 10.827C3.32354 11.1146 2.8646 11.5735 2.57698 12.138C2.25 12.7798 2.25 13.6198 2.25 15.3Z"
            stroke="#2A3D4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export const LogoutIcon = ({ color = '#003c6d' }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99689 2.25H2.25V15.75H9"
            stroke="#E6001C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.375 12.375L15.75 9L12.375 5.625"
            stroke="#E6001C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M6 8.99689H15.75" stroke="#E6001C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
