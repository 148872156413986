import { ConditionalComponent } from '@components/functionComponent';
import React from 'react';
import { Checkbox, Skeleton, Input, Text } from '@chakra-ui/react';

export const CheckboxComponent = ({ onChangeSetting, storeInfo, store, fieldName, isTemplateType }) => {
    const onCheck = (value) => {
        let obj = { ...store };

        if (isTemplateType) {
            obj = {
                ...obj,
                storeInfo: {
                    ...storeInfo,
                    template_upload: { value: 'custom', label: 'Custom', name: 'Custom' },
                },
            };
        }

        onChangeSetting({ newValue: { [fieldName]: value }, store: obj });
    };

    return (
        <ConditionalComponent statement={!store?.isLoading} fallback={<Skeleton width="16px" height={'16px'} />}>
            <Checkbox isChecked={storeInfo[fieldName]} onChange={(e) => onCheck(e.target.checked)} />
        </ConditionalComponent>
    );
};

export const InputComponent = ({ onChangeSetting, storeInfo, store, fieldName, placeholder, isTemplateType }) => {
    const onChange = (value) => {
        let obj = { ...store };

        if (isTemplateType) {
            obj = {
                ...obj,
                storeInfo: {
                    ...storeInfo,
                    template_upload: { value: 'custom', label: 'Custom', name: 'Custom' },
                },
            };
        }

        onChangeSetting({ newValue: { [fieldName]: value }, store: obj });
    };

    return (
        <ConditionalComponent statement={!store?.isLoading} fallback={<Skeleton width="200px" height={'16px'} />}>
            <Input placeholder={placeholder} value={storeInfo[fieldName]} onChange={(e) => onChange(e.target.value)} />
        </ConditionalComponent>
    );
};

export const TextComponent = ({ store, value, color = '#2f2f2f' }) => (
    <ConditionalComponent statement={!store?.isLoading} fallback={<Skeleton width="80px" height={'16px'} />}>
        <Text className="text-value" color={color}>
            {value ?? '-'}
        </Text>
    </ConditionalComponent>
);
