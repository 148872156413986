import { Box, Text } from '@chakra-ui/react';
import './styles.scss';
import { SagitariusLogo } from '@assets/logo';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onCheckToken } from './api';
import { useSnackbarNotification } from '@store/snackbarNotification';

const OauthPage = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    useEffect(() => {
        if (search?.includes('token')) {
            setTimeout(() => {
                onCheckToken({ authToken: search?.replace('?token=', ''), onChangeSnackbarNotification, navigate });
            }, 1500);
        }
    }, [search]);
    return (
        <Box className="outh-page__container">
            <SagitariusLogo />
            <Box className="loader" />
            <Text className="outlook-connection__text">Menghubungkan Outlook dengan Sagitarius</Text>
            <Text className="outlook-redirect__text">
                Jika proses ini berhasil kamu akan diarahkan ke halaman utama Sagitarius
            </Text>
        </Box>
    );
};

export default OauthPage;
