//============================= LIBRARY  =====================================

import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

//============================= GLOBAL COMPONENTS  =====================================

import FieldInput from '@components/formField/input';
import Select from '@components/formField/select';
import { ConditionalComponent } from '@components/functionComponent';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { useLoader } from '@store/loader';
import { CloseEyeIcon, OpenEyeIcon } from '@assets/icons';

//============================= LOCAL COMPONENTS  =====================================

import { onSubmitForm } from '@pages/UserManagement/helpers/crud';
import { onShowCustomerField, onShowDCField, onShowUserField } from '@pages/UserManagement/helpers/utils';
import { fetchCustomer, fetchDC, fetchRole, fetchDetailUser } from '@pages/UserManagement/helpers/crud';
import {
    useAddEditStore,
    useFilterCustomerOptionStore,
    useFilterDCOptionStore,
    useFilterRoleOptionStore,
} from '@pages/UserManagement/helpers/mainState';

import './styles.scss';

let isMounting = false;

const AddEditUserManagment = () => {
    const navigate = useNavigate();
    const isEditPage = useLocation()?.pathname?.includes('edit');
    const { onChangeLoader } = useLoader();
    const { id, uniqueEmail } = useParams();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    //============================= STATE  =====================================

    const addEditState = useAddEditStore();
    const {
        onChangeAddEditStore,
        selectedRole,
        username,
        email,
        selectedDC,
        selectedCustomer,
        selectedAccess,
        hasSubmit,
        password,
        confirmPassword,
        isLoadingSubmit,
        onResetAddEditStore,
        isShowPassword,
    } = addEditState;

    //============================= FILTER ASSETS  =====================================

    const { roleOptions, onChangeFilterRoleOptionStore, isLoadingRoleOption, roleAccessOptions } =
        useFilterRoleOptionStore();
    const { dcOptions, onChangeFilterDCOptionStore, searchDCQuery, isLoadingDCOption } = useFilterDCOptionStore();
    const { customerOptions, onChangeFilterCustomerOptionStore, searchCustomerQuery, isLoadingCustomerOption } =
        useFilterCustomerOptionStore();

    //============================= FETCH ASSETS DATA =====================================

    const onFetchRole = ({ searchQuery }) => fetchRole({ onChangeFilterRoleOptionStore, searchQuery });
    const onFetchDC = ({ searchQuery }) => fetchDC({ onChangeFilterDCOptionStore, searchQuery });
    const onFetchCustomer = ({ searchQuery }) => fetchCustomer({ onChangeFilterCustomerOptionStore, searchQuery });

    //============================= VALIDATION =====================================

    const isShowCustomerField = onShowCustomerField({ selectedRole });
    const isShowDCField = onShowDCField({ selectedRole });
    const isShowUserField = onShowUserField({ selectedRole });

    useEffect(() => {
        if (isEditPage)
            fetchDetailUser({ id, uniqueEmail, onChangeAddEditStore, onChangeLoader, onChangeSnackbarNotification });

        if (isMounting) return onResetAddEditStore();

        isMounting = true;

        // eslint-disable-next-line
    }, [isEditPage]);

    return (
        <Flex className="add-edit-usermanagement__container fade-animation">
            <Flex className="head">
                <Box className="icon-back" onClick={() => navigate('/user-management')}>
                    <ChevronLeftIcon color="var(--main-color-dark)" fontSize="24px" />
                </Box>
                <Text className="title" onClick={() => navigate('/user-management')}>
                    {isEditPage ? 'Update' : 'Create'} User
                </Text>
            </Flex>
            <Box className="body">
                <ConditionalComponent statement={!isEditPage}>
                    <Box className="form-item">
                        <Select
                            options={roleOptions}
                            placeholder="Pilih Role"
                            isMultiple={false}
                            value={selectedRole}
                            label="Role"
                            isLoading={isLoadingRoleOption}
                            isRequired={hasSubmit && isEmpty(selectedRole)}
                            onSelect={(selected) => onChangeAddEditStore({ selectedRole: selected })}
                            onFetch={({ searchQuery }) => onFetchRole({ searchQuery })}
                            onSearch={(value) => onChangeFilterRoleOptionStore({ searchRoleQuery: value })}
                        />
                    </Box>
                </ConditionalComponent>
                <Box className="form-item">
                    <FieldInput
                        label="Username"
                        placeholder="Input username"
                        type="text"
                        value={username}
                        isRequired={hasSubmit && isEmpty(username)}
                        onChange={(value) => onChangeAddEditStore({ username: value })}
                    />
                </Box>
                <ConditionalComponent statement={!isEditPage}>
                    <Box className="form-item">
                        <FieldInput
                            label="Email"
                            placeholder="Input email"
                            type="text"
                            value={email}
                            isRequired={hasSubmit && isEmpty(email)}
                            onChange={(value) => onChangeAddEditStore({ email: value })}
                        />
                    </Box>
                </ConditionalComponent>
                <Box className="form-item">
                    <FieldInput
                        label="Password"
                        placeholder="Input password"
                        type={isShowPassword ? 'text' : 'password'}
                        value={password}
                        autoComplete="new-password"
                        isRequired={hasSubmit && isEmpty(password) && !isEditPage}
                        onChange={(value) => onChangeAddEditStore({ password: value })}
                    />
                    <Box onClick={() => onChangeAddEditStore({ isShowPassword: !isShowPassword })}>
                        <ConditionalComponent statement={isShowPassword} fallback={<CloseEyeIcon />}>
                            <OpenEyeIcon />
                        </ConditionalComponent>
                    </Box>
                </Box>
                <Box className="form-item">
                    <FieldInput
                        label="Confirm Password"
                        placeholder="Confirm password"
                        type={isShowPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        autoComplete="new-password"
                        isRequired={hasSubmit && isEmpty(confirmPassword) && !isEditPage}
                        onChange={(value) => onChangeAddEditStore({ confirmPassword: value })}
                    />
                    <Box onClick={() => onChangeAddEditStore({ isShowPassword: !isShowPassword })}>
                        <ConditionalComponent statement={isShowPassword} fallback={<CloseEyeIcon />}>
                            <OpenEyeIcon />
                        </ConditionalComponent>
                    </Box>
                </Box>

                <ConditionalComponent statement={isShowDCField && !isEditPage}>
                    <Box className="form-item">
                        <Select
                            options={dcOptions}
                            placeholder="Pilih DC"
                            isLoading={isLoadingDCOption}
                            isMultiple={false}
                            label="DC"
                            isCanSearch
                            searchQuery={searchDCQuery}
                            value={selectedDC}
                            isRequired={hasSubmit && isEmpty(selectedDC)}
                            onFetch={({ searchQuery }) => onFetchDC({ searchQuery })}
                            onSelect={(selected) => onChangeAddEditStore({ selectedDC: selected })}
                            onSearch={(value) => onChangeFilterDCOptionStore({ searchDCQuery: value })}
                        />
                    </Box>
                </ConditionalComponent>
                <ConditionalComponent statement={isShowUserField}>
                    <Box className="form-item">
                        <Select
                            options={roleAccessOptions}
                            placeholder="Pilih Access"
                            isMultiple
                            value={selectedAccess}
                            label="Access"
                            isLoading={isLoadingRoleOption}
                            isRequired={hasSubmit && isEmpty(selectedAccess)}
                            onSelect={(selected) => onChangeAddEditStore({ selectedAccess: selected })}
                            onFetch={() => onFetchRole({})}
                        />
                    </Box>
                </ConditionalComponent>

                <ConditionalComponent statement={isShowCustomerField && !isEditPage}>
                    <Box className="form-item">
                        <Select
                            options={customerOptions}
                            isMultiple={false}
                            placeholder="Pilih Store"
                            label="Store"
                            isCanSearch={true}
                            value={selectedCustomer}
                            searchQuery={searchCustomerQuery}
                            isLoading={isLoadingCustomerOption}
                            isRequired={hasSubmit && isEmpty(selectedCustomer)}
                            onFetch={({ searchQuery }) => onFetchCustomer({ searchQuery })}
                            onSelect={(selected) => onChangeAddEditStore({ selectedCustomer: selected })}
                            onSearch={(value) => onChangeFilterCustomerOptionStore({ searchCustomerQuery: value })}
                        />
                    </Box>
                </ConditionalComponent>
                <Button
                    isLoading={isLoadingSubmit}
                    className="button-submit "
                    onClick={() =>
                        onSubmitForm({
                            onChangeAddEditStore,
                            onChangeSnackbarNotification,
                            addEditState,
                            onResetAddEditStore,
                            navigate,
                            isEditPage,
                            userId: id,
                        })
                    }
                >
                    Submit
                </Button>
            </Box>
        </Flex>
    );
};

export default AddEditUserManagment;
