import { find } from 'lodash';

export const onCheckSelectedParentPath = ({ children, pathname, path }) => {
    if (children?.length) {
        return children?.filter(({ path }) => pathname === path)?.length > 0;
    }

    return path === pathname;
};

export const filterRouteList = (routes, menuAccess) => {
    const result = [];
    routes?.forEach((route) => {
        const childrenAvailable = route?.children?.filter((item) => find(menuAccess, { link: item?.id }));
        const isParentAvailable = find(menuAccess, { link: route?.id })?.link;

        if (route?.showInSideBar && isParentAvailable) {
            result.push(route);
        }

        if (route?.showInSideBar && route?.children?.length > 0 && childrenAvailable?.length > 0) {
            result.push({ ...route, children: childrenAvailable });
        }
    });

    return result;
};
