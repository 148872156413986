import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import Cookies from 'universal-cookie';
import { Box, Flex, Text } from '@chakra-ui/react';
import onGetClientScreen from '@store/clientUser';
import { SagitariusLogo, SagitariusLogoSmall } from '@assets/logo';
import { ConditionalComponent, IterationComponent, Element } from '@components/functionComponent';
import { routeList } from '@routes';
import { useExpandNavbar } from '@store/expandNavbar';

import NavbarSkeleton from './components/skeleton';
import { ArrowLeftIcon, ChevronDownIcon, ArrowRightIcon, DotIcon } from './icons';
import { onCheckSelectedParentPath, filterRouteList } from './utils';
import './styles.scss';

const Navbar = ({ isLoadingUserProfile }) => {
    const cookies = new Cookies();
    const menuAccess = cookies.get('menuAccess');
    const clientScreen = onGetClientScreen();

    const navItemHeight = clientScreen === 'wideScreen' ? 52 : clientScreen === 'mediumScreen' ? 40 : 36;
    const maxNavItemHeight = clientScreen === 'wideScreen' ? '52px' : clientScreen === 'mediumScreen' ? '56px' : '50px';

    const { pathname } = useLocation();
    const { onChangeExpandNavbar, isExpandNavbar } = useExpandNavbar();
    const navigate = useNavigate();
    const routes = filterRouteList(routeList, menuAccess);

    return (
        <>
            {/* //====================== NAVBAR EXPANDED ========= */}

            <ConditionalComponent statement={isExpandNavbar}>
                <Box className="navbar-container" data-testid="navbar-container">
                    <Flex className="navbar-header">
                        <SagitariusLogo />
                        <ArrowLeftIcon onClick={() => onChangeExpandNavbar({ isExpandNavbar: false })} />
                    </Flex>

                    <Box className="navbar-list">
                        <ConditionalComponent statement={!isLoadingUserProfile} fallback={<NavbarSkeleton />}>
                            <Text className="main-menu__text">Main Menu</Text>

                            <IterationComponent
                                arr={routes}
                                render={({ children, name, icon, path }) => {
                                    const isParentSelected = onCheckSelectedParentPath({ children, pathname, path });
                                    return (
                                        <Box
                                            className="navbar-item"
                                            key={name}
                                            maxH={
                                                isParentSelected
                                                    ? `${(children?.length + 1) * navItemHeight + 70}px`
                                                    : maxNavItemHeight
                                            }
                                        >
                                            {/* // ================== NAVBAR PARENT ==============  */}

                                            <Flex
                                                className="navbar-item__parent"
                                                data-testid={`navbar-item__parent-${name}`}
                                                onClick={() => navigate(path)}
                                            >
                                                <ConditionalComponent statement={isParentSelected}>
                                                    <Box className="selected-indicator" />
                                                </ConditionalComponent>
                                                <Element value={icon} />
                                                <Text className="navbar-text">{name}</Text>
                                                <ConditionalComponent statement={children?.length}>
                                                    <ChevronDownIcon
                                                        isActive={isParentSelected}
                                                        color={isParentSelected ? '--color-primary' : '--color-blue1'}
                                                    />
                                                </ConditionalComponent>
                                            </Flex>

                                            {/* // ================== NAVBAR CHILD ==============  */}

                                            <IterationComponent
                                                arr={children}
                                                render={({ name, path }) => {
                                                    const isActiveChild = pathname === path;
                                                    return (
                                                        <Flex
                                                            className={`navbar-item__child ${
                                                                isActiveChild && 'navbar-item__child-actived'
                                                            }`}
                                                            key={name}
                                                            onClick={() => navigate(path)}
                                                        >
                                                            <DotIcon
                                                                color={
                                                                    isActiveChild
                                                                        ? 'var(--color-primary)'
                                                                        : 'var(--color-blue1)'
                                                                }
                                                            />
                                                            <Text className="navbar-text">{name}</Text>
                                                        </Flex>
                                                    );
                                                }}
                                            />
                                        </Box>
                                    );
                                }}
                            />
                        </ConditionalComponent>
                    </Box>
                </Box>
            </ConditionalComponent>

            {/* //====================== NAVBAR NOT EXPANDED ========= */}

            <ConditionalComponent statement={!isExpandNavbar}>
                <Box className="navbar-container__small">
                    <Flex className="navbar-header">
                        <SagitariusLogoSmall />
                        <ArrowRightIcon onClick={() => onChangeExpandNavbar({ isExpandNavbar: true })} />
                    </Flex>

                    <Box className="navbar-list">
                        <IterationComponent
                            arr={routes}
                            render={({ children, name, icon, path }) => (
                                <Box
                                    className="navbar-item"
                                    key={name}
                                    maxH={
                                        onCheckSelectedParentPath({ children, pathname, path })
                                            ? `${(children?.length + 1) * navItemHeight + maxNavItemHeight}px`
                                            : '70px'
                                    }
                                >
                                    {/* // ================== NAVBAR PARENT ==============  */}

                                    <Flex
                                        className="navbar-item__parent"
                                        onClick={() => navigate(path)}
                                        data-testid={`navbar-item__parent-${name}__small`}
                                    >
                                        <ConditionalComponent
                                            statement={onCheckSelectedParentPath({ children, pathname, path })}
                                        >
                                            <Box className="selected-indicator" />
                                        </ConditionalComponent>
                                        <Element value={icon} />
                                    </Flex>
                                </Box>
                            )}
                        />
                    </Box>
                </Box>
            </ConditionalComponent>
        </>
    );
};

export default Navbar;
