import { Box } from '@chakra-ui/react';
import './styles.css';
import { TextBold } from '@components/typography';
import CheckboxComponent from '@pages/storeConfigurationDetail/components/checkbox';
import InputComponent from '@pages/storeConfigurationDetail/components/input';
import { useParams } from 'react-router-dom';
import useUpdateConfigurationInfoState from '@domain/configuration/useUpdateConfigurationInfoState';

const Integration = ({ configuration, isLoadingConfiguration }) => {
    const { id } = useParams();
    const updateConfigurationInfoState = useUpdateConfigurationInfoState(id);

    return (
        <Box className="store-config-detail__integration__container">
            <TextBold className="title">Integrasi</TextBold>
            <CheckboxComponent
                isLoading={isLoadingConfiguration}
                value={configuration?.is_active}
                label="Status"
                description="Aktivasi pipeline sell-out ke iSeller"
                onChange={(val) =>
                    updateConfigurationInfoState?.mutate({
                        [val?.id]: val?.value,
                        template_upload: { id: 'Custom', name: 'Custom' },
                    })
                }
                id="is_active"
            />
            <CheckboxComponent
                isLoading={isLoadingConfiguration}
                value={configuration?.is_get_order}
                label="Get Order Detail"
                onChange={(val) => updateConfigurationInfoState?.mutate({ [val?.id]: val?.value })}
                id="is_get_order"
                description="Aktivasi pipeline detail order dari iSeller"
            />
            <CheckboxComponent
                isLoading={isLoadingConfiguration}
                value={configuration?.is_trusted_iseller}
                onChange={(val) =>
                    updateConfigurationInfoState?.mutate({
                        [val?.id]: val?.value,
                        template_upload: { id: 'Custom', name: 'Custom' },
                    })
                }
                id="is_trusted_iseller"
                label="Customer ID sesuai iSeller"
                description="Sesuaikan customer-id dari settings di CMS iSeller"
            />
            <InputComponent
                isLoading={isLoadingConfiguration}
                value={configuration?.iseller_url}
                label="URL ISeller"
                placeholder="Masukan URL ISeller..."
                onChange={(value) =>
                    updateConfigurationInfoState?.mutate({
                        iseller_url: value,
                    })
                }
            />
        </Box>
    );
};

export default Integration;
