import authAxios from '@services/authAxios';

const fetchSisoFilterAccess = async () => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/siso/filter-access`,
    });

    return data?.data;
};

export default fetchSisoFilterAccess;
