import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Button, Input, Text } from '@chakra-ui/react';

import { ConditionalComponent } from '@components/functionComponent';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { CloseEyeIcon, OpenEyeIcon } from '@assets/icons';

import { ResetPasswordImageIllustration } from './assets/illustrations';
import { onValidationPassword, onCheckingUppercase, onCheckingSpecialChar } from './helpers/events';
import { useResetPassword } from './helpers/mainState';
import { onResetPasswordHandler } from './helpers/crud';
import { CheckIcon } from './assets/icons';
import './styles.scss';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const { confirmationPassword, password, onChangeResetPasswordStore, isLoading, isOpenPassword } =
        useResetPassword();

    const isLongChar = password?.length > 8;
    const isUppercaseChar = onCheckingUppercase({ password });
    const isSpecialChar = onCheckingSpecialChar({ password });
    const isMatchPasssword = password === confirmationPassword;

    const isValid = onValidationPassword({
        isMatchPasssword,
        isLongChar,
        isSpecialChar,
        isUppercaseChar,
    });

    return (
        <Box className="reset-password__container">
            <Flex className="box-container">
                <Box className="left-section">{/* <ResetPasswordImageIllustration /> */}</Box>

                <Box className="right-section">
                    <Text className="title">Reset Password</Text>
                    <Text className="desc">Untuk meningkatkan keamanan akun, ganti password kamu sekarang</Text>

                    <Box className="input-container" mb="12px !important">
                        <Text className="label">Password baru</Text>
                        <Box position="relative">
                            <Input
                                data-testid="new-password-input__reset-password"
                                className="input"
                                name="password"
                                type={isOpenPassword ? 'text' : 'password'}
                                onChange={(e) => onChangeResetPasswordStore({ password: e.target.value })}
                            />
                            <ConditionalComponent statement={isOpenPassword}>
                                <OpenEyeIcon onClick={() => onChangeResetPasswordStore({ isOpenPassword: false })} />
                            </ConditionalComponent>
                            <ConditionalComponent statement={!isOpenPassword}>
                                <CloseEyeIcon onClick={() => onChangeResetPasswordStore({ isOpenPassword: true })} />
                            </ConditionalComponent>
                        </Box>
                    </Box>

                    <Box className="input-container" mb="12px !important">
                        <Text className="label">Konfirmasi password</Text>
                        <Box position="relative">
                            <Input
                                data-testid="confirm-password-input__reset-password"
                                className="input"
                                name="password"
                                type={isOpenPassword ? 'text' : 'password'}
                                onChange={(e) => onChangeResetPasswordStore({ confirmationPassword: e.target.value })}
                            />
                            <ConditionalComponent statement={isOpenPassword}>
                                <OpenEyeIcon onClick={() => onChangeResetPasswordStore({ isOpenPassword: false })} />
                            </ConditionalComponent>
                            <ConditionalComponent statement={!isOpenPassword}>
                                <CloseEyeIcon onClick={() => onChangeResetPasswordStore({ isOpenPassword: true })} />
                            </ConditionalComponent>
                        </Box>
                    </Box>

                    <ConditionalComponent statement={password}>
                        <Box className="validation-container">
                            <Flex className="validation-item">
                                <CheckIcon active={isLongChar} />
                                <Text className={`validation-text ${isLongChar && 'validation-text__actived'}`}>
                                    Minimal 8 karakter
                                </Text>
                            </Flex>
                        </Box>

                        <Box className="validation-container">
                            <Flex className="validation-item">
                                <CheckIcon active={isUppercaseChar} />
                                <Text className={`validation-text ${isUppercaseChar && 'validation-text__actived'}`}>
                                    Gunakan minimal 1 huruf kapital (Contoh A B C)
                                </Text>
                            </Flex>
                        </Box>

                        <Box className="validation-container">
                            <Flex className="validation-item">
                                <CheckIcon active={isSpecialChar} />
                                <Text className={`validation-text ${isSpecialChar && 'validation-text__actived'}`}>
                                    Gunakan 1 karakter khusus (Contoh: * - , !)
                                </Text>
                            </Flex>
                        </Box>
                        <Box className="validation-container" mb="12px !important">
                            <Flex className="validation-item">
                                <CheckIcon active={isMatchPasssword} />
                                <Text className={`validation-text ${isMatchPasssword && 'validation-text__actived'}`}>
                                    Password baru dan konfimasi password sama
                                </Text>
                            </Flex>
                        </Box>
                    </ConditionalComponent>

                    <Button
                        isLoading={isLoading}
                        className={`button-submit ${isValid && 'valid-button'}`}
                        disabled={!isValid}
                        onClick={() =>
                            onResetPasswordHandler({
                                password,
                                confirmationPassword,
                                onChangeSnackbarNotification,
                                navigate,
                                onChangeResetPasswordStore,
                                isValid,
                            })
                        }
                    >
                        Masuk
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
};

export default ResetPassword;
