import { Box } from '@chakra-ui/react';
import React, { useEffect, createContext } from 'react';

import Table from '@pages/AuditTrail/sections/table';
import { fetchAuditTrail } from '@pages/AuditTrail/helpers/crud';
import { useAuditTrailFilterStore, useAuditTrailStore } from '@pages/AuditTrail/helpers/mainState';
import Head from '@pages/AuditTrail/sections/head';

export const PageContext = createContext();

const AuditTrail = () => {
    const { onChangeAuditTrailStore, onResetAuditTrailStore, trails, isLoadingTable } = useAuditTrailStore();
    const filterAuditTrailState = useAuditTrailFilterStore();

    const refetchAuditTrail = () => fetchAuditTrail({ onChangeAuditTrailStore, filterAuditTrailState });

    useEffect(() => {
        refetchAuditTrail();
        // eslint-disable-next-line
    }, [filterAuditTrailState]);

    useEffect(() => {
        return () => {
            onResetAuditTrailStore();
            filterAuditTrailState?.onResetAuditTrailFilterSelectedStore();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <PageContext.Provider value={{ refetchAuditTrail }}>
            <Box className="audit-trail-container">
                <Head />
                <Table trails={trails} isLoadingTable={isLoadingTable} />
            </Box>
        </PageContext.Provider>
    );
};

export default AuditTrail;
