import { startCase } from 'lodash';
import { getTotal } from './function';

export const getArrDataHandler = ({ data }) => {
    return [
        {
            month: 'April',
            selloutWardah: {
                revenue: data.g_apr_w,
            },
            selloutEmina: { revenue: data.g_apr_e },
            selloutMakeOver: { revenue: data.g_apr_mo },
        },
        {
            month: 'Mei',
            selloutWardah: { revenue: data.g_may_w },
            selloutEmina: { revenue: data.g_may_e },
            selloutMakeOver: { revenue: data.g_may_mo },
        },
        {
            month: 'Juni',
            selloutWardah: { revenue: data.g_jun_w },
            selloutEmina: { revenue: data.g_jun_e },
            selloutMakeOver: { revenue: data.g_jun_mo },
        },
        {
            month: 'Juli',
            selloutWardah: { revenue: data.g_jul_w },
            selloutEmina: { revenue: data.g_jul_e },
            selloutMakeOver: { revenue: data.g_jul_mo },
        },
        {
            month: 'Agustus',
            selloutWardah: { revenue: data.g_aug_w },
            selloutEmina: { revenue: data.g_aug_e },
            selloutMakeOver: { revenue: data.g_aug_mo },
        },
        {
            month: 'September',
            selloutWardah: { revenue: data.g_sep_w },
            selloutEmina: { revenue: data.g_sep_e },
            selloutMakeOver: { revenue: data.g_sep_mo },
        },
        {
            month: 'Tahunan Semester 1',
            type: 'semester',
            selloutWardah: {
                revenue: getTotal({ totalType: 'semester1', brand: 'w', data, type: 'g' }),
            },
            selloutEmina: {
                revenue: getTotal({ totalType: 'semester1', brand: 'e', data, type: 'g' }),
            },
            selloutMakeOver: {
                revenue: getTotal({ totalType: 'semester1', brand: 'mo', data, type: 'g' }),
            },
        },
        {
            month: 'Oktober',
            selloutWardah: { revenue: data.g_oct_w },
            selloutEmina: { revenue: data.g_oct_e },
            selloutMakeOver: { revenue: data.g_oct_mo },
        },
        {
            month: 'November',
            selloutWardah: { revenue: data.g_nov_w },
            selloutEmina: { revenue: data.g_nov_e },
            selloutMakeOver: { revenue: data.g_nov_mo },
        },
        {
            month: 'Desember',
            selloutWardah: { revenue: data.g_dec_w },
            selloutEmina: { revenue: data.g_dec_e },
            selloutMakeOver: { revenue: data.g_dec_mo },
        },
        {
            month: 'Januari',
            selloutWardah: { revenue: data.g_jan_w },
            selloutEmina: { revenue: data.g_jan_e },
            selloutMakeOver: { revenue: data.g_jan_mo },
        },
        {
            month: 'Februari',
            selloutWardah: { revenue: data.g_feb_w },
            selloutEmina: { revenue: data.g_feb_e },
            selloutMakeOver: { revenue: data.g_feb_mo },
        },
        {
            month: 'Maret',
            selloutWardah: { revenue: data.g_mar_w },
            selloutEmina: { revenue: data.g_mar_e },
            selloutMakeOver: { revenue: data.g_mar_mo },
        },
        {
            month: 'Tahunan Semester 2',
            type: 'semester',

            selloutWardah: {
                return: getTotal({ totalType: 'semester2', brand: 'w', data, type: 'r' }),
                revenue: getTotal({ totalType: 'semester2', brand: 'w', data, type: 'g' }),
            },
            selloutEmina: {
                return: getTotal({ totalType: 'semester2', brand: 'e', data, type: 'r' }),
                revenue: getTotal({ totalType: 'semester2', brand: 'e', data, type: 'g' }),
            },
            selloutMakeOver: {
                return: getTotal({ totalType: 'semester2', brand: 'mo', data, type: 'r' }),
                revenue: getTotal({ totalType: 'semester2', brand: 'mo', data, type: 'g' }),
            },
        },
        {
            month: 'Tahunan',
            type: 'total',

            selloutWardah: {
                return: getTotal({ totalType: 'total', brand: 'w', data, type: 'r' }),
                revenue: getTotal({ totalType: 'total', brand: 'w', data, type: 'g' }),
            },
            selloutEmina: {
                return: getTotal({ totalType: 'total', brand: 'e', data, type: 'r' }),
                revenue: getTotal({ totalType: 'total', brand: 'e', data, type: 'g' }),
            },
            selloutMakeOver: {
                return: getTotal({ totalType: 'total', brand: 'mo', data, type: 'r' }),
                revenue: getTotal({ totalType: 'total', brand: 'mo', data, type: 'g' }),
            },
        },
    ];
};

export const getArrActualDataHandler = ({ data }) => {
    return [
        {
            name: `Target ${startCase(data?.selling_tag)}`,
            emina: {
                Semester1: getTotal({ totalType: 'semester1', brand: 'e', data, type: 't' }),
                Semester2: getTotal({ totalType: 'semester2', brand: 'e', data, type: 't' }),
                Tahunan: getTotal({ totalType: 'total', brand: 'e', data, type: 't' }),
            },
            wardah: {
                Semester1: getTotal({ totalType: 'semester1', brand: 'w', data, type: 't' }),
                Semester2: getTotal({ totalType: 'semester2', brand: 'w', data, type: 't' }),
                Tahunan: getTotal({ totalType: 'total', brand: 'w', data, type: 't' }),
            },
            makeover: {
                Semester1: getTotal({ totalType: 'semester1', brand: 'mo', data, type: 't' }),
                Semester2: getTotal({ totalType: 'semester2', brand: 'mo', data, type: 't' }),
                Tahunan: getTotal({ totalType: 'total', brand: 'mo', data, type: 't' }),
            },
        },
        {
            name: `Penjualan Sellout`,
            emina: { Semester1: data.a_s1_e, Semester2: data.a_s2_e, Tahunan: data.a_e },
            wardah: { Semester1: data.a_s1_w, Semester2: data.a_s2_w, Tahunan: data.a_w },
            makeover: { Semester1: data.a_s1_mo, Semester2: data.a_s2_mo, Tahunan: data.a_mo },
        },
        {
            name: `Penjualan Sellin`,
            emina: { Semester1: data.a_s1_e_sellin, Semester2: data.a_s2_e_sellin, Tahunan: data.a_e_sellin },
            wardah: { Semester1: data.a_s1_w_sellin, Semester2: data.a_s2_w_sellin, Tahunan: data.a_w_sellin },
            makeover: { Semester1: data.a_s1_mo_sellin, Semester2: data.a_s2_mo_sellin, Tahunan: data.a_mo_sellin },
        },
        {
            name: 'Pencapaian',
            type: '%',
            emina: { Semester1: data.a_s1_e_pct, Semester2: data.a_s2_e_pct, Tahunan: data.a_e_pct },
            wardah: { Semester1: data.a_s1_w_pct, Semester2: data.a_s2_w_pct, Tahunan: data.a_w_pct },
            makeover: { Semester1: data.a_s1_mo_pct, Semester2: data.a_s2_mo_pct, Tahunan: data.a_mo_pct },
        },
        {
            name: 'Rebate MOU %',
            type: '%',
            emina: { Semester1: data.reb_s1_e_pct, Semester2: data.reb_s2_e_pct, Tahunan: data.rebate_e },
            wardah: { Semester1: data.reb_s1_w_pct, Semester2: data.reb_s2_w_pct, Tahunan: data.rebate_w },
            makeover: { Semester1: data.reb_s1_mo_pct, Semester2: data.reb_s2_mo_pct, Tahunan: data.rebate_mo },
        },
        {
            name: 'Aktual Rebate %',
            type: '%',
            emina: { Semester1: data.reb_s1_e_pct, Semester2: data.reb_s2_e_pct, Tahunan: data.reb_period_e_pct },
            wardah: { Semester1: data.reb_s1_w_pct, Semester2: data.reb_s2_w_pct, Tahunan: data.reb_period_w_pct },
            makeover: { Semester1: data.reb_s1_mo_pct, Semester2: data.reb_s2_mo_pct, Tahunan: data.reb_period_mo_pct },
        },

        {
            name: 'Aktual Rebate (Rp)',
            emina: { Semester1: data.reb_s1_e, Semester2: data.reb_s2_e, Tahunan: data.reb_period_e },
            wardah: {
                Semester1: data.reb_s1_w,
                Semester2: data.reb_s2_w,
                Tahunan: data.reb_period_w,
            },
            makeover: { Semester1: data.reb_s1_mo, Semester2: data.reb_s2_mo, Tahunan: data.reb_period_mo },
        },
        {
            name: 'Start Date Sharing Data',
            wardah: data?.start_date_sharing,
            emina: 'empty',
            makeover: 'empty',
        },
        {
            name: 'Rebate Data Sharing',
            wardah: {
                Semester1: data?.sellout_data_insentif,
                Semester2: data?.sellout_data_insentif_second_semester,
                Tahunan: 'empty',
            },
            emina: 'empty',
            makeover: 'empty',
        },

        {
            name: 'Total Rebate S1',
            value:
                Number(data.reb_s1_w) -
                Number(data.sellout_data_insentif) -
                Number(data.tech_investment) +
                Number(data.reb_s1_e) +
                Number(data.reb_s1_mo),
            type: 'all',
        },
        {
            name: 'Total Rebate S2',
            type: 'all',
            value:
                Number(data.reb_s2_w) +
                Number(data.reb_period_w) +
                Number(data.add_reb_s1_w) +
                Number(data.add_reb_s2_w) +
                Number(data.reb_s2_e) +
                Number(data.reb_period_e) +
                Number(data.add_reb_s1_e) +
                Number(data.add_reb_s2_e) +
                Number(data.reb_s2_mo) +
                Number(data.reb_period_mo) +
                Number(data.add_reb_s1_mo) +
                Number(data.add_reb_s2_mo),
        },
        {
            name: 'Tech Invesment',
            type: 'all',
            value: data?.tech_investment,
        },
    ];
};
