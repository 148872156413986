import authAxios from '@services/authAxios';

const postSyncToExagonOutbound = async ({ id }) => {
    await authAxios({
        method: 'POST',
        url: `/api/v1/outbound/outbound-pipeline/${id}`,
    });
};

export default postSyncToExagonOutbound;
