//========================== LIBRARY ===========================

import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

//========================== LOCAL ASSETS ===========================

import { ReminderIllustration } from '@pages/UploadSellout/assets/illustration';

import './styles.scss';
// import {
//     CheckPointIcon,
//     ChevronLeftIcon,
//     ChevronRightIcon,
//     CurrentPointIcon,
//     NextPointIcon,
//     SkipPointIcon,
// } from '@views/uploadSelloutRevamp/assets/icons';

const Reminder = () => {
    const navigate = useNavigate();
    const elHistory = document.getElementById('history__upload-sellout');

    const scrollToHistory = () => window.scrollTo({ top: elHistory.clientHeight + 500, behavior: 'smooth' });

    return (
        <Flex className="reminder-container__upload-sellout">
            <ReminderIllustration />
            <Box className="reminder-information__container">
                <Text className="title">Yuk, rutin upload data.</Text>
                <Text className="description">
                    Lacak kelengkapan file sell-out kamu melalui <span onClick={scrollToHistory}>riwayat</span> dan{' '}
                    <span onClick={() => navigate('/audit-trail')}>audit trail</span>. Pastikan untuk upload data seluru
                    brand secara rutin sesuai komitmen ya. Terimakasih banyak atas kolaborasinya
                </Text>
                {/* <Box className="week-reminder__box"> */}
                {/* <Text className="date-text">Januari 2024</Text> */}

                {/* //========================= CHECKPOINT ICON ============== */}

                {/* <Box className="checkpoint-container">
                        <Box className="line" />
                        <Box className="circle-checkpoint">
                            <CheckPointIcon />
                        </Box>
                        <Box className="circle-checkpoint">
                            <SkipPointIcon />
                        </Box>
                        <Box className="circle-checkpoint">
                            <CurrentPointIcon />
                        </Box>
                        <Box className="circle-checkpoint">
                            <NextPointIcon />
                        </Box>
                    </Box> */}

                {/* //========================= WEEK TEXTTEXT ============== */}

                {/* <Box className="weeks">
                        <Box className="week-item">
                            <Text className="week">Minggu 1</Text>
                        </Box>
                        <Box className="week-item">
                            <Text className="week">Minggu 2</Text>
                        </Box>
                        <Box className="week-item">
                            <Text className="week">Minggu 3</Text>
                        </Box>
                        <Box className="week-item">
                            <Text className="week">Minggu 4</Text>
                        </Box>
                    </Box> */}

                {/* //========================= CHEVRON ============== */}
                {/* <Box className="arrow">
                        <Box className="arrow-left">
                            <ChevronLeftIcon />
                        </Box>
                        <Box className="arrow-right">
                            <ChevronRightIcon />
                        </Box>
                    </Box> */}
                {/* </Box> */}
            </Box>
        </Flex>
    );
};

export default Reminder;
