import { isArray, isObject } from 'lodash';
import moment from 'moment';
import Cookies from 'universal-cookie';

import Axios from '@services/axios';
import { redirectToPage } from '@helpers/events';

export const onLoginHandler = async ({
    username,
    password,
    onChangeSnackbarNotification,
    navigate,
    onChangeLoginPasswordStore,
    isValid,
}) => {
    //============================ NOT ALLOW, IF NOT VALID ========================

    if (!isValid) {
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Login Error',
            description: 'Harap isi semua field',
            duration: 2,
            detailMessage: '',
        });

        return;
    }

    onChangeLoginPasswordStore({ isLoading: true });

    try {
        //============================ LOGIN ========================

        const { data } = await Axios.post('/api/authenticate', {
            email: username,
            password: password,
            app_name: 'sagitarius',
        });

        //============================ SET NOTIF ========================

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Login berhasil',
            description: 'Berhasil masuk ke halaman dashboard',
            duration: 3,
        });

        //============================ SET COOKIES ========================

        const { token, name, roleName, menuAccess, isDefaultPassword } = data;

        const expirations = new Date(moment().add(1, 'days'));

        const cookies = new Cookies();

        cookies.set('userToken', token, { path: '/', expires: expirations });
        cookies.set('username', name, { path: '/', expires: expirations });
        cookies.set('role', roleName, { path: '/', expires: expirations });
        cookies.set('menuAccess', JSON.stringify(menuAccess), { path: '/', expires: expirations });
        cookies.set('isLogin', 'true', { path: '/', expires: expirations });

        //============================ NAVIGATE ========================

        if (isDefaultPassword) navigate('/reset-password');
        else redirectToPage({ menuAccess, navigate });

        onChangeLoginPasswordStore({ isLoading: false });
    } catch (err) {
        console.log(err);

        let detailMessage = null;

        const errorMessage = err?.response?.data?.message || 'Something wrong!';
        const isObjectError = isObject(errorMessage);

        if (isObjectError) {
            const { email } = errorMessage;
            detailMessage = [...email];
        }

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Login gagal',
            description: isArray(detailMessage) ? 'Something wrong' : errorMessage,
            detailMessage,
            duration: 5,
        });

        onChangeLoginPasswordStore({ isLoading: false });
    }
};
