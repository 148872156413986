import React from 'react';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { getArrDataHandler, getArrActualDataHandler } from './mapping';

import { isEmpty } from 'lodash';

export const getTotal = ({ totalType, type, brand, data }) => {
    switch (totalType) {
        case 'semester1':
            return (
                Number(data[`${type}_apr_${brand}`]) +
                Number(data[`${type}_may_${brand}`]) +
                Number(data[`${type}_jun_${brand}`]) +
                Number(data[`${type}_jul_${brand}`]) +
                Number(data[`${type}_aug_${brand}`]) +
                Number(data[`${type}_sep_${brand}`])
            );
        case 'semester2':
            return (
                Number(data[`${type}_oct_${brand}`]) +
                Number(data[`${type}_nov_${brand}`]) +
                Number(data[`${type}_dec_${brand}`]) +
                Number(data[`${type}_jan_${brand}`]) +
                Number(data[`${type}_feb_${brand}`]) +
                Number(data[`${type}_mar_${brand}`])
            );
        case 'total':
            return (
                Number(data[`${type}_apr_${brand}`]) +
                Number(data[`${type}_may_${brand}`]) +
                Number(data[`${type}_jun_${brand}`]) +
                Number(data[`${type}_jul_${brand}`]) +
                Number(data[`${type}_aug_${brand}`]) +
                Number(data[`${type}_sep_${brand}`]) +
                Number(data[`${type}_oct_${brand}`]) +
                Number(data[`${type}_nov_${brand}`]) +
                Number(data[`${type}_dec_${brand}`]) +
                Number(data[`${type}_jan_${brand}`]) +
                Number(data[`${type}_feb_${brand}`]) +
                Number(data[`${type}_mar_${brand}`])
            );
        default:
            break;
    }
};

export const currencyFormatted = ({ value }) => {
    try {
        const flooringCurrency = Math.floor(value);
        return flooringCurrency?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') || '-';
    } catch (error) {
        console.log(error);
    }
};

export const fetchAchievementHandler = async ({ setData, setActualData, setIsLoading }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const actualArr = [
            'Target Sellout',
            'Penjualan Sell In(Rp)',
            'Pencapain Percent',
            'Rebate MOU %',
            'Aktual Rebate %',
            'Aktual Rebate (Rp)',
        ];

        setIsLoading(true);

        let { data } = await Axios({
            method: 'POST',
            url: '/api/v1/user/achievement',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        if (isEmpty(data?.data)) {
            setData([]);
            setActualData([]);
        } else {
            const createArrData = getArrDataHandler({ data: data?.data });

            setData(createArrData);

            const createArrActualData = getArrActualDataHandler({ data: data?.data, actualArr });

            setActualData(createArrActualData);
        }
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
};
