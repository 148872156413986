import { Flex, useDisclosure } from '@chakra-ui/react';
import styles from './styles.module.css';
import { InformationBlue24Icon } from '@assets/icons/index';
import { TextRegular } from '@components/typography';
import { CloseIcon } from '@chakra-ui/icons';
import { ConditionalComponent } from '@components/functionComponent';

const Alert = () => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    return (
        <ConditionalComponent statement={isOpen} fallback={<></>}>
            <Flex className={styles['alert__container']}>
                <Flex alignItems="center" gap="12px">
                    <InformationBlue24Icon />
                    <TextRegular className={styles['alert__text']}>
                        Seluruh angka di halaman ini menggunakan Harga Eceran Tertinggi (HET). Halaman ini belum
                        terhubung dengan <span>Pencapaian</span> yang menggunakan nilai NETT.
                    </TextRegular>
                </Flex>

                <CloseIcon color="var(--color-semantic-text)" fontSize="10px" cursor="pointer" onClick={onClose} />
            </Flex>
        </ConditionalComponent>
    );
};

export default Alert;
