import React from 'react';
import { Text, Button, Box } from '@chakra-ui/react';
import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { ConditionalComponent } from '@components/functionComponent';
import { find, get } from 'lodash';
import FloatingMenu from '@components/floatingMenu';
import { usePopUp } from '@store/popup';
import { onRemoveUser } from '@pages/UserManagement/helpers/crud';
import { fetchUserManagement } from '@pages/UserManagement/helpers/crud';
import { useUserManagementStore } from '@pages/UserManagement/helpers/mainState';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { useNavigate } from 'react-router-dom';

export const IconAccessColumn = ({ value, type }) => {
    const isAccess = get(find(value, { link: type }), 'link');

    return (
        <Text className="column-text icon-access__container">
            <ConditionalComponent statement={isAccess} fallback={<NotAllowedIcon color="red.500" boxSize={4} />}>
                <CheckCircleIcon color="green.500" boxSize={4} />
            </ConditionalComponent>
        </Text>
    );
};

export const ActionsComponent = ({ values }) => {
    const navigate = useNavigate();
    const { onChangePopUp, onResetPopUp } = usePopUp();
    const { onChangeUserManagementStore } = useUserManagementStore();
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    const refetchUserManagement = () => {
        fetchUserManagement({ onChangeUserManagementStore, page: 1 });
    };
    const actionList = [
        <Box className="action-menu" onClick={() => navigate(`/user-management/edit/${values?.id}/${values?.email}`)}>
            <Text> Edit</Text>
        </Box>,
        <Box
            className="action-menu"
            onClick={() =>
                onChangePopUp({
                    status: 'delete',
                    title: 'Apakah anda yakin?',
                    description: 'Data yang sudah dihapus tidak akan dapat dipulihkan kembali',
                    onSubmit: () =>
                        onRemoveUser({
                            id: values?.id,
                            refetchUserManagement,
                            onChangeSnackbarNotification,
                            onChangePopUp,
                            onResetPopUp,
                            onChangeUserManagementStore,
                        }),
                })
            }
        >
            <Text> Remove</Text>
        </Box>,
    ];

    return (
        <ConditionalComponent statement={values?.can_role_edited}>
            <FloatingMenu
                className="dotted-icon"
                isClickMenu={true}
                list={actionList}
                buttonElement={<Button className="action-button">Actions</Button>}
            />
        </ConditionalComponent>
    );
};

export const TextColumn = ({ value }) => <Text className="column-text">{value}</Text>;
