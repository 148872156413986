import { Input, Box, Text, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Search2Icon } from '@chakra-ui/icons';
import { find, isEmpty } from 'lodash';

import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import FloatingInfo from '@components/floatingInfo';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { ChevronDownThickIcon, CloseIcon } from '@assets/icons';
import { getSelectedFilterName } from './helpers/utils';
import { initialState, dummyFilterList } from './const';
import { updateSelectedFilter, searchQuery } from './helpers/events';
import './styles.scss';

const FilterDropdown = ({
    placeholder,
    options,
    maxFilter,
    onFetchData,
    isLoading,
    filterId,
    onSelect,
    alwaysShow,
    isMultiple = true,
    currentValue,
}) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const [state, setState] = useState(initialState);
    const { isOpen, selectedFilter, filterList, query, onInputMode } = state;

    const showSelectedFilter = getSelectedFilterName({ selectedFilter });
    const onChangeState = (newState) => setState({ ...state, ...newState });
    const onSearch = (query) => searchQuery({ query, onChangeState, options });

    const onUpdateSelectedFilter = ({ selectedFilter, item }) =>
        updateSelectedFilter({
            selectedFilter,
            item,
            onChangeSnackbarNotification,
            maxFilter,
            onChangeState,
            onSelect,
            options,
            isMultiple,
        });

    const onResetFilter = () => {
        onChangeState({ ...initialState, filterList: options });
        onSelect({ selected: [] });
    };
    useEffect(() => {
        if (options?.length >= 0) {
            if (isEmpty(currentValue)) onChangeState({ filterList: [...options] });
            else onChangeState({ filterList: [...options], selectedFilter: currentValue });
        }
        // eslint-disable-next-line
    }, [options]);

    useEffect(() => {
        if (isOpen && onFetchData) onFetchData();
        // eslint-disable-next-line
    }, [isOpen]);

    return (
        <OutsideClickHandler onOutsideClick={() => onChangeState({ isOpen: false, query: '' })}>
            <Box className="filter-dropdown__container" id={filterId}>
                <FloatingInfo label={showSelectedFilter} position={'top'}>
                    {/* //====================FILTER BUTTOn =============== */}

                    <Box className={`filter-button ${isOpen && 'filter-button__actived'}`}>
                        <Box onClick={() => onChangeState({ isOpen: true })}>
                            {/* //====================SEARCH INPUT =============== */}
                            <ConditionalComponent statement={selectedFilter?.length}>
                                <Text className="filter-selected__number">{selectedFilter?.length}</Text>
                            </ConditionalComponent>
                            <Input
                                paddingLeft={selectedFilter?.length > 0 ? '38px !important' : '16px !important'}
                                className={selectedFilter?.length && 'input-container__selectedfilter'}
                                placeholder={isOpen ? 'Search...' : placeholder}
                                onChange={(e) => onSearch(e.target.value)}
                                value={query}
                                onFocus={() => onChangeState({ onInputMode: true })}
                                onBlur={() => onChangeState({ onInputMode: false })}
                                pointerEvents={onInputMode ? 'none' : 'pointer'}
                            />

                            {/* //==================== CHEVRONDOWN ICON =============== */}
                            <ConditionalComponent statement={selectedFilter?.length === 0 && !onInputMode}>
                                <Box className="icon-container">
                                    <ChevronDownThickIcon color="grey" />
                                </Box>
                            </ConditionalComponent>

                            {/* //==================== SEARCH ICON =============== */}

                            <ConditionalComponent statement={onInputMode}>
                                <Box className="icon-container">
                                    <Search2Icon className="search-icon" color="grey" />
                                </Box>
                            </ConditionalComponent>
                        </Box>

                        {/* //==================== SELECTED INDICATOR =============== */}

                        {/* //==================== RESET ICON =============== */}

                        <ConditionalComponent statement={selectedFilter?.length && !onInputMode}>
                            <Box className="icon-container" cursor="pointer">
                                <CloseIcon color="grey" onClick={onResetFilter} />
                            </Box>
                        </ConditionalComponent>
                    </Box>
                </FloatingInfo>

                {/* //==================== FILTER LIST =============== */}

                <ConditionalComponent statement={isOpen || alwaysShow}>
                    <Box className={`filter-list filter-list__actived`}>
                        <IterationComponent
                            arr={isLoading ? dummyFilterList : filterList}
                            render={(item) => (
                                <Box
                                    key={item?.id}
                                    className={`filter-item ${
                                        find(selectedFilter, { id: item?.id }) && 'filter-selected'
                                    } `}
                                    onClick={() =>
                                        onUpdateSelectedFilter({ selectedFilter: [...selectedFilter], item })
                                    }
                                >
                                    {/* //==================== SKELETON LIST =============== */}

                                    <ConditionalComponent statement={isLoading}>
                                        <Skeleton
                                            h="18px"
                                            w="100%"
                                            borderRadius="6px"
                                            startColor="var(--main-color-light-active)"
                                            endColor={`rgba(var(--main-color-light-active),0.5)`}
                                        />
                                    </ConditionalComponent>

                                    {/* //==================== FILTER ITEM =============== */}

                                    <ConditionalComponent statement={!isLoading}>
                                        <Text className="filter-text">{item?.name}</Text>
                                        <Box className="circle-active" />
                                    </ConditionalComponent>
                                </Box>
                            )}
                        />
                    </Box>
                </ConditionalComponent>
            </Box>
        </OutsideClickHandler>
    );
};

export default FilterDropdown;
