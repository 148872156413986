import { Box, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { useDownloadNotification } from '@store/downloadNotification';
import { ConditionalComponent } from '@components/functionComponent';
import { useDragFile } from '@store/dragFile';
import { onDownloadTemplateFile, onDownloadUsermanagement } from '@pages/UserManagement/helpers/crud';

const ImportButton = () => {
    const { onChangeDragFile } = useDragFile();

    return (
        <>
            <Box className="list-button__usermanagement" onClick={() => onChangeDragFile({ isDragFile: true })}>
                <Text>Import</Text>
            </Box>
        </>
    );
};

const ExportButton = () => {
    const { onChangeDownloadNotification, status } = useDownloadNotification();

    return (
        <Box
            className={`list-button__usermanagement ${status === 'download' && 'ondownload-process'}`}
            onClick={() => onDownloadUsermanagement({ onChangeDownloadNotification, status })}
        >
            <Text>Export</Text>
            <ConditionalComponent statement={status === 'download'}>
                <Spinner color="var(--main-color)" size="sm" />
            </ConditionalComponent>
        </Box>
    );
};

const TemplateUpload = () => {
    const { onChangeDownloadNotification, status } = useDownloadNotification();

    return (
        <Box
            className={`list-button__usermanagement ${status === 'download-template' && 'ondownload-process'}`}
            onClick={() => onDownloadTemplateFile({ onChangeDownloadNotification, status })}
        >
            <Text>Template Upload</Text>
            <ConditionalComponent statement={status === 'download-template'}>
                <Spinner color="var(--main-color)" size="sm" />
            </ConditionalComponent>
        </Box>
    );
};
export const listHeadMenu = [<ImportButton />, <ExportButton />, <TemplateUpload />];
