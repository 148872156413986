import Cookies from 'universal-cookie';
import { find } from 'lodash';

export const fetchGlobalStyles = (environment) => {
    environment === 'development' ? require('../@styles/development-styles') : require('../@styles/production-styles');

    return environment;
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const getToken = () => {
    const cookies = new Cookies();

    const tokenCookies = cookies.get('userToken');

    return tokenCookies;
};

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
        .format(value)
        ?.replace(/\s/g, '');
};
