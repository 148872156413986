export const initialFilterState = {
    salesAreaSearch: '',
    storeSearch: '',
    dcSearch: '',
    groupStoreSearch: '',
    brandSearch: '',
    brands: [
        'Wardah',
        'Emina',
        'Make Over',
        'OMG',
        'Kahf',
        'Instaperfect',
        'Tavi',
        'Labore',
        'Biodef',
        'Putri',
        'Crystallure',
    ],
    dc: [],
    salesAreas: [],
    stores: [],
    groupStores: [],
    enabledSalesArea: false,
    enabledStore: false,
    enabledDC: false,
    enabledBrand: false,
    enabledGroupStore: false,
};

export const emptyFilterState = {
    salesAreaSearch: '',
    storeSearch: '',
    dcSearch: '',
    groupStoreSearch: '',
    brandSearch: '',
    brands: [],
    dc: [],
    salesAreas: [],
    stores: [],
    groupStores: [],
    enabledSalesArea: false,
    enabledStore: false,
    enabledDC: false,
    enabledBrand: false,
    enabledGroupStore: false,
};
