import { Flex, Box } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold, TextRegular } from '@components/typography';
import { SyncIcon } from '@assets/icons/index';
import ButtonOutline from '@components/buttons/buttonOutline';
import { ConditionalComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';

const SyncComponent = ({ label, description, isLoading, onOpen }) => {
    return (
        <Flex className={styles['sync-component__container']}>
            <Box>
                <TextBold className={styles['label__text']}>{label}</TextBold>
                <TextRegular className={styles['description__text']}>{description}</TextRegular>
            </Box>

            <ConditionalComponent statement={!isLoading} fallback={<SkeletonComponent height="36px" width="80px" />}>
                <ButtonOutline
                    className={styles['sync__button']}
                    color="var(--color-primary)"
                    hoverColor={'var(--color-primary70)'}
                    onClick={onOpen}
                    component={
                        <Flex gap="8px">
                            <SyncIcon />
                            <TextBold className={styles['sync__text']}>Sync</TextBold>
                        </Flex>
                    }
                />
            </ConditionalComponent>
        </Flex>
    );
};

export default SyncComponent;
