import moment from 'moment';
import { create } from 'zustand';

const state = {
    trendSellin: true,
    trendSellout: true,
    selectedYear: moment().format('YYYY'),
};

export const useTrends = create((set) => ({
    ...state,
    setTrends: (newState) => set((state) => ({ ...state, ...newState })),
    resetTrends: () => set(() => ({ ...state })),
}));
