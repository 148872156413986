import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import React from 'react';

const ModalTemplate = ({ isOpen, onClose, children, width, modalContentCustomStyle, borderRadius = '12px' }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
                background={'rgba(0,0,0,0.7)'}
                onClick={onClose}
                data-testid="modal-overlay__modal-template"
            />
            <ModalContent
                style={{ ...modalContentCustomStyle }}
                width={width}
                maxWidth={'none'}
                borderRadius={borderRadius}
            >
                {children}
            </ModalContent>
        </Modal>
    );
};

export default ModalTemplate;
