import errorResponseHandler from '@data/helpers/errorResponseHandler';
import authAxios from '@services/authAxios';

const fetchSalesAreas = async ({ queries }) => {
    try {
        const { data } = await authAxios({
            method: 'GET',
            url: `/api/v1/sales-area/list?${queries}`,
        });

        return data?.data;
    } catch (error) {
        const errorMessage = errorResponseHandler(error);
        throw new Error(errorMessage);
    }
};

export default fetchSalesAreas;
