import getStoresRequest from '@domain/model/request/store/getStoresRequest';
import getStoresResponse from '@domain/model/response/store/getStoresResponse';
import getStores from '@hooks/store/getStores';

const useGetStores = ({ enabled, search }) => {
    const queries = getStoresRequest({ search });
    const responses = getStores({ enabled, queries });
    const mappingResult = getStoresResponse(responses?.data);
    return { ...responses, data: mappingResult };
};

export default useGetStores;
