import { Text } from '@chakra-ui/react';
import { BadgeStatus } from '@pages/UploadSellout/components/otherColumns';
import React from 'react';

export const tableList = [
    {
        name: 'Date Time',
        width: 150,
        value: 'date_uploaded',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'Uploader',
        width: 180,
        value: 'name',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'Sellout Type',
        width: 180,
        value: 'type_date',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'Upload Type',
        width: 220,
        value: 'upload_type',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'Status',
        width: 180,
        value: 'status',
        component: ({ value }) => {
            return <BadgeStatus status={value} />;
        },
    },
];
