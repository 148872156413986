import { upperCase } from 'lodash';

export const getShortUsername = (name) => {
    let result = '';

    const spiltUsername = name.split(' ');
    if (spiltUsername?.length > 0) result = upperCase(spiltUsername[0][0]);
    if (spiltUsername?.length > 1) result += upperCase(spiltUsername[1][0]);

    return result;
};
