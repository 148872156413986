export const chartOptions = {
    interaction: {
        mode: 'index',
        intersect: false,
    },
    tickWidth: 1,
    events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
    hover: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            offset: true,
            grid: {
                color: '#EBEBEB',
            },
            ticks: {
                color: '#2A3D4A',
            },
            title: {
                color: '#2A3D4A',
                display: true,
                text: 'Bulan',
                font: {
                    size: 12,
                    family: 'Lato',
                    weight: 600,
                },
                padding: {
                    top: 8,
                },
            },
        },
    },
    plugins: {
        legend: {
            display: false,
            labels: {},
        },
    },
};
