const getTemplateUploadResponse = (response) => {
    if (!response) return [];

    return [
        ...response,
        {
            id: 'Custom',
            label: 'Custom',
            value: 'Custom',
            name: 'Custom',
        },
    ];
};

export default getTemplateUploadResponse;
