import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import SkeletonComponent from '@components/skeleton';
import '../styles.scss';
import { IterationComponent } from '@components/functionComponent';

const BusinessSisoSkeleton = () => {
    return (
        <Box className="business-siso__skeleton">
            {/* OVERVIEW */}

            <Flex className="box-section" justifyContent="space-between" alignItems="center">
                <Box>
                    <SkeletonComponent height="36px" width="480px" mb="8px" />
                    <Flex gap="8px" alignItems="center">
                        <SkeletonComponent height="16px" width="16px" />
                        <SkeletonComponent height="22px" width="560px" />
                    </Flex>
                </Box>

                <SkeletonComponent height="40px" width="90px" />
            </Flex>

            {/* STATISTIC */}

            <Box className="box-section" justifyContent="space-between" alignItems="center">
                <Flex
                    height="44px"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    paddingBottom="24px"
                    borderBottom="1px solid var(--color-neutral10)"
                    mb="24px"
                >
                    <Flex alignItems="center" gap="12px">
                        <SkeletonComponent height="22px" width="276px" />
                        <SkeletonComponent height="16px" width="16px" />
                    </Flex>

                    <Flex alignItems="center" gap="12px">
                        <SkeletonComponent height="20px" width="40px" />
                        <SkeletonComponent height="36px" width="104px" />
                        <SkeletonComponent height="20px" width="40px" />
                    </Flex>
                </Flex>

                <Flex>
                    <Box flex={1} borderRight="1px solid var(--color-neutral10)">
                        <SkeletonComponent width="120px" height="22px" mb="12px" />
                        <Flex>
                            <SkeletonComponent width="268px" height="45px" mr="12px" />
                            <Box>
                                <SkeletonComponent width="56px" height="20px" mb="2px" />
                                <SkeletonComponent width="108px" height="22px" />
                            </Box>
                        </Flex>
                    </Box>

                    <Box flex={1} marginLeft="32px">
                        <SkeletonComponent width="120px" height="22px" mb="12px" />
                        <Flex>
                            <SkeletonComponent width="268px" height="45px" mr="12px" />
                            <Box>
                                <SkeletonComponent width="56px" height="20px" mb="2px" />
                                <SkeletonComponent width="108px" height="22px" />
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>

            {/* TREND */}

            <Box className="box-section" justifyContent="space-between" alignItems="center">
                <Flex
                    height="44px"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    paddingBottom="24px"
                    borderBottom="1px solid var(--color-neutral10)"
                    mb="24px"
                >
                    <Flex alignItems="center" gap="12px">
                        <SkeletonComponent height="22px" width="298px" />
                        <SkeletonComponent height="16px" width="16px" />
                    </Flex>

                    <Flex alignItems="center" gap="12px">
                        <SkeletonComponent height="20px" width="116px" />
                    </Flex>
                </Flex>
                <Flex justifyContent="center" gap="24px" mb="24px">
                    <SkeletonComponent width="63px" height="20px" />
                    <SkeletonComponent width="63px" height="20px" />
                </Flex>
                <Flex alignItems="center" mb="8px">
                    <SkeletonComponent width="17px" height="110px" mr="8px" />
                    <Flex flexDirection="column" justifyContent="space-between" height="200px">
                        <SkeletonComponent width="28px" height="15px" />
                        <SkeletonComponent width="28px" height="15px" />
                        <SkeletonComponent width="28px" height="15px" />
                        <SkeletonComponent width="28px" height="15px" />
                        <SkeletonComponent width="28px" height="15px" />
                        <SkeletonComponent width="28px" height="15px" />
                        <SkeletonComponent width="28px" height="15px" />
                    </Flex>
                    <Box ml="18px" flex={1} padding="6px 0">
                        <IterationComponent
                            arr={[1, 2, 3, 4, 5, 6]}
                            render={() => (
                                <Table>
                                    <Tr>
                                        <IterationComponent
                                            arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                                            render={() => <Td border="1px solid var(--color-neutral10)"></Td>}
                                        />
                                    </Tr>
                                </Table>
                            )}
                        />
                    </Box>
                </Flex>
                <Flex pl="72px" width="100%">
                    <Flex padding="0 24px" justifyContent="space-between" flex={1}>
                        <IterationComponent
                            arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                            render={() => <SkeletonComponent width="50px" height="15px" />}
                        />
                    </Flex>
                </Flex>
            </Box>

            {/* TABLE */}
            <Box className="box-section" justifyContent="space-between" alignItems="center">
                <Flex
                    height="44px"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    pb="24px"
                    mb="24px"
                    borderBottom="1px solid var(--color-neutral10)"
                >
                    <Flex gap="12px" alignItems="center">
                        <SkeletonComponent width={'362px'} height={'22px'} />
                        <SkeletonComponent width={'16px'} height={'16px'} />
                    </Flex>
                    <Flex alignItems="center" gap="12px">
                        <SkeletonComponent height="20px" width="40px" />
                        <SkeletonComponent height="36px" width="104px" />
                        <SkeletonComponent height="20px" width="40px" />
                        <SkeletonComponent height="40px" width="132px" ml="32px" />
                    </Flex>
                </Flex>
                <Table>
                    <Thead>
                        <Tr className="row-head__container row-skeleton">
                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'40px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px" float="right">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'40px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px" float="right">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px" float="right">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px" float="right">
                                    <SkeletonComponent width={'40px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px" float="right">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <IterationComponent
                            arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            render={(_, index) => (
                                <Tr className="row-column__container row-skeleton" key={index}>
                                    <Td>
                                        <SkeletonComponent width={'68px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent
                                            float="right"
                                            width={'100%'}
                                            maxWidth={'144px'}
                                            height={'20px'}
                                        />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'64px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent
                                            float="right"
                                            width={'100%'}
                                            maxWidth={'144px'}
                                            height={'20px'}
                                        />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent
                                            float="right"
                                            width={'100%'}
                                            maxWidth={'144px'}
                                            height={'20px'}
                                        />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent
                                            float="right"
                                            width={'100%'}
                                            maxWidth={'144px'}
                                            height={'20px'}
                                        />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent
                                            float="right"
                                            width={'100%'}
                                            maxWidth={'144px'}
                                            height={'20px'}
                                        />
                                    </Td>
                                </Tr>
                            )}
                        />
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default BusinessSisoSkeleton;
