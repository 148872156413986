import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import styles from './styles.module.css';
import { SagitariusLogo } from '@assets/logo';
import { ErrorState401Image } from '@assets/images';
import { TextBold, TextRegular } from '@components/typography';
import { useNavigate } from 'react-router-dom';

const ErrorState = ({ title, description }) => {
    const navigate = useNavigate();

    return (
        <Flex className={styles['error-page-content__container']}>
            <SagitariusLogo />
            <ErrorState401Image />
            <TextBold className={styles['error-page__title']}>{title}</TextBold>
            <TextRegular className={styles['error-page__description']}>{description}</TextRegular>
            <Button className={styles['go-to-home__button']} onClick={() => navigate('/')}>
                Kembali ke Beranda
            </Button>
        </Flex>
    );
};

export default ErrorState;
