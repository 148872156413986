import { isEmpty, sum } from 'lodash';

const getSisoTableResponse = (data) => {
    if (isEmpty(data)) return;

    let { lines, summary } = data;

    lines = lines?.map((item) => {
        return {
            ...item,
            mtd_sellin_number: item?.sellin_status === 'Negative' ? Number(`-${item?.sellin_mtd}`) : item?.sellin_mtd,
            mtd_sellout_number:
                item?.sellout_status === 'Negative' ? Number(`-${item?.sellout_mtd}`) : item?.sellout_mtd,
        };
    });

    if (isEmpty(lines)) return [];

    return [...lines, { ...summary, brand_name: 'Grand Total' }];
};

export default getSisoTableResponse;
