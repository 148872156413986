export const dummyReplenishmentProgressList = [
    { status: 'Inprogress', name: 'Created Task' },
    { status: 'Todo', name: 'Calculation Inventory Data from iSeller' },
    { status: 'Todo', name: 'Comparing with Exagon Product List' },
    { status: 'Todo', name: 'Updating Stock in Exagon ' },
    { status: 'Todo', name: 'Successed Task' },
];

export const dummyOutboundProgressList = [
    { status: 'Inprogress', name: 'Created Task' },
    { status: 'Todo', name: 'Get Sellout Data From iSeller In Database' },
    {
        status: 'Todo',
        name: 'Comparing With Exagon Product List',
    },
    {
        status: 'Todo',
        name: 'Updating Outbound in Exagon ',
    },
    {
        status: 'Todo',
        name: 'Successed Task',
    },
];
