import React from 'react';
import { Flex } from '@chakra-ui/react';
import { EmptyStateImage } from '@assets/images';
import { TextBold, TextRegular } from '@components/typography';

import './styles.scss';

const EmptyState = ({ text, description, emptyStateBottomComponent }) => (
    <Flex className="empty-state-global__container">
        <EmptyStateImage />
        <TextBold className="empty-state__text">{text}</TextBold>
        <TextRegular className="empty-state__desc">{description}</TextRegular>
        {emptyStateBottomComponent}
    </Flex>
);

export default EmptyState;
