import getSalesAreasRequest from '@domain/model/request/salesArea/getSalesAreasRequest';
import getStoreGroupRequest from '@domain/model/request/storeGroupRequest/getStoreGroupRequest';
import getGroupStoresResponse from '@domain/model/response/groupStore/getGroupStoresResponse';
import getGroupStores from '@hooks/groupStore/getGroupStores';

const useGetGroupStores = ({ enabled, search }) => {
    const queries = getStoreGroupRequest({ search });
    const responses = getGroupStores({ enabled, queries });
    const mappingResult = getGroupStoresResponse(responses?.data);

    return { ...responses, data: mappingResult };
};

export default useGetGroupStores;
