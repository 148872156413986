import React, { useEffect, useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/layout';
import SectionTable from './sections/sectionTable';
import { tableList, tableActual } from './const';

import { fetchAchievementHandler } from './function';

import { Spinner } from '@chakra-ui/react';
import './style.scss';
import ErrorState from '@components/errorState';

export const AchievementContext = React.createContext();

const Achievement = () => {
    const [data, setData] = useState([]);
    const [actualData, setActualData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAchievementHandler({ setData, setActualData, setIsLoading });
    }, []);

    return (
        <Box className={`achievement-dashboard__wrapper`}>
            {!isLoading && data?.length === 0 && actualData?.length === 0 ? (
                <ErrorState
                    type="content"
                    title="Halaman dalam proses validasi"
                    description="Saat ini, Pencapaian kamu sedang dalam proses pengerjaan dan validasi. Kami akan memberikan pembaruan mengenai penyelesaian validasi dalam beberapa waktu ke depan. "
                />
            ) : (
                <Box className={`achievement__container `}>
                    {!isLoading && data?.length > 0 && (
                        <>
                            <SectionTable data={data} tableList={tableList} />
                        </>
                    )}
                    {!isLoading && actualData?.length > 0 && (
                        <>
                            <Text className="title" mt="24px" textAlign="left" w="100%">
                                Pencapaian PSF 2023
                            </Text>
                            <SectionTable data={actualData} tableList={tableActual} />
                        </>
                    )}
                    {isLoading && (
                        <Box className="spinner-container" data-testId="spinner-achievement">
                            <Spinner color="var(--main-color)" />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default Achievement;
