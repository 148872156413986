export const getStatusDotBackground = (status) => {
    switch (status) {
        case 'Inprogress':
            return 'var(--color-primary)';
        case 'Failed':
            return 'var(--color-danger50)';
        case 'Success':
            return 'var(--color-success50)';
    }
};
