import { onDownloadFile } from '@pages/StockTrail/helpers/crud';

//==================================== ON DOWNLOAD FILE ==================================

export const onClickDownloadFile = ({ onChangePopUp, onChangeDownloadNotification, values }) => {
    onChangePopUp({
        status: 'confirmation',
        title: 'Mulai Proses Data',
        description: 'Apakah anda akan Download saja atau mengubah status ke Processing',
        cancelOptions: {
            name: 'Download saja',
        },
        submitOptions: {
            name: 'Download & proses',
        },
        onSubmit: async () => {
            onDownloadFile({
                type: `original-file`,
                path: values?.original_filename,
                onChangeDownloadNotification,
            });
        },
        onClose: () => {},
    });
};
