import { QuestionBlackIcon, UploadWhiteIcon } from '@assets/icons/index';
import { Flex } from '@chakra-ui/react';
import ButtonOutline from '@components/buttons/buttonOutline';
import ButtonRegular from '@components/buttons/buttonRegular';
import { TextBold } from '@components/typography';
import { useNavigate } from 'react-router-dom';

const EmptyStateBottomComponent = () => {
    const navigate = useNavigate();

    return (
        <Flex gap="16px" mt="24px">
            <ButtonRegular
                onClick={() => navigate('/upload-data')}
                color="var(--color-primary)"
                component={
                    <Flex className="empty-state-button-regular__content">
                        <UploadWhiteIcon />
                        <TextBold className="empty-state-upload__text">Upload Data</TextBold>
                    </Flex>
                }
            />

            <ButtonOutline
                color="var(--color-border-grey1)"
                component={
                    <Flex
                        className="empty-state-button-regular__content"
                        onClick={() =>
                            window.open(
                                'https://paracorpgroup-my.sharepoint.com/:w:/p/muhammad_adobson/EVFbScHA86FBgUFeyC_Lb_8BWEfMMXupOiLMTf-MDq3ZJg?e=HVmhxH&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIxNDE1LzI0MTAyMDAxMzE4IiwiSGFzRmVkZXJhdGVkVXNlciI6ZmFsc2V9',
                                '_blank'
                            )
                        }
                    >
                        <QuestionBlackIcon />
                        <TextBold className="empty-state-help__text">Bantuan</TextBold>
                    </Flex>
                }
            />
        </Flex>
    );
};
export default EmptyStateBottomComponent;
