import React from 'react';

export const PlayIcon = ({ onClick }) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 12 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="play-icon-sync-to-exagon-data"
    >
        <path
            d="M16.2111 11.1056L9.73666 7.86833C8.93878 7.46939 8 8.04958 8 8.94164V15.0584C8 15.9504 8.93878 16.5306 9.73666 16.1317L16.2111 12.8944C16.9482 12.5259 16.9482 11.4741 16.2111 11.1056Z"
            stroke="var(--main-color)"
            stroke-width="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
