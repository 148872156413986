import React from 'react';
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { ErrorStateImage } from '@assets/images';
import { TextBold, TextRegular } from '@components/typography';
import ButtonOutline from '@components/buttons/buttonOutline';
import './styles.scss';
import ButtonRegular from '@components/buttons/buttonRegular';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CloseRedIcon, CopyIcon, RedoIcon } from '@assets/icons/index';
import { ConditionalComponent } from '@components/functionComponent';
import { onLogoutHandler } from '@components/header/helpers/events';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { useLoader } from '@store/loader';
import { upperFirst } from 'lodash';
import { SagitariusLogo } from '@assets/logo';
import { useGetError } from './error';

const ErrorPage = ({ error }) => {
    const navigate = useNavigate();
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const { type, image, title, description } = useGetError(error);
    const { onChangeLoader } = useLoader();

    const { isOpen, onClose, onOpen } = useDisclosure();

    const copyToClipboard = () => {
        const message = error?.error?.stack ? upperFirst(error?.error?.stack) : upperFirst(error?.error?.message);
        copy(message);

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Berhasil',
            description: `Text berhasil di copy ke clipboard`,
            duration: 3,
        });
    };

    return (
        <Box className="error-page__container">
            <SagitariusLogo />
            {image}
            <TextBold className="error-page__title">{title}</TextBold>
            <TextRegular className="error-page__description">{description}</TextRegular>

            <ConditionalComponent statement={type === 'javascript'}>
                <Flex gap="12px" marginBottom="32px">
                    <ButtonOutline
                        onClick={() =>
                            onLogoutHandler({
                                navigate,
                                onChangeSnackbarNotification,
                                onChangeLoader,
                                withReload: true,
                            })
                        }
                        width="120px"
                        height="42px"
                        color="var(--color-primary)"
                        component={<TextBold className="button-logout__text">Logout</TextBold>}
                    />

                    <ButtonRegular
                        onClick={() => window.location.reload()}
                        width="120px"
                        height="42px"
                        color="var(--color-primary)"
                        component={<TextBold className="button-reload__text">Muat Ulang</TextBold>}
                    />
                </Flex>

                <Box className="box-info__container">
                    <Flex className="toggle__button" onClick={isOpen ? onClose : onOpen}>
                        <TextBold>Lihat Detail Kesalahan</TextBold>
                        <Box transform={`rotate(${isOpen ? '180deg' : '0deg'})`}>
                            <ChevronDownIcon color="var(--color-primary)" />
                        </Box>
                    </Flex>
                    <ConditionalComponent statement={isOpen}>
                        <Flex className="info__container">
                            <Flex gap="8px" flex={1}>
                                <CloseRedIcon />
                                <TextRegular>
                                    {error?.error?.stack
                                        ? upperFirst(error?.error?.stack)
                                        : upperFirst(error?.error?.message)}
                                </TextRegular>
                            </Flex>
                            <CopyIcon onClick={copyToClipboard} />
                        </Flex>
                    </ConditionalComponent>
                </Box>
            </ConditionalComponent>

            <ConditionalComponent statement={type === 'not-found'}>
                <Button
                    className={'back__button'}
                    onClick={() => {
                        window.location.href = '/';
                    }}
                >
                    Kembali ke Beranda
                </Button>
            </ConditionalComponent>

            <ConditionalComponent statement={type !== 'not-found' && type !== 'javascript'}>
                <Button className={'back__button'} onClick={() => window.location.reload()}>
                    <RedoIcon /> Muat Ulang
                </Button>
            </ConditionalComponent>
        </Box>
    );
};

export default ErrorPage;
