import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import TableColumn from '../../components/tableColumn';
import TableHead from '../../components/tableHead';
import EmptyState from '@components/emptyState';
import './styles.scss';

const SectionTable = ({ data, tableList }) => {
    return (
        <Box
            data-testid="achievement-section-table"
            className={`section-table__container__achievement
            ${data?.length <= 0 && 'hide-scrollbar-section-table__container'}`}
        >
            <Box className="section-table">
                <TableHead tableList={tableList} />
                <Box className="columns__container" bg="white">
                    {data?.map((item, idx) => (
                        <TableColumn data={item} key={idx} tableList={tableList} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default SectionTable;
