import fetchStores from '@data/store/fetchStores';
import { useQuery } from '@tanstack/react-query';

const getStores = ({ enabled, queries }) => {
    return useQuery({
        queryKey: ['stores', queries],
        queryFn: async () => await fetchStores({ queries }),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getStores;
