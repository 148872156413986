export const statusTransferOptions = [
    {
        name: 'Completed',
        id: 2,
    },
    {
        name: 'Pending',
        id: 1,
    },
    {
        name: 'Started',
        id: 3,
    },
];
