import fetchSisoTrends from '@data/siso/fethcSisoTrends';
import { useQuery } from '@tanstack/react-query';

const getSisoTrends = (queries) => {
    return useQuery({
        queryKey: ['siso-trends', queries],
        queryFn: () => fetchSisoTrends(queries),
        throwOnError: (error) => error,
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getSisoTrends;
