import fetchConfigurationInfo from '@data/configuration/fetchConfigurationInfo';
import { useQuery } from '@tanstack/react-query';

const getConfigurationInfo = (id) => {
    return useQuery({
        queryKey: ['configuration-info', id],
        queryFn: async () => await fetchConfigurationInfo(id),
        refetchOnWindowFocus: false,
        throwOnError: (error) => error,
        retry: false,
        gcTime: 0,
    });
};

export default getConfigurationInfo;
