import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import './styles.css';
import { InformationIcon, FilterIcon, FilterRemoveIcon } from '@assets/icons/index';
import Select from '@components/filter/select';
import ButtonOutline from '@components/buttons/buttonOutline';
import ButtonRegular from '@components/buttons/buttonRegular';
import { ConditionalComponent } from '@components/functionComponent';
import useGetLatestSync from '@domain/siso/useGetLastesSync';
import SkeletonComponent from '@components/skeleton';
import TooltipComponent from '@components/tooltip';
import { TooltipFilterContent } from '@pages/BusinessSiso/components/toolipContent';
import useGetSalesAreas from '@domain/salesArea/useGetSalesAreas';
import { useFilter } from '@pages/BusinessSiso/state/filters';
import { useState } from 'react';
import { emptyFilterState, initialFilterState } from './const';
import useGetStores from '@domain/store/useGetStores';
import useGetDC from '@domain/dc/useGetDC';
import useGetGroupStores from '@domain/groupStore/useGroupStores';
import useGetBrands from '@domain/brands/useGetBrands';
import { checkFilterInformation } from '@pages/BusinessSiso/helpers/utils';
import useGetSisoFilterAccess from '@domain/siso/useGetSisoFilterAccess';
import FilterLoader from '@pages/BusinessSiso/components/filterLoader';

const Filters = () => {
    const [tempFilter, setTempFilter] = useState(initialFilterState);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data, isLoading } = useGetLatestSync();
    const { data: filterAccess, isLoading: isLoadingFilterAccess } = useGetSisoFilterAccess();
    const filters = useFilter();
    const salesAreas = useGetSalesAreas({ enabled: tempFilter?.enabledSalesArea, search: tempFilter?.salesAreaSearch });
    const stores = useGetStores({ enabled: tempFilter?.enabledStore, search: tempFilter?.storeSearch });
    const dc = useGetDC({ enabled: tempFilter?.enabledDC, search: tempFilter?.dcSearch });
    const brands = useGetBrands({ enabled: tempFilter?.enabledBrand, search: tempFilter?.brandSearch });
    const groupStores = useGetGroupStores({
        enabled: tempFilter?.enabledGroupStore,
        search: tempFilter?.groupStoreSearch,
    });

    const isOpenFilterInformation = checkFilterInformation(filters);

    const onClickFilter = () => {
        if (isOpen) onClose();
        else onOpen();
    };

    const onResetFilter = () => {
        setTempFilter(emptyFilterState);
        filters.onClearFilter();
        onClose();
    };

    const onApplyFilter = () => {
        filters.setFilter({
            dcName: tempFilter?.dc?.map((item) => item?.name),
            salesArea: tempFilter?.salesAreas?.map((item) => item?.name),
            storeGroup: tempFilter?.groupStores?.map((item) => item?.name),
            customer: tempFilter?.stores?.map((item) => item?.name),
            brands: tempFilter?.brands?.map((item) => item?.name),
        });
        onClose();
    };

    const onRemoveFilterItem = (filterName, tempFilterName) => {
        filters.setFilter({ [filterName]: [] });
        setTempFilter({ ...tempFilter, [tempFilterName]: [] });
    };

    return (
        <Box className="filters-siso__container">
            <Flex justifyContent="space-between" alignItems="center">
                <Box>
                    <TextBold className="title">Dashboard Business Sell-In & Sell-Out (SiSo)</TextBold>
                    <ConditionalComponent
                        statement={!isLoading}
                        fallback={
                            <Flex className="description-container">
                                <SkeletonComponent width="16px" height="16px" mr="8px" />
                                <SkeletonComponent width="560px" height="22px" />
                            </Flex>
                        }
                    >
                        <Flex className="description-container">
                            <TooltipComponent
                                positionArrowLeft="14px"
                                maxWidth="560px"
                                content={<TooltipFilterContent data={data} />}
                            >
                                <InformationIcon />
                            </TooltipComponent>

                            <TextRegular className="description">
                                Pembaruan data terakhir, Sell-In: <span> {data?.sellin}</span> & Sell-Out{' '}
                                <span> {data?.sellout}</span>
                            </TextRegular>
                        </Flex>
                    </ConditionalComponent>
                </Box>

                <Flex
                    className="filter-button"
                    onClick={onClickFilter}
                    background={isOpen ? 'var(--semantic-heron)' : 'white'}
                >
                    <FilterIcon />
                    <TextBold className="filter-button__text no-highlight__text">Filter</TextBold>
                    <ConditionalComponent statement={isOpenFilterInformation}>
                        <Box className="filter-indicator" />
                    </ConditionalComponent>
                </Flex>
            </Flex>

            {/* FILTER LIST  */}

            <ConditionalComponent statement={isOpen}>
                <Flex className="box-filters fade-animation-bottom">
                    <ConditionalComponent statement={!isLoadingFilterAccess} fallback={<FilterLoader />}>
                        <Flex gap="16px 24px" flexWrap="wrap">
                            <ConditionalComponent statement={filterAccess?.salesArea}>
                                <Select
                                    selected={tempFilter?.salesAreas}
                                    onCloseOptions={() => setTempFilter({ ...tempFilter, salesAreaSearch: '' })}
                                    title="Sales Area"
                                    placeholder="Pilih Sales Area"
                                    width="240px"
                                    isGetAll={true}
                                    refetchData={salesAreas?.refetch}
                                    data={salesAreas?.data}
                                    isLoading={salesAreas?.isLoading}
                                    onSearch={(query) =>
                                        setTempFilter({ ...tempFilter, salesAreaSearch: query, enabledSalesArea: true })
                                    }
                                    onChangeFilter={(value) => setTempFilter({ ...tempFilter, salesAreas: value })}
                                />
                            </ConditionalComponent>

                            <ConditionalComponent statement={filterAccess?.dc}>
                                <Select
                                    selected={tempFilter?.dc}
                                    onCloseOptions={() => setTempFilter({ ...tempFilter, salesAreaSearch: '' })}
                                    title="Distribution Center"
                                    placeholder="Pilih DC"
                                    width="240px"
                                    isGetAll={true}
                                    refetchData={dc?.refetch}
                                    data={dc?.data}
                                    isLoading={dc?.isLoading}
                                    onSearch={(query) =>
                                        setTempFilter({ ...tempFilter, dcSearch: query, enabledDC: true })
                                    }
                                    onChangeFilter={(value) => setTempFilter({ ...tempFilter, dc: value })}
                                />
                            </ConditionalComponent>

                            <ConditionalComponent statement={filterAccess?.storeGroup}>
                                <Select
                                    selected={tempFilter?.groupStores}
                                    onCloseOptions={() => setTempFilter({ ...tempFilter, groupStoreSearch: '' })}
                                    title="Grup Toko"
                                    placeholder="Pilih Grup Toko"
                                    width="240px"
                                    isGetAll={true}
                                    refetchData={groupStores?.refetch}
                                    data={groupStores?.data}
                                    isLoading={groupStores?.isLoading}
                                    onSearch={(query) =>
                                        setTempFilter({
                                            ...tempFilter,
                                            groupStoreSearch: query,
                                            enabledGroupStore: true,
                                        })
                                    }
                                    onChangeFilter={(value) => setTempFilter({ ...tempFilter, groupStores: value })}
                                />
                            </ConditionalComponent>

                            <ConditionalComponent statement={filterAccess?.store}>
                                <Select
                                    selected={tempFilter?.stores}
                                    onCloseOptions={() => setTempFilter({ ...tempFilter, storeSearch: '' })}
                                    title="Toko"
                                    placeholder="Pilih Toko"
                                    width="240px"
                                    isGetAll={true}
                                    refetchData={stores?.refetch}
                                    data={stores?.data}
                                    isLoading={stores?.isLoading}
                                    onSearch={(query) =>
                                        setTempFilter({ ...tempFilter, storeSearch: query, enabledStore: true })
                                    }
                                    onChangeFilter={(value) => setTempFilter({ ...tempFilter, stores: value })}
                                />
                            </ConditionalComponent>

                            <ConditionalComponent statement={filterAccess?.brand}>
                                <Select
                                    selected={tempFilter?.brands}
                                    onCloseOptions={() => setTempFilter({ ...tempFilter, brandSearch: '' })}
                                    title="Brand"
                                    placeholder="Pilih Brand"
                                    width="240px"
                                    isGetAll={true}
                                    refetchData={brands?.refetch}
                                    data={brands?.data}
                                    isLoading={brands?.isLoading}
                                    onSearch={(query) =>
                                        setTempFilter({ ...tempFilter, brandSearch: query, enabledBrand: true })
                                    }
                                    onChangeFilter={(value) => setTempFilter({ ...tempFilter, brands: value })}
                                />
                            </ConditionalComponent>
                        </Flex>
                        <Flex className="filter-buttons__container">
                            <ButtonOutline
                                component="Reset All"
                                width="79px"
                                height="36px"
                                color="var(--color-primary)"
                                onClick={onResetFilter}
                            />
                            <ButtonRegular
                                component="Apply Filter"
                                width="98px"
                                height="36px"
                                color="var(--color-primary)"
                                onClick={onApplyFilter}
                            />
                        </Flex>
                    </ConditionalComponent>
                </Flex>
            </ConditionalComponent>

            {/* FILTER SELECTED  */}
            <ConditionalComponent statement={!isOpen && isOpenFilterInformation}>
                <Flex className="filter-selected__container">
                    <TextBold>Filters by:</TextBold>

                    <ConditionalComponent statement={filters?.salesArea?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Sales Area:</TextBold>
                            <TextRegular className="selected-text">{filters?.salesArea?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('salesArea', 'salesAreas')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={filters?.dcName?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Distribution Center:</TextBold>
                            <TextRegular className="selected-text">{filters?.dcName?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('dcName', 'dc')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={filters?.storeGroup?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Grup Toko:</TextBold>
                            <TextRegular className="selected-text">{filters?.storeGroup?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('storeGroup', 'groupStores')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={filters?.brands?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Brand:</TextBold>
                            <TextRegular className="selected-text">{filters?.brands?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('brands', 'brands')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={filters?.customer?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Toko:</TextBold>
                            <TextRegular className="selected-text">{filters?.customer?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('customer', 'stores')} />
                        </Flex>
                    </ConditionalComponent>

                    <ButtonOutline
                        component="Reset All"
                        width="79px"
                        height="36px"
                        color="var(--color-primary)"
                        onClick={onResetFilter}
                    />
                </Flex>
            </ConditionalComponent>
        </Box>
    );
};

export default Filters;
