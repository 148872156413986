import getSisoTrendsRequest from '@domain/model/request/siso/getSisoTrendsRequest';
import getSisoTrendsResponse from '@domain/model/response/siso/getSisoTrendsResponse';
import getSisoTrends from '@hooks/siso/getSisoTrends';

const useGetSisoTrends = (filters, trends) => {
    const queries = getSisoTrendsRequest({ ...filters, ...trends });

    const response = getSisoTrends(queries);
    const mappingResult = getSisoTrendsResponse(response?.data, trends);

    return { ...response, data: mappingResult };
};

export default useGetSisoTrends;
