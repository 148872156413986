import { isEmpty } from 'lodash';

const getStoresResponse = (data) => {
    if (isEmpty(data)) return [];

    const { page_data } = data;

    const mappingData = page_data?.map((item) => {
        return {
            id: item?.customer_id,
            name: item?.nama_outlet,
        };
    });

    return mappingData;
};

export default getStoresResponse;
