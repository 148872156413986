import { Flex, Box, Text } from '@chakra-ui/react';
import './styles.scss';
import React from 'react';

const TableHead = ({ tableList }) => {
    return (
        <Flex className="table-head__container__achievement">
            {tableList?.map((head, idx) => {
                return (
                    <Box
                        id={`table-head__item--${idx}`}
                        className="table-head__item"
                        flexDirection="column"
                        width={`${head.width}px`}
                        key={head.name}
                        bg={process.env.REACT_APP_BG_COLOR}
                    >
                        {head.children ? (
                            <>
                                <Flex className="table-head__parent table-head__parent-multiple-child">
                                    {head.name}
                                </Flex>
                                <Flex justify="space-between" w="100%">
                                    {head.children?.map((child) => (
                                        <Box className="table-head__child-container">
                                            <Box className="table-head__child">
                                                <Text>{child}</Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Flex className="table-head__parent">{head.name}</Flex>
                                <Box className="table-head__child">-</Box>
                            </>
                        )}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableHead;
