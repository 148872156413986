import Cookies from 'universal-cookie';
import { saveAs } from 'file-saver';
import moment from 'moment';
import queryString from 'qs';

import Axios from '@services/axios';
import { onFormattingDateSellout } from './utils';

//======================================= FETCH ASSETS & DATA ===============================

export const fetchAuditTrail = async ({ onChangeAuditTrailStore, filterAuditTrailState }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const {
        page,
        selectedStore,
        selectedDC,
        selectedStoreGroup,
        selectedStatus,
        selectedUploadType,
        selectedSorting,
        dateUploadSelected,
        dateSelloutSelected,
    } = filterAuditTrailState;

    onChangeAuditTrailStore({ isLoadingTable: true, users: [] });

    const nama_dc = selectedDC && `[${selectedDC?.map((item) => item?.name)}]`;
    const partner_ref = selectedStore && `[${selectedStore?.map((item) => item?.id)}]`;
    const store_group = selectedStoreGroup && `[${selectedStoreGroup?.map((item) => item?.name)}]`;
    const status = selectedStatus?.length && selectedStatus[0]?.name;
    const upload_type = selectedUploadType?.length && selectedUploadType[0]?.name;
    const date_uploaded = dateUploadSelected && moment(new Date(dateUploadSelected)).format('YYYY-MM-DD');
    const date_sellout = dateSelloutSelected?.length ? `(${onFormattingDateSellout(dateSelloutSelected)})` : null;

    const filterStringify = queryString.stringify(
        {
            page: page,
            limit: 7,
            partner_ref,
            nama_dc,
            store_group,
            sortdate: selectedSorting,
            date_uploaded,
            status,
            upload_type,
            date_sellout,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/audit-trail/info?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { total_data, page_data, total_page } = data?.data;

        onChangeAuditTrailStore({
            trails: page_data,
            isLoadingTable: false,
            paginationInfo: {
                dataCount: page_data?.length,
                totalData: total_data,
                totalPage: total_page,
            },
        });
    } catch (error) {
        console.log(error);
        onChangeAuditTrailStore({ trails: [], isLoadingTable: false });
    }
};

export const fetchDC = async ({ onChangeDCOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeDCOptionStore({ isLoadingDCOption: true, dcOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 1000,
            searchparam: searchQuery ? `dc_name.${searchQuery}` : '',
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/dc/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.dc_name,
            };
        });

        onChangeDCOptionStore({ dcOptions: result, isLoadingDCOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeDCOptionStore({ isLoadingDCOption: false, dcOptions: [] });
    }
};

export const fetchCustomer = async ({ onChangeCustomerOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeCustomerOptionStore({ isLoadingCustomerOption: true, customerOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 30,
            searchparam: searchQuery ? `nama_outlet.${searchQuery},customer_id.${searchQuery}` : '',
            dc: `[]`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/user/customer-dc?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                store_name: item?.nama_outlet,
                name: `${item?.nama_outlet} (${item?.customer_id})`,
                id: item?.customer_id,
            };
        });

        onChangeCustomerOptionStore({ customerOptions: result, isLoadingCustomerOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeCustomerOptionStore({ isLoadingCustomerOption: false, customerOptions: [] });
    }
};

export const fetchStoreGroup = async ({ onChangeStoreGroupOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeStoreGroupOptionStore({ isLoadingStoreGroupOption: true, storeGroupOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 30,
            searchparam: `group.${searchQuery}`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/group-store/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                name: item?.group,
                id: item?.group,
            };
        });

        onChangeStoreGroupOptionStore({ storeGroupOptions: result, isLoadingStoreGroupOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeStoreGroupOptionStore({ isLoadingStoreGroupOption: false, storeGroupOptions: [] });
    }
};

export const fetchStatus = async ({ onChangeStatusOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeStatusOptionStore({ isLoadingStatusOption: true, statusOptions: [] });

    try {
        const { data } = await Axios({
            url: `/api/v1/status-upload/list`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.name,
                id: item?.name,
            };
        });

        onChangeStatusOptionStore({ statusOptions: result, isLoadingStatusOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeStatusOptionStore({ isLoadingStatusOption: false, statusOptions: [] });
    }
};

export const fetchUploadType = async ({ onChangeUploadTypeOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeUploadTypeOptionStore({ isLoadingUploadTypeOption: true, uploadTypeOptions: [] });

    try {
        const { data } = await Axios({
            url: `/api/v1/upload-type/list`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.name,
                id: item?.name,
            };
        });

        onChangeUploadTypeOptionStore({ uploadTypeOptions: result, isLoadingUploadTypeOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeUploadTypeOptionStore({ isLoadingUploadTypeOption: false, uploadTypeOptions: [] });
    }
};

//======================================= ACTIONS ===============================

export const onDownloadFile = async ({ path, type, status, onChangeDownloadNotification }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const fileName = `audit trail ${type}`;

    //======== DISABLED CLICK WHEN DOWNLOADING FILE ================

    if (status === 'download') return;

    onChangeDownloadNotification({
        status: 'download',
        title: 'Downloading',
        description: `Harap tunggu, sedang mendownload "${fileName}"`,
    });

    path = path?.replace('/sellout', '');

    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/api/download-file',
            data: { path },
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });

        const blob = new Blob([data], { type: 'text/xlsx' });
        saveAs(blob, `${fileName}.xlsx`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: `Download "${fileName}.xlsx" berhasil`,
        });
    } catch (error) {
        console.log(error);

        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: `Download "${fileName}.xlsx" gagal`,
        });
    }
};

export const onDownloadAuditTrail = async ({ store, onChangeDownloadNotification }) => {
    try {
        const {
            selectedDC,
            selectedStore,
            selectedStoreGroup,
            selectedStatus,
            selectedUploadType,
            dateUploadSelected,
            selectedSorting,
            dateSelloutSelected,
        } = store;

        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const nama_dc = selectedDC && `[${selectedDC?.map((item) => item?.name)}]`;
        const partner_ref = selectedStore && `[${selectedStore?.map((item) => item?.id)}]`;
        const store_group = selectedStoreGroup && `[${selectedStoreGroup?.map((item) => item?.name)}]`;
        const status = selectedStatus?.length && selectedStatus[0]?.name;
        const upload_type = selectedUploadType?.length && selectedUploadType[0]?.name;
        const date_uploaded = dateUploadSelected && moment(dateUploadSelected).format('YYYY-MM-DD');
        const date_sellout = dateSelloutSelected?.length ? `(${onFormattingDateSellout(dateSelloutSelected)})` : null;

        const filterStringify = queryString.stringify(
            {
                partner_ref,
                nama_dc,
                store_group,
                sortdate: selectedSorting,
                date_uploaded,
                upload_type,
                date_sellout,
                status,
            },
            { skipEmptyString: true, arrayFormat: 'separator' }
        );

        onChangeDownloadNotification({
            status: 'download',
            title: 'Downloading',
            description: 'Harap tunggu, sedang mendownload audit trail.xlsx',
        });

        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/audit-trail/download/info?${filterStringify}`,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });

        const blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, `audit trail.xlsx`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Download Success',
            description: 'File  audit trail.xlsx berhasil di download',
            duration: 5,
        });
    } catch (error) {
        console.log(error);
        const message =
            error?.response?.data?.message ||
            'fitur export hanya bisa digunakan ketika memilih salah satu filter,  maksimal 1 store group, maksimal 1 DC atau maksimal 3 toko';

        onChangeDownloadNotification({
            status: 'error',
            title: 'Download audit trail.xlsx failed',
            description: message,
            duration: 1000,
        });
    }
};

export const onProcessingFile = async ({
    id,
    status,
    file,
    onResetPopUp,
    onChangeSnackbarNotification,
    onChangePopUp,
    refetchAuditTrail,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangePopUp({ isLoading: true });

    const formData = new FormData();

    formData.append('id', id);
    formData.append('status', status);
    formData.append('file', file);

    try {
        await Axios({
            url: `/api/v1/audit-trail/processing-file`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        await refetchAuditTrail();

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil mengubah data',
            duration: 5,
        });
    } catch (error) {
        console.log(error, '<<');
        const errorMessage = error?.response?.data?.message || '';
        onChangeSnackbarNotification({
            status: 'error',
            title: 'Gagal mengubah data',
            description: errorMessage,
            duration: 5,
        });
    } finally {
        onResetPopUp();
    }
};

export const onCancelAuditTrail = async ({
    onChangePopUp,
    refetchAuditTrail,
    onChangeSnackbarNotification,
    onResetPopUp,
    id,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangePopUp({ isLoading: true });

    try {
        await Axios({
            method: 'POST',
            url: '/api/v1/cancel-process/sellout-date',
            data: { id },
            headers: { Authorization: `Bearer ${userToken}` },
        });

        await refetchAuditTrail();

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil cancel data',
            duration: 5,
        });
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.message || '';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed cancel data',
            description: errorMessage,
            duration: 5,
        });
    } finally {
        onResetPopUp();
    }
};
