import fetchSisoFilterAccess from '@data/siso/fetchSisoFilterAccess';
import { useQuery } from '@tanstack/react-query';

const getSisoFilterAccess = () => {
    return useQuery({
        queryKey: ['siso-filter-access'],
        queryFn: () => fetchSisoFilterAccess(),
        throwOnError: (error) => error,
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getSisoFilterAccess;
