import React from 'react';
import { Button } from '@chakra-ui/react';
import './styles.css';

const ButtonOutline = ({ component, width, height, color, onClick, className, hoverColor, borderRadius }) => {
    return (
        <Button
            onClick={onClick}
            className={`button-outline__container ${className}`}
            width={width}
            height={height}
            borderColor={color}
            color={color}
            borderRadius={borderRadius ?? '4px !important'}
            _hover={{ borderColor: hoverColor, background: 'white !important', color: `${color} !important` }}
        >
            {component}
        </Button>
    );
};

export default ButtonOutline;
