import React from 'react';
export const UploadIcon = () => (
    <svg
        className="navbar-icon__container"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2243_84)">
            <mask x="0" y="0" width="20" height="20">
                <path d="M0.75 19.25V0.75H19.25V19.25H0.75Z" fill="#333333" stroke-width="1.5" />
            </mask>
            <g mask="url(#mask0_2243_84)">
                <path d="M2.5 10.0035V17.5H17.5V10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M13.75 6.25L10 2.5L6.25 6.25"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M9.99654 13.3333V2.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_2243_84">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const OrderIcon = () => (
    <svg
        className="navbar-icon__container"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.4167 3.33337H4.58333C4.1231 3.33337 3.75 3.70647 3.75 4.16671V17.5C3.75 17.9603 4.1231 18.3334 4.58333 18.3334H15.4167C15.8769 18.3334 16.25 17.9603 16.25 17.5V4.16671C16.25 3.70647 15.8769 3.33337 15.4167 3.33337Z"
            stroke-width="1.5"
            stroke-linejoin="round"
        />
        <path d="M7.5 1.66663V4.16663" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 1.66663V4.16663" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.66699 7.91669H13.3337" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.66699 11.25H11.667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.66699 14.5834H10.0003" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const AuditIcon = () => (
    <svg
        className="navbar-icon__container"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2243_106)">
            <path
                d="M2.42432 2.80304V5.83334H5.45462"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C6.91567 1.66669 4.2228 3.34215 2.78182 5.83256"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.002 5L10.0015 10.0037L13.5345 13.5368"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_2243_106">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const DashboardIcon = () => (
    <svg
        className="navbar-icon__container"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.33333 2.5H2.5V7.08333H8.33333V2.5Z" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M17.4998 12.9167H11.6665V17.5H17.4998V12.9167Z" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M17.4998 2.5H11.6665V9.58333H17.4998V2.5Z" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M8.33333 10.4167H2.5V17.5H8.33333V10.4167Z" stroke-width="1.5" stroke-linejoin="round" />
    </svg>
);

export const StoreIcon = () => (
    <svg
        className="navbar-icon__container"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.683 9.16663V17.5H3.34961V9.16663"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M2.43416 5.74025C1.79684 7.39071 3.02614 9.16667 4.79536 9.16667C6.17607 9.16667 7.30303 8.04738 7.30303 6.66667C7.30303 8.04738 8.42232 9.16667 9.80303 9.16667H10.2274C11.6081 9.16667 12.7274 8.04738 12.7274 6.66667C12.7274 8.04738 13.8548 9.16667 15.2355 9.16667C17.0057 9.16667 18.2361 7.38967 17.5983 5.73846L16.3472 2.5H3.68537L2.43416 5.74025Z"
            stroke-width="1.5"
            stroke-linejoin="round"
        />
    </svg>
);

export const UserManagementIcon = () => (
    <svg
        className="navbar-icon__container"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2243_439)">
            <path
                d="M5.83333 14.1667C6.98393 14.1667 7.91667 13.2339 7.91667 12.0833C7.91667 10.9327 6.98393 10 5.83333 10C4.68274 10 3.75 10.9327 3.75 12.0833C3.75 13.2339 4.68274 14.1667 5.83333 14.1667Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.1668 14.1667C15.3174 14.1667 16.2502 13.2339 16.2502 12.0833C16.2502 10.9327 15.3174 10 14.1668 10C13.0162 10 12.0835 10.9327 12.0835 12.0833C12.0835 13.2339 13.0162 14.1667 14.1668 14.1667Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.99984 5.83329C11.1504 5.83329 12.0832 4.90055 12.0832 3.74996C12.0832 2.59937 11.1504 1.66663 9.99984 1.66663C8.84924 1.66663 7.9165 2.59937 7.9165 3.74996C7.9165 4.90055 8.84924 5.83329 9.99984 5.83329Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.99984 18.3333C9.99984 16.0321 8.13434 14.1666 5.83317 14.1666C3.53198 14.1666 1.6665 16.0321 1.6665 18.3333"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18.3333 18.3333C18.3333 16.0321 16.4678 14.1666 14.1667 14.1666C11.8655 14.1666 10 16.0321 10 18.3333"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.1668 10C14.1668 7.69887 12.3013 5.83337 10.0002 5.83337C7.699 5.83337 5.8335 7.69887 5.8335 10"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_2243_439">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
