import authAxios from '@services/authAxios';

const fetchSisoTrends = async (queries) => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/siso/trends?${queries}`,
    });

    return data?.data;
};

export default fetchSisoTrends;
