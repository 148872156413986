import React from 'react';
import { ExportIcon } from '@assets/icons/index';
import { Flex } from '@chakra-ui/react';
import { TextBold } from '@components/typography';

const ButtonExport = ({ mutation }) => {
    return (
        <Flex className="button-export__container" onClick={mutation.mutate}>
            <ExportIcon />
            <TextBold>Export Data</TextBold>
        </Flex>
    );
};

export default ButtonExport;
