import { Box, List, ListItem } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import styles from './styles.module.css';

export const TooltipFilterContent = ({ data }) => (
    <Box>
        <TextRegular color={'var(--semantic-heron)'}>
            Data Sell-In diperbarui setiap hari & data Sell-Out diperbarui setelah melakukan upload sell-out atau data
            masuk dari API. Pembaruan data terakhir:
        </TextRegular>

        <List>
            <ListItem>
                <TextRegular className={styles['list-item__text']} color={'var(--semantic-heron)'}>
                    <span>•</span>
                    Sell-In: {data?.sellin}
                </TextRegular>
            </ListItem>

            <ListItem>
                <TextRegular className={styles['list-item__text']} color={'var(--semantic-heron)'}>
                    <span>•</span>
                    Sell-Out: {data?.sellout}
                </TextRegular>
            </ListItem>
        </List>
    </Box>
);

export const TooltipStatistictontent = () => (
    <Box>
        <TextRegular color={'var(--semantic-heron)'} className={styles['statistic-content__text--1']}>
            Total Sell-In dan Sell-Out ditampilkan berdasarkan periode MTD (Month to Date). Artinya data Total nilai
            Sell-In dan Sell-Out dari awal bulan berjalan hingga saat ini, namun tidak termasuk hari ini.
        </TextRegular>

        <TextRegular color={'var(--semantic-heron)'} className={styles['statistic-content__text--2']}>
            Misal: Hari ini tanggal 10, Total Sell-In dan Sell-Out yang ditampilkan merupakan akumulasi dari tanggal 1 -
            9.
        </TextRegular>
    </Box>
);

export const TooltipTrendtontent = () => (
    <Box>
        <TextRegular color={'var(--semantic-heron)'} className={styles['statistic-content__text--1']}>
            Data Sell-In dan Sell-Out Toko bulan ini masih dapat berubah-ubah seiring adanya penambahan Sell-In atau
            Sell-Out di hari berikutnya.
        </TextRegular>

        <TextRegular color={'var(--semantic-heron)'} className={styles['statistic-content__text--2']}>
            Lakukan pengecekan di tanggal 11 untuk data yang akurat di bulan sebelumnya.
        </TextRegular>
    </Box>
);

export const TooltipTabletontent = () => (
    <Box>
        <TextBold color={'var(--semantic-heron)'} className={styles['table-content__text']}>
            MTD (Month to Date) dan YTD (Year to Date) Sell-In dan Sell-Out
        </TextBold>

        <List>
            <ListItem>
                <TextRegular className={styles['list-table-item__text']} color={'var(--semantic-heron)'}>
                    <span>•</span>
                    MTD: Total nilai Sell-In dan Sell-Out dari awal bulan berjalan hingga saat ini, namun tidak termasuk
                    hari ini. Misal: hari ini tanggal 10 November, value MTD yang ditampilkan merupakan akumulasi dari
                    tanggal 1 - 9 November.
                </TextRegular>
            </ListItem>

            <ListItem>
                <TextRegular className={styles['list-table-item__text']} color={'var(--semantic-heron)'}>
                    <span>•</span>
                    YTD: Total nilai Sell-In dan Sell-Out dari 1 Januari hingga saat ini, namun tidak termasuk hari ini.
                    Misal: hari ini tanggal 10 November, value YTD yang ditampilkan merupakan akumulasi dari tanggal 1
                    Januari - 9 November
                </TextRegular>
            </ListItem>
        </List>
    </Box>
);
