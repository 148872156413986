import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ConditionalComponent } from '@components/functionComponent';
import { useDownloadNotification } from '@store/downloadNotification';

import { InfoIcon, CloseIcon } from './icons';
import { getColor } from './const';

import './styles.scss';
import { InformationBlue24Icon } from '@assets/icons/index';
import { TextBold, TextRegular } from '@components/typography';

const DownloadNotification = () => {
    const { status, title, description, onResetDownloadNotification } = useDownloadNotification();

    const { borderColor, backgroundColor, icon } = getColor({ status });

    useEffect(() => {
        setTimeout(() => {
            if (status !== 'download') onResetDownloadNotification();
        }, 3000);
        // eslint-disable-next-line
    }, [status]);

    return (
        <ConditionalComponent statement={!!status}>
            <Box className="download-notification__container">
                <Flex className="box" bg={backgroundColor} borderLeft={`4px solid ${borderColor}`}>
                    {icon}
                    <Box className="info-container">
                        <TextBold className="title">{title}</TextBold>
                        <TextRegular className="description">{description}</TextRegular>
                    </Box>
                    <CloseIcon color={'var(--semantic-text)'} onClick={onResetDownloadNotification} />
                </Flex>
            </Box>
        </ConditionalComponent>
    );
};

export default DownloadNotification;
