import fetchBrands from '@data/brands/getBrands';
import { useQuery } from '@tanstack/react-query';

const getBrands = ({ enabled, queries }) => {
    return useQuery({
        queryKey: ['brands', queries],
        queryFn: async () => await fetchBrands({ queries }),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getBrands;
