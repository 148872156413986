import fetchSisoTable from '@data/siso/fetchSIsoTable';
import { useQuery } from '@tanstack/react-query';

const getSisoTable = (queries) => {
    return useQuery({
        queryKey: ['siso-table', queries],
        queryFn: () => fetchSisoTable(queries),
        throwOnError: (error) => error,
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getSisoTable;
