import getSisoTableRequest from '@domain/model/request/siso/getSisoTableRequest';
import getSisoTableResponse from '@domain/model/response/siso/getSisoTableResponse';
import getSisoTable from '@hooks/siso/getSisoTable';

const useGetSisoTable = (filters, table) => {
    const queries = getSisoTableRequest({ ...filters, ...table });
    const response = getSisoTable(queries);
    const mappingResult = getSisoTableResponse(response?.data);
    return { ...response, data: mappingResult };
};

export default useGetSisoTable;
