import Cookies from 'universal-cookie';

export const onCheckIsLogin = ({ token, navigate, pathname }) => {
    const cookie = new Cookies();
    const menuAccess = cookie.get('menuAccess');

    if (
        (pathname === '/login' && token) ||
        (pathname === '/oauth' && token) ||
        (pathname === '/oauth/' && token) ||
        (pathname === '/login/' && token)
    ) {
        redirectToPage({ menuAccess, navigate });
    }

    if (pathname !== '/login' && !token && pathname !== '/oauth' && pathname !== '/oauth/' && pathname !== '/login/') {
        navigate('/login');
    }
};

export const onDragFile = ({ event, type, onChangeDragFile }) => {
    if (type === 'leave') {
        event.preventDefault();
        if (event.currentTarget.contains(event.relatedTarget)) return;
    }

    onChangeDragFile({ isDragFile: type === 'enter' });
};

export const onRemoveAllCookies = () => {
    try {
        const cookies = new Cookies();
        const allCookies = cookies.getAll();

        for (const cookieName in allCookies) {
            if (cookieName !== 'hetModalStatus') {
                cookies.remove(cookieName, { path: '/' });
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const redirectToPage = ({ menuAccess, navigate }) => {
    let target = '';

    if (!menuAccess?.length) {
        navigate('/profile');

        return;
    }

    const menuListCheck = menuAccess?.some((menu) => menu.link === 'upload_data');

    if (menuListCheck) target = '/upload-sellout';
    else target = '/audit-trail';

    navigate(target);
};
