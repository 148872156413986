import { create } from 'zustand';

const state = {
    dcName: [],
    customer: [],
    salesArea: [],
    brands: [
        'Wardah',
        'Emina',
        'Make Over',
        'OMG',
        'Kahf',
        'Instaperfect',
        'Tavi',
        'Labore',
        'Biodef',
        'Putri',
        'Crystallure',
    ],
    storeGroup: [],
};

export const useFilter = create((set) => ({
    ...state,
    setFilter: (newState) => set((state) => ({ ...state, ...newState })),
    onClearFilter: () =>
        set(() => ({
            dcName: [],
            customer: [],
            salesArea: [],
            brands: [],
            storeGroup: [],
        })),
    resetFilter: () => set(() => ({ ...state })),
}));
