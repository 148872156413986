import getSalesAreasRequest from '@domain/model/request/salesArea/getSalesAreasRequest';
import getSalesAreasResponse from '@domain/model/response/salesArea/getSalesAreasResponse';
import getSalesAreas from '@hooks/salesArea/getSalesAreas';

const useGetSalesAreas = ({ enabled, search }) => {
    const queries = getSalesAreasRequest({ search });
    const responses = getSalesAreas({ enabled, queries });
    const mappingResult = getSalesAreasResponse(responses?.data);
    return { ...responses, data: mappingResult };
};

export default useGetSalesAreas;
