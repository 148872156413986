import React from 'react';

export const CheckIcon = ({ active }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1393_1002)">
            <path
                d="M9.99996 18.3333C12.3011 18.3333 14.3845 17.4006 15.8925 15.8925C17.4005 14.3845 18.3333 12.3012 18.3333 10C18.3333 7.69884 17.4005 5.6155 15.8925 4.10745C14.3845 2.59941 12.3011 1.66667 9.99996 1.66667C7.69879 1.66667 5.61546 2.59941 4.1074 4.10745C2.59937 5.6155 1.66663 7.69884 1.66663 10C1.66663 12.3012 2.59937 14.3845 4.1074 15.8925C5.61546 17.4006 7.69879 18.3333 9.99996 18.3333Z"
                fill={active ? '#2EA31F' : '#868686'}
                stroke={active ? '#2EA31F' : '#868686'}
                strokeWidth="1.66667"
                strokeLinejoin="round"
            />
            <path
                d="M6.66663 10L9.16663 12.5L14.1666 7.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1393_1002">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
