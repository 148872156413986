import { SortIcon } from '@assets/icons/index';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const SortTableIcon = ({ sortType, value, withSort }) => {
    if (withSort) {
        if (sortType?.type === 'asc' && sortType?.name === value) {
            return <ChevronUpIcon cursor="pointer" fontSize="14px" color="var(--semantic-text)" />;
        } else if (sortType?.type === 'desc' && sortType?.name === value) {
            return <ChevronDownIcon cursor="pointer" fontSize="14px" color="var(--semantic-text)" />;
        } else {
            return <SortIcon />;
        }
    }

    return <></>;
};

export default SortTableIcon;
