import Cookies from 'universal-cookie';
import Axios from '@services/axios';

import moment from 'moment';

import { redirectToPage } from '@helpers/events';

export const onCheckToken = async ({ authToken, onChangeSnackbarNotification, navigate }) => {
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/api/validate-token/${authToken}`,
        });

        //============================ SET NOTIF ========================

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Login berhasil',
            description: 'Berhasil masuk ke halaman dashboard',
            duration: 2,
        });

        const { token, name, roleName, menuAccess, isDefaultPassword } = data;
        const expirations = new Date(moment().add(1, 'days'));

        const cookies = new Cookies();

        cookies.set('userToken', token, { path: '/', expires: expirations });
        cookies.set('username', name, { path: '/', expires: expirations });
        cookies.set('role', roleName, { path: '/', expires: expirations });
        cookies.set('menuAccess', JSON.stringify(menuAccess), { path: '/', expires: expirations });
        cookies.set('isLogin', 'true', { path: '/', expires: expirations });

        //============================ NAVIGATE ========================

        if (isDefaultPassword) navigate('/reset-password');
        else redirectToPage({ menuAccess, navigate });
    } catch (error) {
        console.log(error);

        navigate('/login?error=error');
    }
};
