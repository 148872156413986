import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import Table from '@pages/UserManagement/sections/table';
import { fetchUserManagement } from '@pages/UserManagement/helpers/crud';
import { useUserManagementFilterStore, useUserManagementStore } from '@pages/UserManagement/helpers/mainState';
import Head from '@pages/UserManagement/sections/head';

let isMounting = false;

const UserManagement = () => {
    const { onChangeUserManagementStore, onResetUserManagementStore, users, isLoadingTable } = useUserManagementStore();
    const { searchQuery, selectedRole, selectedDC, page, onResetUserManagementFilterSelectedStore } =
        useUserManagementFilterStore();

    const refetchUserManagement = () => {
        fetchUserManagement({ onChangeUserManagementStore, page, searchQuery, selectedRole, selectedDC });
    };

    useEffect(() => {
        refetchUserManagement();
        // eslint-disable-next-line
    }, [page, searchQuery, selectedRole, selectedDC]);

    useEffect(() => {
        if (isMounting) {
            return () => {
                onResetUserManagementStore();
                onResetUserManagementFilterSelectedStore();
            };
        }

        isMounting = true;

        // eslint-disable-next-line
    }, []);

    return (
        <Box className="usermanagement-container" data-testid="usermanagement-container">
            <Head />
            <Table users={users} isLoadingTable={isLoadingTable} />
        </Box>
    );
};

export default UserManagement;
