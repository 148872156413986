import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useConfigDetail } from '@pages/StoreConfig/helpers/mainState';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { OpenEyeIcon } from '@assets/icons';
import { ConditionalComponent } from '@components/functionComponent';

import { onFetchSyncToExagon, onForceRunPost } from '@pages/StoreConfig/helpers/crud';
import { onUpdateActiveSyncToExagon } from '@pages/StoreConfig/helpers/event';
import { getStatusColor, onCheckBackgroundProgress } from '@pages/StoreConfig/helpers/utils';
import SyncToExagonModal from '../syncToExagonModal';
import { CheckboxComponent, TextComponent } from '../detailElement';
import { dummyOutboundProgressList, dummyReplenishmentProgressList } from '../syncToExagonModal/const';
import { PlayIcon } from './icons';

import './styles.scss';
import FloatingInfo from '@components/floatingInfo';

const SyncToExagon = ({
    id,
    setIsBackgroundProgress,
    isBackgroundProgress,
    label,
    isActiveLabel,
    endpointGet,
    forceRunEndpoint,
}) => {
    const store = useConfigDetail();
    const storeInfo = store?.storeInfo;
    const { onChangeSnackbarNotification } = useSnackbarNotification();
    const [syncData, setSyncData] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);

    const fetchSyncToExagon = () => onFetchSyncToExagon({ setSyncData, id, endpointGet });

    useEffect(() => {
        let intervalRef = null;
        const isBackgroundProgress = onCheckBackgroundProgress(syncData?.progress_stage);
        setIsBackgroundProgress(isBackgroundProgress);

        if (isBackgroundProgress) intervalRef = setInterval(() => fetchSyncToExagon(), 2000);
        else clearInterval(intervalRef);

        return () => clearInterval(intervalRef);
    }, [syncData?.progress_stage]);

    useEffect(() => {
        fetchSyncToExagon();
    }, []);

    return (
        <Box className="syncto-exagon-container">
            <Box className="section-item" pr="0 !important">
                <Text className="label-sync">{label}</Text>
                <Flex className="checkbox-item force-run__container">
                    <Text className="checkbox-label">Force run</Text>
                    <ConditionalComponent
                        statement={!isBackgroundProgress}
                        fallback={<OpenEyeIcon color="var(--main-color)" onClick={() => setIsOpenModal(true)} />}
                    >
                        <PlayIcon
                            onClick={() =>
                                onForceRunPost({
                                    onChangeSnackbarNotification,
                                    id,
                                    setIsOpenModal,
                                    fetchSyncToExagon,
                                    forceRunEndpoint,
                                })
                            }
                        />
                    </ConditionalComponent>
                </Flex>

                <Flex className="checkbox-item">
                    <Text className="checkbox-label">Active</Text>
                    <CheckboxComponent
                        storeInfo={storeInfo}
                        store={store}
                        fieldName={isActiveLabel}
                        onChangeSetting={(value) => onUpdateActiveSyncToExagon(value)}
                    />
                </Flex>

                <Flex className="text-item">
                    <Text className="text-label">Last run</Text>
                    <TextComponent store={store} storeInfo={storeInfo} value={syncData?.status?.updated_at} />
                </Flex>
                <Flex className="text-item">
                    <Text className="text-label">Status</Text>
                    <FloatingInfo label={syncData?.status?.message ?? ''}>
                        <Box>
                            <TextComponent
                                storeInfo={storeInfo}
                                store={store}
                                value={syncData?.status?.name}
                                color={getStatusColor(syncData?.status?.name)}
                            />
                        </Box>
                    </FloatingInfo>
                </Flex>
                <Flex className="text-item">
                    <Text className="text-label">Task no</Text>
                    <TextComponent storeInfo={storeInfo} store={store} value={syncData?.task_id} />
                </Flex>

                <ConditionalComponent statement={isOpenModal}>
                    <SyncToExagonModal
                        onClose={() => setIsOpenModal(false)}
                        id={id}
                        endpointGet={endpointGet}
                        dummyData={
                            isActiveLabel === 'is_outbound' ? dummyOutboundProgressList : dummyReplenishmentProgressList
                        }
                    />
                </ConditionalComponent>
            </Box>
        </Box>
    );
};

export default SyncToExagon;
