import moment from 'moment';
import { create } from 'zustand';

const state = {
    tableMonth: moment().format('MM'),
    tableYear: moment().format('YYYY'),
};

export const useTable = create((set) => ({
    ...state,
    setTable: (newState) => set((state) => ({ ...state, ...newState })),
    resetTable: () => set(() => ({ ...state })),
}));
