import queryString from 'qs';

const getDCRequest = (data) => {
    const payload = {
        searchparam: data?.search ? `dc_name.${data?.search}` : null,
    };

    return queryString.stringify(payload, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getDCRequest;
