import { Box } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import TextComponent from '@pages/storeConfigurationDetail/components/text';

const MasterData = ({ configuration }) => {
    return (
        <Box className={styles['master-data__container']}>
            <TextBold className={styles['title']}>Master Data</TextBold>
            <TextComponent label="Harga Produk" value={configuration?.het_price_name?.name} />
            <TextComponent label="Harga Biodef" value={configuration?.lp_price_name?.name} />
        </Box>
    );
};

export default MasterData;
