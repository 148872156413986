import fetchSisoLatestSync from '@data/siso/fethcSisoLatestSync';
import { useQuery } from '@tanstack/react-query';

const getLatestSync = () => {
    return useQuery({
        queryKey: ['siso-latest-sync'],
        queryFn: () => fetchSisoLatestSync(),
        refetchOnWindowFocus: false,
        throwOnError: (error) => error,
        retry: false,
        gcTime: 0,
    });
};

export default getLatestSync;
