import { background, Box, Flex, Tooltip } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextRegular } from '@components/typography';
import SkeletonComponent from '@components/skeleton';
import { ConditionalComponent } from '@components/functionComponent';

const TextComponent = ({ label, value, isLoading, skeletonWidth, withDot, backgroundDot, statusErrorMessage }) => {
    return (
        <Flex className={styles['text-component__container']}>
            <TextRegular className={styles['text']}>{label}</TextRegular>
            <ConditionalComponent
                statement={!isLoading}
                fallback={<SkeletonComponent height="20px" width={skeletonWidth} />}
            >
                <Tooltip hasArrow label={statusErrorMessage} placement={'bottom-end'}>
                    <Flex gap="8px" alignItems="center">
                        <ConditionalComponent statement={withDot}>
                            <Box className={styles['dot']} background={backgroundDot} />
                        </ConditionalComponent>
                        <TextRegular className={styles['text']}>{value}</TextRegular>
                    </Flex>
                </Tooltip>
            </ConditionalComponent>
        </Flex>
    );
};

export default TextComponent;
